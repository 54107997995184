import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, FormCheck, Image, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";

import * as dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import {
  CONTENT_ADD_POST,
  CONTENT_CREATE_GET,
  CONTENT_STUDIO_UPDATE,
} from "../../../../api/endpoints/content-studio-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import { modifyKeyNames } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import DeleteModal from "../../../../components/partials/common/delete-modal";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";
import SpamScoreModal from "../../../../components/partials/common/spam-score-modal";
import SelectTemplateCard from "./select-template-card";
import CreateContentStudioModal from "./create-content-studio-modal";
import { useSelector } from "react-redux";
import { contentStudioGetData } from "../../../../store/content-studio/content-studio.selector";
import { STATUS_CONSTANTS } from "../../action/constants/action-constants";

const emailObjectKeyMapping = {
  email_id: "id",
  email_name: "name",
  approve: "approve",
  status: "status",
};

const smsObjectKeyMapping = {
  sms_id: "id",
  sms_name: "name",
  sms_content: "content",
  approve: "approve",
  status: "status",
};
const whatsappObjectKeyMapping = {
  whatsapp_id: "id",
  whatsapp_name: "name",
  whatsapp_content: "content",
  approve: "approve",
  status: "status",
};

const CreateContentStudio = ({ view = false }) => {
  const [createCsLoaing, setCreateCsLoading] = useState(false);
  const [contentStudioData, setContentStudioData] = useState({});
  const [templateList, setTemplateList] = useState({
    whatsapp: [],
    email: [],
    sms: [],
  });
  const [variationName, setVariationName] = useState("");
  const [contentName, setContentName] = useState("");
  const [showContentListModal, setShowContentListModal] = useState(false);
  const [showSpamScoretModal, setShowSpamScoretModal] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const deleteVariationName = useRef("");
  const deleteVariationId = useRef("");
  const variationId = useRef("");
  const submitButtonRef = useRef([]);
  const { pathname } = useLocation();
  const { redirectTo } = useRedirect();
  let contentStudioDataRedux = useSelector(contentStudioGetData);

  useEffect(() => {
    getCreateContentStudioData();
  }, []);

  useEffect(() => {
    setContentStudioData(
      structuredClone({
        ...contentStudioDataRedux,
      })
    );
    submitButtonRef.current =
      contentStudioDataRedux.variations.length > 0 &&
      contentStudioDataRedux.variations.map((_s) => {
        return { id: _s.id, status: checkPendingTemplate(_s) };
      });
  }, [contentStudioDataRedux]);

  const checkPendingTemplate = (_s, updatedChannelStatus) => {
    let channelStatus = updatedChannelStatus
      ? updatedChannelStatus
      : contentStudioDataRedux.channelStatus;
    let templates = _s.templates;
    console.log("checkPendingTemplate", templates, channelStatus);
    let isWhatsappPending =
      templates?.whatsapp?.approve !== STATUS_CONSTANTS.APPROVED;
    let isEmailPending =
      templates?.email?.approve !== STATUS_CONSTANTS.APPROVED;
    let isSmsPending = templates?.sms?.approve !== STATUS_CONSTANTS.APPROVED;
    let isAnyPendingTemp =
      (channelStatus.whatsapp ? isWhatsappPending : false) ||
      (channelStatus.email ? isEmailPending : false) ||
      (channelStatus.sms ? isSmsPending : false);

    return isAnyPendingTemp;
  };

  const getId = pathname?.split("/")?.pop();
  const submitTextType = pathname.includes("edit") ? "Update" : "Submit";
  const isSubmitButtonDisable = useMemo(() => {
    let isWhatsappDisable = !contentStudioData?.channelStatus?.whatsapp;
    let isEmailDisable = !contentStudioData?.channelStatus?.email;
    let isSmsDisable = !contentStudioData?.channelStatus?.sms;
    if (isWhatsappDisable && isEmailDisable && isSmsDisable) {
      console.log("isAnyPendingTemplate1", submitButtonRef.current);
      return true;
    } else {
      let isAnyPendingTemplate = submitButtonRef.current
        .map((i) => i.status)
        .includes(true);
      console.log("isAnyPendingTemplate2", submitButtonRef.current);
      // return isAnyPendingTemplate;
      if (pathname.includes("edit")) {
        let contentStudioNameUpdate =
          contentStudioDataRedux.content_studio_name ==
          contentStudioData.content_studio_name;
        let contentStudioContentUpdate =
          contentStudioDataRedux.content_studio_content_id ==
          contentStudioData.content_studio_content_id;
        return (
          isAnyPendingTemplate &&
          contentStudioNameUpdate &&
          contentStudioContentUpdate
        );
      } else {
        return isAnyPendingTemplate;
      }
    }
  }, [contentStudioData]);

  const getVariationName = (e) => setVariationName(e.target.value);
  const saveVariationNameHandler = (id) => {
    setContentStudioData({
      ...contentStudioData,
      variations: contentStudioData?.variations?.map((_v) => {
        if (_v.id === id) {
          _v.variation_name = variationName;
          _v.is_variation_name_edit = !_v.is_variation_name_edit;
        }
        return _v;
      }),
    });
  };

  const cancelVariationNameHandler = (id) =>
    setContentStudioData({
      ...contentStudioData,
      variations: contentStudioData?.variations?.map((_v) => {
        if (_v.id === id) {
          _v.is_variation_name_edit = !_v.is_variation_name_edit;
          setVariationName(_v.variation_name);
        }
        return _v;
      }),
    });
  const deleteVariationRow = () => {
    setContentStudioData({
      ...contentStudioData,
      variations: contentStudioData.variations.filter(
        (_v) => _v.id !== deleteVariationId.current
      ),
    });
    submitButtonRef.current.filter((_s) => _s.id !== deleteVariationId.current);
  };
  const editVariationName = (id) => {
    setContentStudioData({
      ...contentStudioData,
      variations: contentStudioData.variations.map((_v) => {
        if (_v.id === id) {
          _v.is_variation_name_edit = !_v.is_variation_name_edit;
          setVariationName(_v.variation_name);
        }
        return _v;
      }),
    });
  };
  const channelsCheckHandler = (type, checked) => {
    let updatedChannelStatus = {
      ...contentStudioData.channelStatus,
      [type]: !contentStudioData.channelStatus[type],
    };
    let updatedVariations = contentStudioData?.variations?.map((_v, idx) => {
      _v.templates[type] = {
        id: "",
        name: "",
        content: "",
        approve: "",
        status: "",
      };
      return _v;
    });
    console.log("updatedVariations ##", updatedVariations);
    submitButtonRef.current =
      updatedVariations.length > 0 &&
      updatedVariations.map((_s) => {
        return {
          id: _s.id,
          status: checkPendingTemplate(_s, updatedChannelStatus),
        };
      });
    setContentStudioData({
      ...contentStudioData,
      channelStatus: updatedChannelStatus,
      variations: updatedVariations,
    });
    // submitButtonRef.current
  };

  const addVariationRow = () => {
    let channelTempDetails = {
      id: "",
      name: "",
      content: "",
      approve: "",
    };
    setContentStudioData({
      ...contentStudioData,
      variations: [
        ...contentStudioData.variations,
        {
          id:
            contentStudioData.variations[
              contentStudioData.variations.length - 1
            ].id + 1,
          variation_name: `variation ${
            contentStudioData.variations.length + 1
          }`,
          templates: {
            whatsapp: channelTempDetails,
            email: channelTempDetails,
            sms: channelTempDetails,
          },
          spam_status: 0,
          is_checkSpam_disable: false,
          is_variation_name_edit: false,
        },
      ],
    });
    submitButtonRef.current = [
      ...submitButtonRef.current,
      {
        id: submitButtonRef.current[submitButtonRef.current.length - 1].id + 1,
        status: true,
      },
    ];
  };

  const isSpamScoreButtonDisable = (id) => {
    let disableSpamScore = contentStudioData?.variations?.filter(
      (_v) => _v.id == id
    );
    let isWhatsappChannelEnable = false;
    let isEmailChannelEnable = false;
    let isSmsChannelEnable = false;
    let channelStatus = contentStudioData?.channelStatus;
    let isAllChannelDisable =
      !channelStatus.whatsapp && !channelStatus.email && !channelStatus.sms;

    if (disableSpamScore && disableSpamScore?.length > 0) {
      let templates = disableSpamScore[0]?.templates;

      isWhatsappChannelEnable =
        !channelStatus.whatsapp || templates?.whatsapp.id !== "";
      isEmailChannelEnable = !channelStatus.email || templates?.email.id !== "";
      isSmsChannelEnable = !channelStatus.sms || templates?.sms.id !== "";
    }

    if (isWhatsappChannelEnable && isEmailChannelEnable && isSmsChannelEnable) {
      if (isAllChannelDisable) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  function getDeviceRegion() {
    var deviceLocale = navigator.language || navigator.userLanguage; // Get the device's locale
    console.log("deviceLocale::", deviceLocale);
    if (deviceLocale) {
      try {
        var region = new Intl.Locale(deviceLocale).region; // Use Intl API to extract the region
        return region ? region.toUpperCase() : null; // Return the uppercase region if available, otherwise null
      } catch (error) {
        console.error("Error occurred while retrieving the region:", error);
        return null; // Return null if an error occurred while retrieving the region
      }
    }

    return null; // Return null if the locale couldn't be determined
  }
  const getCreateContentStudioData = async (e) => {
    if (createCsLoaing) return false;
    try {
      setCreateCsLoading(true);
      const response = await getService(`${CONTENT_CREATE_GET}`, {});
      setCreateCsLoading(false);
      if (response?.data && !response?.isError) {
        console.log("response template", response?.data?.data?.templates);
        if (Object.keys(response?.data?.data?.templates)?.length > 0) {
          // setCreateContentStudioData(response?.data?.data?.templates);

          const modifiedEmailTemplateOpt = await modifyKeyNames(
            response?.data?.data?.templates?.email,
            emailObjectKeyMapping
          );

          const modifiedSmsTemplateOpt = await modifyKeyNames(
            response?.data?.data?.templates?.sms,
            smsObjectKeyMapping
          );
          const modifiedWhatsappTemplateOpt = await modifyKeyNames(
            response?.data?.data?.templates?.whatsapp,
            whatsappObjectKeyMapping
          );

          console.log("modifiedEmailTemplateOpt", modifiedEmailTemplateOpt);
          console.log("modifiedSmsTemplateOpt", modifiedSmsTemplateOpt);
          console.log(
            "modifiedWhatsappTemplateOpt",
            modifiedWhatsappTemplateOpt
          );
          setTemplateList({
            ...templateList,
            whatsapp: modifiedWhatsappTemplateOpt,
            email: modifiedEmailTemplateOpt,
            sms: modifiedSmsTemplateOpt,
          });
        }
      } else {
      }
    } catch (error) {
      setCreateCsLoading(false);
      console.log("content service ", error);
    }
  };

  const selectedChannelTemplate = useCallback(
    (id, type, data) => {
      console.log("shoow", id, type, data);
      let updatedChannelStatus = contentStudioData
        ? contentStudioData.channelStatus
        : contentStudioDataRedux.channelStatus;
      let updatedVariations = contentStudioData.variations.map((_v) => {
        let typeHandler =
          type.toLowerCase() == "message" ? "sms" : type.toLowerCase();
        if (_v.id === id) {
          _v.templates[typeHandler] = data;
        }
        return _v;
      });

      submitButtonRef.current =
        updatedVariations.length > 0 &&
        updatedVariations.map((_s) => {
          return {
            id: _s.id,
            status: checkPendingTemplate(_s, updatedChannelStatus),
          };
        });

      console.log(
        "submitButtonRef.current temp select",
        submitButtonRef.current
      );

      setContentStudioData((prevState) => ({
        ...prevState,
        variations: updatedVariations,
      }));
    },
    [contentStudioData]
  );
  const filePreviewHandler = useCallback(
    () => setFilePreview(!filePreview),
    [filePreview]
  );

  const submitContentStudio = async () => {
    let postApiAction = pathname.includes("edit")
      ? CONTENT_STUDIO_UPDATE + `${getId}`
      : CONTENT_ADD_POST;
    if (!contentStudioData?.content_studio_content_id) return false;
    let postData = {
      content_studio_name: contentStudioData?.content_studio_name,
      content_studio_content_id: contentStudioData?.content_studio_content_id,
      variations: contentStudioData?.variations?.map((_p) => {
        return {
          variation_name: _p.variation_name,
          templates: Object.keys(_p.templates).reduce((obj, eachTemplate) => {
            if (_p.templates[eachTemplate].id !== "") {
              obj[eachTemplate] = _p.templates[eachTemplate].id;
            }
            return obj;
          }, {}),

          spam_status: _p.spam_status,
        };
      }),
    };
    const response = await postService(postApiAction, postData);
    if (response.data.success) {
      toast.success(response.data.message);

      redirectTo("content-studio/listing");
    } else {
      toast.error(response.data.message);
    }
    console.log("ADD_RESPONSE ####", postData, contentStudioData);
  };

  const editContentFileHandler = (e) =>
    setShowContentListModal(!showContentListModal);
  const spamScoreModalHandler = (id) => {
    variationId.current = id;
    setShowSpamScoretModal(!showSpamScoretModal);
  };
  const deleteModalHandler = () => setDeleteModalShow(!deleteModalShow);

  const getContentName = (e) => setContentName(e.target.value);
  const saveContentStudioName = () => {
    setContentStudioData({
      ...contentStudioData,
      is_content_name_edit: !contentStudioData?.is_content_name_edit,
      content_studio_name: contentName,
    });
  };
  const cancelContentStudioName = () => {
    setContentStudioData({
      ...contentStudioData,
      is_content_name_edit: !contentStudioData?.is_content_name_edit,
    });
    setContentName(contentStudioData?.content_studio_name);
  };
  const editContentStudioName = () => {
    setContentStudioData({
      ...contentStudioData,
      is_content_name_edit: !contentStudioData?.is_content_name_edit,
    });
  };

  const isSpamScoreDone = useCallback(
    (id, data) => {
      console.log("spamScoreDone data", id, data);
      if (data) {
        setContentStudioData((prevState) => ({
          ...prevState,
          variations: contentStudioData.variations.map((_v) => {
            if (_v.id === id) {
              _v.is_checkSpam_disable = true;
            }
            return _v;
          }),
        }));
      }
    },
    [contentStudioData]
  );
  const editVariation = (id) =>
    setContentStudioData((prevState) => ({
      ...prevState,
      variations: contentStudioData.variations.map((_v) => {
        if (_v.id === id) {
          _v.is_checkSpam_disable = false;
        }
        return _v;
      }),
    }));
  const selectContentFile = useCallback(
    (item) => {
      setContentStudioData({
        ...contentStudioData,
        content_studio_content_id: item?.id,
        content_studio_content_name: item?.fileName,
        content_studio_content_icon: item?.fileTypeIcon,
        content_studio_content_publish_date: item?.publicationDate,
        content_studio_content_description: item?.fileDescription,
        content_studio_content_type: item?.fileType,
        content_studio_content_created_by: item?.createdBy,
        content_studio_content_url: item.fileLink,
      });
      setShowContentListModal(false);
    },
    [contentStudioData]
  );
  // console.log(
  //   "isSpamScoreButtonDisable",
  //   isSpamScoreButtonDisable(0),
  //   isSubmitButtonDisable
  // );
  return (
    <>
      <Row>
        {/* Content Studio Name Goes Here*/}
        <div className="d-flex justify-content-start align-items-center gap-2 mb-2">
          {" "}
          {contentStudioData.is_content_name_edit ? (
            <>
              <input
                type="text"
                value={contentName}
                onChange={getContentName}
                className="border-1 rounded-3 px-2 py-1"
              />
              <Button
                variant="success"
                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                type="submit"
                onClick={saveContentStudioName}
                disabled={
                  contentName === contentStudioData?.content_studio_name
                }
              >
                {findIcon("Check", "outline", "18")}
              </Button>
              <Button
                variant="danger"
                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                type="submit"
                onClick={cancelContentStudioName}
              >
                {findIcon("Close-2", "outline", "14")}
              </Button>
            </>
          ) : (
            contentStudioData?.content_studio_name && (
              <>
                <h4 className="mb-0">
                  {contentStudioData?.content_studio_name}
                </h4>
                {!view && (
                  <span
                    onClick={editContentStudioName}
                    className="cursor-pointer d-inline-flex"
                  >
                    {findIcon("Pencil", "dual-tone", "20")}
                  </span>
                )}
              </>
            )
          )}
        </div>

        <Col lg="12">
          <Card>
            <Card.Body>
              {view && (
                <div className="d-flex justify-content-end gap-2">
                  <Button
                    variant="success"
                    className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                    type="submit"
                    onClick={() => redirectTo(`content-studio/edit/${getId}`)}
                  >
                    {findIcon("Pencil", "dual-tone", "20")}
                  </Button>
                  {/* <Button
                    variant="danger"
                    className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                    type="submit"
                    // onClick={deleteContentStudio}
                  >
                    {findIcon("Trash", "dual-tone", "20")}
                  </Button> */}
                </div>
              )}
              <Row>
                {/* <Col lg={templateView ? "12" : "9"}> */}
                <Col lg={view ? "12" : "9"}>
                  <div className="d-flex mt-3 p-3 border border-1 rounded-3">
                    <div
                      className={`d-flex align-items-center justify-content-center avatar-50 rounded-3 p-2 flex-shrink-0 bg-soft-primary`}
                    >
                      {/* {findIcon("Whatsapp", "dual-tone")} */}
                      {contentStudioData?.content_studio_content_icon && (
                        <Image
                          src={contentStudioData?.content_studio_content_icon}
                          className="mw-100 mx-100 h-auto icon-24"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="flex-grow-1 ms-3 d-flex flex-column justify-content-center">
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        {contentStudioData?.content_studio_content_name && (
                          <>
                            <h6 className="mb-0">
                              {contentStudioData?.content_studio_content_name}
                            </h6>

                            {!view && (
                              <span
                                onClick={editContentFileHandler}
                                className="cursor-pointer d-inline-flex"
                              >
                                {findIcon("Pencil", "dual-tone", "20")}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      {contentStudioData?.content_studio_content_publish_date && (
                        <h6 className="mt-1 fw-normal text-secondary">
                          <small>
                            {dayjs(
                              contentStudioData?.content_studio_content_publish_date
                            ).format("DD MMM YYYY")}
                          </small>
                        </h6>
                      )}
                      {contentStudioData?.content_studio_content_description && (
                        <p className="mb-2">
                          {
                            contentStudioData?.content_studio_content_description
                          }
                        </p>
                      )}
                      <div class="d-flex justify-content-start gap-2">
                        {contentStudioData?.content_studio_content_created_by && (
                          <span class="fs-6">
                            Created By{" "}
                            {
                              contentStudioData?.content_studio_content_created_by
                            }
                          </span>
                        )}
                      </div>
                    </div>

                    <Button
                      variant="soft-primary"
                      onClick={filePreviewHandler}
                      className="rounded-circle flex-shrink-0 avatar-40 lh-1 d-flex align-items-center justify-content-center p-1"
                    >
                      {findIcon("Eye", "solid", "24")}
                    </Button>
                  </div>
                </Col>
                {!view && (
                  <Col lg="3">
                    <div className="d-flex flex-column align-items-start gap-2 p-3 bg-soft-primary rounded-3">
                      <h5 className="mb-1">Channels</h5>
                      <Form.Check className="form-switch form-check-reverse w-100">
                        <FormCheck.Input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          checked={contentStudioData?.channelStatus?.whatsapp}
                          onChange={(e) =>
                            channelsCheckHandler("whatsapp", e.target.checked)
                          }
                        />
                        <FormCheck.Label
                          className={`form-check-label d-flex gap-2 align-items-center w-100`}
                          htmlFor="flexSwitchCheckChecked"
                        >
                          {findIcon("Whatsapp", "dual-tone", "20")}WhatsApp
                        </FormCheck.Label>
                      </Form.Check>
                      <Form.Check className="form-switch form-check-reverse w-100">
                        <FormCheck.Input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked2"
                          checked={contentStudioData?.channelStatus?.email}
                          onChange={(e) =>
                            channelsCheckHandler("email", e.target.checked)
                          }
                        />
                        <FormCheck.Label
                          className={`form-check-label d-flex gap-2 align-items-center w-100`}
                          htmlFor="flexSwitchCheckChecked2"
                        >
                          {findIcon("Email", "dual-tone", "20")}
                          Email
                        </FormCheck.Label>
                      </Form.Check>
                      <Form.Check className="form-switch form-check-reverse w-100">
                        <FormCheck.Input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked3"
                          checked={contentStudioData?.channelStatus?.sms}
                          onChange={(e) =>
                            channelsCheckHandler("sms", e.target.checked)
                          }
                        />
                        <FormCheck.Label
                          className={`form-check-label d-flex gap-2 align-items-center w-100`}
                          htmlFor="flexSwitchCheckChecked3"
                        >
                          {findIcon("Message-2", "dual-tone", "20")}
                          SMS
                        </FormCheck.Label>
                      </Form.Check>
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg="12" className="mt-4">
                  {contentStudioData?.variations?.length > 0 &&
                    contentStudioData?.variations?.map((_v, index) => (
                      <Card
                        className={`bg-soft-${
                          _v.is_checkSpam_disable ? "secondary" : "primary"
                        } mb-3`}
                        key={index + 1}
                      >
                        <Card.Header className="align-items-center p-3 pb-0">
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            {_v.is_variation_name_edit ? (
                              <>
                                <input
                                  type="text"
                                  value={variationName}
                                  onChange={getVariationName}
                                  className="border-0 rounded-3 px-2 py-1"
                                />
                                <Button
                                  variant="success"
                                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                  type="submit"
                                  onClick={() =>
                                    saveVariationNameHandler(_v.id)
                                  }
                                  disabled={variationName === _v.variation_name}
                                >
                                  {findIcon("Check", "outline", "18")}
                                </Button>
                                <Button
                                  variant="danger"
                                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                  type="submit"
                                  onClick={() =>
                                    cancelVariationNameHandler(_v.id)
                                  }
                                >
                                  {findIcon("Close-2", "outline", "14")}
                                </Button>
                              </>
                            ) : (
                              _v.variation_name && (
                                <>
                                  <h5 className="mb-0">{_v.variation_name}</h5>
                                  {(!view || _v.variation_name) && (
                                    <span
                                      onClick={() => editVariationName(_v.id)}
                                      className="cursor-pointer d-inline-flex"
                                    >
                                      {findIcon("Pencil", "dual-tone", "20")}
                                    </span>
                                  )}
                                </>
                              )
                            )}
                          </div>
                          {!view && (
                            <div className="justify-content-center align-items-center d-flex gap-2">
                              {_v.is_checkSpam_disable ? (
                                <Button
                                  variant="success"
                                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                  type="submit"
                                  onClick={() => editVariation(_v.id)}
                                >
                                  {findIcon("Pencil", "dual-tone", "20")}
                                </Button>
                              ) : (
                                <Button
                                  variant="outline-primary"
                                  className="flex-grow-1 flex-md-grow-0"
                                  type="submit"
                                  disabled={isSpamScoreButtonDisable(_v.id)}
                                  onClick={() => spamScoreModalHandler(_v.id)}
                                >
                                  Check Spam Score & Self Test
                                </Button>
                              )}
                              {contentStudioData?.variations?.length > 1 && (
                                <Button
                                  variant="danger"
                                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                  type="submit"
                                  onClick={() => {
                                    deleteVariationId.current = _v.id;
                                    deleteVariationName.current =
                                      _v.variation_name;
                                    deleteModalHandler();
                                  }}
                                >
                                  {findIcon("Trash", "solid", "18")}
                                </Button>
                              )}
                            </div>
                          )}
                        </Card.Header>
                        <Card.Body className="p-3">
                          <Row className="mt-n3">
                            <Col lg={4} className="mt-3">
                              <SelectTemplateCard
                                svg={findIcon("Whatsapp", "dual-tone", "22")}
                                colors={
                                  contentStudioData?.channelStatus?.whatsapp
                                    ? "success"
                                    : "secondary"
                                }
                                type="WhatsApp"
                                view={view}
                                selectedTemplateData={
                                  _v?.templates?.whatsapp ?? {}
                                }
                                selectedChannelTemplate={
                                  selectedChannelTemplate
                                }
                                options={templateList?.whatsapp}
                                id={_v.id}
                                // templateView={templateView}
                                isChannelEnable={
                                  contentStudioData?.channelStatus?.whatsapp
                                }
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                            <Col lg={4} className="mt-3">
                              <SelectTemplateCard
                                svg={findIcon("Email", "dual-tone", "20")}
                                colors={
                                  contentStudioData?.channelStatus?.email
                                    ? "warning"
                                    : "secondary"
                                }
                                type="Email"
                                view={view}
                                selectedTemplateData={
                                  _v?.templates?.email ?? {}
                                }
                                selectedChannelTemplate={
                                  selectedChannelTemplate
                                }
                                options={templateList?.email}
                                id={_v.id}
                                // templateView={templateView}
                                isChannelEnable={
                                  contentStudioData?.channelStatus?.email
                                }
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                            <Col lg={4} className="mt-3">
                              <SelectTemplateCard
                                svg={findIcon("Message-2", "dual-tone", "20")}
                                colors={
                                  contentStudioData?.channelStatus?.sms
                                    ? "info"
                                    : "secondary"
                                }
                                type="Message"
                                view={view}
                                selectedTemplateData={_v?.templates?.sms ?? {}}
                                selectedChannelTemplate={
                                  selectedChannelTemplate
                                }
                                options={templateList?.sms}
                                id={_v.id}
                                // templateView={templateView}
                                isChannelEnable={
                                  contentStudioData?.channelStatus?.sms
                                }
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}

                  {!view && (
                    <div className="justify-content-center d-flex gap-3">
                      <Button
                        variant="outline-info"
                        className="flex-grow-1 flex-md-grow-0 gap-2 d-flex align-items-center justify-content-center border-dashed"
                        type="submit"
                        // disabled={createCsLoaing}
                        onClick={() => addVariationRow()}
                      >
                        {findIcon("Plus", "solid", "18")}
                        Add More Variation
                      </Button>
                    </div>
                  )}
                </Col>
                {view && (
                  <Col lg="12">
                    <Button
                      variant="secondary"
                      className="flex-grow-1 flex-md-grow-0 gap-2 d-flex align-items-center justify-content-center border-dashed"
                      type="submit"
                      onClick={() => {
                        redirectTo("content-studio/listing");
                      }}
                    >
                      {findIcon("Arrow Left", "outline", "18")}
                      Back
                    </Button>
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col
          className={`d-flex justify-content-${
            view ? "start" : "end"
          } mb-5 gap-2`}
        >
          {!view && (
            <>
              <Button className="" type="submit">
                Save as Draft
              </Button>
              <Button
                className=""
                type="submit"
                disabled={isSubmitButtonDisable}
                onClick={submitContentStudio}
              >
                {submitTextType} Content Studio
              </Button>
            </>
          )}
        </Col>
      </Row>
      <SpamScoreModal
        show={showSpamScoretModal}
        modalClose={spamScoreModalHandler}
        channelStatus={contentStudioData?.channelStatus}
        isSpamScoreDone={isSpamScoreDone}
        variationId={variationId.current}
      />
      <FilePreviewModal
        mediaType={contentStudioData?.content_studio_content_type}
        show={filePreview}
        viewModalClose={filePreviewHandler}
        title={contentStudioData?.content_studio_content_name}
        brand=""
        createdBy=""
        createdOn=""
        description=""
        icon=""
        file_url={contentStudioData?.content_studio_content_url}
        titleHtml=""
        coverImage=""
        descriptionHtml=""
        citation=""
      />
      <DeleteModal
        show={deleteModalShow}
        modalClose={deleteModalHandler}
        type={"content-studio"}
        onSubmit={deleteVariationRow}
        variationName={deleteVariationName.current}
      />
      <CreateContentStudioModal
        show={showContentListModal}
        handleCreateModalClose={editContentFileHandler}
        stepToShow={2}
        // nextStep={nextStep}
        // contentStudioDetails={contentStudioDetails}
        // getContentStudioName={getContentStudioName}
        selectTemplateForContent={selectContentFile}
      />
    </>
  );
};

export default memo(CreateContentStudio);
