import React from "react";
import { useParams } from "react-router-dom";

const Brand = () => {
  const { type } = useParams();
  console.log('type', type);
  return <>Brand {type}</>;
};

export default Brand;
