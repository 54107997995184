export const GET_HCP_DETAILS = "GET_HCP_DETAILS";
export const GET_HCP_ACTIVITY_LIST = "GET_HCP_ACTIVITY_LIST";
export const GET_HCP_ENGAGEMENT_METER_DATA = "GET_HCP_ENGAGEMENT_METER_DATA";
// export const POST_HCP_SPECIALITY_DASHBOARD_STATUS =
//   "GET_HCP_CHANGE_SPECIALITY_DASHBOARD_STATUS";
// export const POST_HCP_RECOMMENDATION_STATUS = "POST_HCP_RECOMMENDATION_STATUS";
// export const POST_HCP_REQUEST_DATA_CHNAGE = "POST_HCP_REQUEST_DATA_CHNAGE";
export const GET_HCP_RECOMMENDATION_STATUS = "GET_HCP_RECOMMENDATION_STATUS";
export const GET_HCP_ALL_ENGAGEMENT_DATA = "GET_HCP_ALL_ENGAGEMENT_DATA";
export const GET_HCP_ACTIVITY_TIMELINE_DATA = "GET_HCP_ACTIVITY_TIMELINE_DATA";
export const GET_HCP_TIME_OF_DAY_DATA = "GET_HCP_TIME_OF_DAY_DATA";
export const GET_HCP_DAY_OF_WEEK_DATA = "GET_HCP_DAY_OF_WEEK_DATA";
export const GET_HCP_NBA_VIEW_DATA = "GET_HCP_NBA_VIEW_DATA";

export const GET_HCP_DATA_COUNT = "GET_HCP_DATA_COUNT";
export const GET_HCP_ONBOARDING_BY_REGISTRATION = "GET_ONBOARDING_BY_REGISTRATION";
export const GET_HCP_RE_ONBOARDING_BY_REGISTRATION = "GET_RE_ONBOARDING_BY_REGISTRATION";
export const HCP_ONBOARDING_EFFICIENCY_BY_APPROVAL = "HCP_ONBOARDING_EFFICIENCY_BY_APPROVAL";
export const HCP_ONBOARDING_EFFICIENCY_BY_TASK_RATE = "HCP_ONBOARDING_EFFICIENCY_BY_TASK_RATE";
export const HCP_ONBOARDING_EFFICIENCY_BY_TASK_COMPLITION = "HCP_ONBOARDING_EFFICIENCY";
export const HCP_ANALYTICS_ON_REGISTER_DATE = "HCP_ANALYTICS_ON_REGISTER_DATE";
export const HCP_ANALYTICS_ON_ONBOARDED_DATE = "HCP_ANALYTICS_ON_ONBOARDED_DATE";
export const HCP_ANALYTICS_INDIA_TO_INTERNATIONAL = "HCP_ANALYTICS_INDIA_TO_INTERNATIONAL";
export const HCP_ANALYTICS_TOP_INTERNATIONAL_COUNTRIES = "HCP_ANALYTICS_TOP_INTERNATIONAL_COUNTRIES";
export const HCP_LIST_DATA = "HCP_LIST_DATA";
export const HCP_LIST_FILTER_DATA = "HCP_LIST_FILTER_DATA";


