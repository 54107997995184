import packageJson from "../../../package.json";
const env = process.env.REACT_APP_ENVIRONMENT;
const constants = {
  appName: "Engage",
  appLogo: "",
  appVersion: packageJson.version,
  fileStoragePath: process.env.REACT_APP_FILE_STORAGE_PATH,
};

const dev_config = {
  api_url: "https://engageapidev.clirdev.com/api", //"https://ciplacrmdevapi.clirdev.com/api", //process.env.REACT_APP_API_BASE_URL,
  api_base_url: "https://engageapidev.clirdev.com/api", //"https://ciplacrmdevapi.clirdev.com",
  short_link_url: process.env.REACT_APP_SHORT_LINK_API_BASE_URL,
  cms_api_url: "https://ciplacrm-api-ocqxdiivfa-el.a.run.app/api",
  sms_generator_url: "https://sms-generator-v01-ocqxdiivfa-el.a.run.app", //process.env.REACT_APP_TEMPLATE_SUGGESTION_URL,
};
const uat_config = {
  api_url: process.env.REACT_APP_API_BASE_URL,
  sms_generator_url: "https://sms-generator-v01-ocqxdiivfa-el.a.run.app", // process.env.REACT_APP_TEMPLATE_SUGGESTION_URL,
};
const prod_config = {
  api_url: process.env.REACT_APP_API_BASE_URL,
  sms_generator_url: "https://sms-generator-v01-ocqxdiivfa-el.a.run.app", // process.env.REACT_APP_TEMPLATE_SUGGESTION_URL,
};
const environment = () => {
  switch (env) {
    case "1":
      return dev_config;

    case "2":
      return uat_config;
    case "3":
      return prod_config;

    default:
      break;
  }
};

const app_config = {
  ...constants,
  ...environment(),
};
export default app_config;
