import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import {
  PRIORITY_DETAILS_GET,
  ALL_PRIORITY_POST,
  DELETE_PRIORITY_POST,
  ADD_PRIORITY_POST,
  UPDATE_PRIORITY_POST,
} from "../../../../../api/endpoints/priority-endpoints";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import toast from "react-hot-toast";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllPriorities = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [priorityList, setPriorityList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewPriority, setViewPriority] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      priority_id: "desc",
    },
  });
  //yup and formik for validation of form fields of priority_name

  const validationSchema = yup.object().shape({
    priority_name: yup.string().required("Priority Name is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      priority_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("values===>", values);

      const postValues = {
        priority_name: values.priority_name.trim(),
      };

      try {
        // setLoading(true);
        const response = await postService(
          isEdit
            ? UPDATE_PRIORITY_POST + "/" + selectedPriority
            : ADD_PRIORITY_POST,
          {
            ...postValues,
            // priority_active: 1,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchPriorities(post_json.current);
          isEdit && setIsEdit(false);
          // toast.success(isEdit ? "Priority Updated Successfully" : "New Priority Added Successfully");
          toast.success(response?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("PRIORITY_SERVICE_ERROR", error);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  //fetch priorities
  const fetchPriorities = async (data) => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_PRIORITY_POST, data);
      console.log("PRIORITY_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setPriorityList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("PRIORITY_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (priority) => {
    console.log("priority===>", priority);
    let dataJson = {
      "Priority ID": priority.priority_id,
      "Priority Name": priority.priority_name,
      "Created By": priority.created_by.name,
      Status: priority.status.value,
      "Modified By": priority.modified_by,
    };
    setViewPriority(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deletePriority = async () => {
    try {
      // setLoading(true);
      const response = await getService(
        `${DELETE_PRIORITY_POST}/${selectedPriority}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchPriorities(post_json.current);
        toast.success("Priority Deleted Successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("PRIORITY_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchPriorities(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      fetchPriorities(payload);
    },
    [priorityList, loading, post_json.current]
  );
  const onDeletePriority = (priority) => {
    // alert("deleting" + priority.priority_id);
    setSelectedPriority(priority.priority_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Priority ID",
      accessor: "priority_id",
    },
    {
      Header: "Priority Name",
      accessor: "priority_name",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ value }) => value.name,
    },
    // {
    //   Header: "Modified By",
    //   accessor: "modified_by",
    // },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={(data) => viewModal(data)}
          onEdit={(data) => onEditPriorityClick(data)}
          onDelete={(data) => onDeletePriority(data)}
        />
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditPriorityClick = (priority) => {
    console.log("priority===>", priority);
    setIsEdit(true);
    setSelectedPriority(priority.priority_id);

    const editValues = {
      priority_name: priority?.priority_name,
      status: priority?.status?.id,
    };
    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Priority Names</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Priority Name
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                priorityList.length > 0 ? (
                  <ReactTable
                    data={priorityList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deletePriority}
      />
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewPriority}
      />
      {/* create a add Priority Name modal with Priority Name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Priority Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Priority Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Priority Name Name"
                name="priority_name"
                onChange={handleChange}
                value={values.priority_name}
                onBlur={handleBlur}
                isInvalid={touched.priority_name && errors.priority_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.priority_name}
              </Form.Control.Feedback>
            </Form.Group>

            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="status"
                  value={values?.status}
                  isInvalid={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Status</option>
                  <option value="1">Enabled</option>
                  <option value="2">Disabled</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllPriorities;
