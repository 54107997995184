import { useEffect } from "react";
import Echo from "@ably/laravel-echo";
import * as Ably from "ably";
import jwt_decode from "jwt-decode";
import Notify from "notifyjs";
import * as authSelectors from "../../store/auth/selectors";
import { useSelector } from "react-redux";
import app_config from "../../common/data/app_config";
const apiKey = process.env.REACT_APP_ABLY_API_KEY;

const useAbly = () => {
  let token = useSelector(authSelectors.token);
  useEffect(() => {
    if (token) {
      console.log("Token:", token);
      let decodedToken = jwt_decode(token);
      let clientId = decodedToken["x-ably-clientId"];
      console.log("clientId:", clientId);
      window.Ably = Ably; // make globally accessible to Echo
      window.Echo = new Echo({
        broadcaster: "ably",
        token: token,
        key: apiKey,
        authEndpoint: "https://engageapidev.clirdev.com/broadcasting/auth",
        // authEndpoint: app_config?.api_base_url + "/broadcasting/auth",
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
      //Register a callback for listing to connection state change
      window.Echo.connector.ably.connection.on((stateChange) => {
        console.log("LOGGER:: Connection event :: ", stateChange);
        if (stateChange.current === "connected") {
          console.log("connected to ably server");
        }
      });
      const notificationChannel = window.Echo.private(
        `notification.${clientId}`
      );
      console.log("Channel Name:", notificationChannel);
      notificationChannel.listen(".NotificationEvent", function (res) {
        console.log("new notification", res.message);
        let myNotification = new Notify("You have a new notification!", {
          body: res.message,
          notifyShow: true,
        });
        myNotification.show();
      });
    }
  }, []);
};

export default useAbly;
