import { useCallback, useEffect, useRef, useState, memo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  CONTENT_ADD_POST,
  CONTENT_STUDIO_LIST_POST,
} from "../../../../api/endpoints/content-studio-endpoints";
import postService from "../../../../api/services/post-service";
import Card from "../../../../components/bootstrap/card";
import ViewModal from "../../../../components/partials/common/view-modal";
import { findIcon } from "../../../dashboard/icons";
import FilterBar from "../../../../components/filter-bar";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import CreateContentStudioModal from "../component/create-content-studio-modal";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { useDispatch } from "react-redux";
import { getContentStudio } from "../../../../store/content-studio/content-studio.action";
import { changeTableStateToPayload } from "../../../../common/utils/utils";
import ReactTable from "../../../../lib/table/react-table";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import PageNotFound from "../../../PageNotFound";
import { toast } from "react-hot-toast";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import withAuthorization from "../../../../components/hoc/with-authorization";
const ContentStudioListing = () => {
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewContent, setViewContent] = useState({});
  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [contentList, setContentList] = useState([]);
  const [contentStudioDetails, setContentStudioDetails] = useState({});

  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();
  const createNewModalClose = useCallback(
    () => setCreateNewModalShow(false),
    [createNewModalShow]
  );

  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      content_studio_id: "desc",
    },
  });

  const selectTemplateForContent = useCallback(
    (item) => {
      const payloadData = {
        ...contentStudioDetails,
        content_studio_content_id: item?.id,
        // content_studio_content_name: item?.fileName,
        // content_studio_content_icon: item?.fileTypeIcon,
        // content_studio_content_publish_date: item?.publicationDate,
        // content_studio_content_description: item?.fileDescription,
        // content_studio_content_type: item?.fileType,
        // content_studio_content_created_by: item?.createdBy,
        // content_studio_content_url: item.fileLink,
        variations: [],
        // is_content_name_edit: false,
        // channelStatus: { whatsapp: true, email: true, sms: true },
      };
      dispatch(
        getContentStudio({
          ...contentStudioDetails,
          content_studio_content_id: item?.id,
          content_studio_content_name: item?.fileName,
          content_studio_content_icon: item?.fileTypeIcon,
          content_studio_content_publish_date: item?.publicationDate,
          content_studio_content_description: item?.fileDescription,
          content_studio_content_type: item?.fileType,
          content_studio_content_created_by: item?.createdBy,
          content_studio_content_url: item.fileLink,
          variations: [
            {
              id: 0,
              variation_name: "variation 1",
              templates: {
                whatsapp: {
                  id: "",
                  name: "",
                  content: "",
                  approve: "",
                },
                email: {
                  id: "",
                  name: "",
                  content: "",
                  approve: "",
                },
                sms: {
                  id: "",
                  name: "",
                  content: "",
                  approve: "",
                },
              },
              spam_status: 0,
              is_checkSpam_disable: false,
              is_variation_name_edit: false,
            },
          ],
          is_content_name_edit: false,
          channelStatus: { whatsapp: true, email: true, sms: true },
        })
      );
      submitContentStudio(payloadData);
      // createNewModalClose();
      // redirectTo("content-studio/create");
    },
    [contentStudioDetails]
  );

  const submitContentStudio = async (postData) => {
    showBackdrop();
    const response = await postService(CONTENT_ADD_POST, postData);
    if (response.data.success && response?.data?.data?.content_studio_id) {
      createNewModalClose();
      redirectTo(
        `content-studio/create/${response?.data?.data?.content_studio_id}`
      );
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    if (response.data) {
      hideBackdrop();
    }
  };

  const getContentStudioName = useCallback(
    (e) => {
      setContentStudioDetails({
        ...contentStudioDetails,
        content_studio_name: e.target.value,
      });
    },
    [contentStudioDetails]
  );
  const viewModalClose = useCallback(() => {
    setViewModalShow(false);
  }, [viewModalShow]);

  const contentStudioListColumns = [
    {
      Header: "ID",
      accessor: "content_studio_id",
    },
    {
      Header: "Content Studio Name",
      accessor: "content_studio_name",
    },

    {
      Header: "Variations",
      accessor: "variations",
      Cell: ({ value }) => Object.keys(value)?.length,
    },
    {
      Header: "Created On",
      accessor: "created_at",
    },
    // {
    //   Header: "Created By",
    //   accessor: "created_by",
    //   Cell: ({ value }) => value?.name ?? "None",
    // },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => value?.value ?? "None",
    },
    {
      Header: "Action",
      accessor: "Action",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          buttons={["view", "edit"]}
          row={row}
          onView={(data) => {
            redirectTo(
              "content-studio/details/" + row.original.content_studio_id
            );
          }}
          onEdit={() => {
            redirectTo("content-studio/edit/" + row.original.content_studio_id);
          }}
          onDelete={() => {}}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchContentStudioListing(post_json.current);
  }, []);

  const fetchContentStudioListing = async (data) => {
    try {
      const response = await postService(CONTENT_STUDIO_LIST_POST, data);
      setLoading(false);
      if (response.data.data.rows.length > 0) {
        setContentList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
      }
    } catch (error) {
      console.log("ACTION_SERVICE_ERROR", error);
      setLoading(false);
    }
  };

  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchContentStudioListing(payload);
    },
    [contentList, post_json.current]
  );

  console.log("content list", loading, contentList);
  return (
    <>
      <>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <div className="d-flex flex-column">
            <h3 className="m-0">Content Studio - All Content</h3>
          </div>
          <FilterBar />
          <Button
            variant="success"
            className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
            onClick={() => {
              setCreateNewModalShow(true);
            }}
          >
            {findIcon("Plus", "outline", "32")}
          </Button>
        </div>
        <Row>
          <Col lg="12">
            <Card className="h-100">
              <Card.Body>
                {!loading ? (
                  contentList?.length > 0 ? (
                    <ReactTable
                      data={contentList}
                      columns={contentStudioListColumns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      isRowSelectionAvailable={false}
                      recordsPerPage={10}
                    />
                  ) : (
                    <PageNotFound
                      title="No Content Studio Found "
                      message="You Can Create New Content Studio, BY clicking + Button"
                      backgroundImage={
                        "https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg"
                      }
                    />
                  )
                ) : (
                  <CommonTableLoader />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewContent}
      />
      <CreateContentStudioModal
        show={createNewModalShow}
        handleCreateModalClose={createNewModalClose}
        contentStudioDetails={contentStudioDetails}
        getContentStudioName={getContentStudioName}
        selectTemplateForContent={selectTemplateForContent}
      />
    </>
  );
};

export default memo(ContentStudioListing);
