import { memo, Fragment } from "react";

// React-bootstrap
import { Row, Col, Form, Image, Button, Container } from "react-bootstrap";

//Img
import img1 from "../../../../assets/images/avatars/01.png";

// Components
import Card from "../../../../components/bootstrap/card";
import Autheffect from "../components/auth-effect";
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.svg";

const Lockscreen = memo(() => {
  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Container>
          <Row className="d-flex align-items-center iq-auth-container justify-content-center">
            <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
              <Image
                src={ciplaUnityLogo}
                alt="CLIRNET Unity"
                className="ciplaLogo mb-2"
              />
              <h5 className="h5 lh-base">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </h5>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Body>
                  <Image
                    src={img1}
                    alt="User-Profile"
                    className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded"
                    loading="lazy"
                  />
                  <div className="mb-2">
                    <h4>Hi ! Austin Robertson</h4>
                    <p>Enter your password to acess the admin.</p>
                  </div>
                  <Form>
                    <Row>
                      <Col lg="12" className="mb-3">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="email"
                            className=""
                            id="floatingInput1"
                            autoComplete="Email address"
                            placeholder="Email address"
                          />
                          <Form.Label htmlFor="fname">Enter Email</Form.Label>
                        </Form.Floating>
                      </Col>
                      <Col lg="12" className="mb-3">
                        <Button className="btn btn-primary w-100">
                          Verify
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

Lockscreen.displayName = "Lockscreen";
export default Lockscreen;
