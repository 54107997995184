import React from "react";
import { Card, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useHcpDetails } from "../../../../../queries/query-hooks/hcp-profile-hook";
import HcpMedicalSkeleton from "../../../../../skeleton/hcp/HcpProfileDetails/HcpMedicalSkeleton";

const HcpMedicalInformation = ({hcpDetail,isHcpDetailsLoading}) => {
  function extractDegreeNames(input) {
    return input.map((item) => item.medical_degree.degree_name).join(",");
  }

  function extractInterestSpecNames(data) {
    const degreeNames = data.map((item) => item.speciality.speciality_name);
    const result = degreeNames.join(", ");
    return result;
  }

  // const { id: hcpId } = useParams();
  // //   const commonPayload = { hcp_id: hcpId };
  // const onSuccess = (response) => {
  //   //response
  //   //console.log("reponse", response);
  // };

  // const {
  //   data: hcpDetail,
  //   refetch: refetchHcpDetails,
  //   isLoading,
  // } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  if (isHcpDetailsLoading) {
    return <HcpMedicalSkeleton />;
  }

  return (
    <Card className="h-100 bg-soft-info">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">HCP's Medical Information</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
          <Row>
            <div className="col-7 mb-3">Degree</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_medical_degrees?.length > 0
                ? extractDegreeNames(hcpDetail?.hcp_to_medical_degrees)
                : "NA"}
            </div>

            <div className="col-7 mb-3">Practice Speciality</div>
            <div className="col-5 mb-3">
              {hcpDetail?.hcp_to_primary_speciality?.speciality_name ?? "NA"}
            </div>
            <div className="col-7 mb-3">Interest Specialty</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_interest_speciality?.length > 0
                ? extractInterestSpecNames(
                    hcpDetail?.hcp_to_interest_speciality
                  )
                : "NA"}
            </div>
            <div className="col-7 mb-3">Council</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_medical_council?.council_name ?? "NA"}
            </div>
            <div className="col-7 mb-3">Association </div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_association
                ? hcpDetail?.hcp_to_association.association_name
                : "NA"}
            </div>
            <div className="col-7 mb-3">Register Number</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_register_number
                ? hcpDetail?.hcp_register_number
                : "NA"}
            </div>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default HcpMedicalInformation;
