import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import ReactTable from "../../../../../lib/table/react-table";
import { useParams } from "react-router-dom";
import { useGetNbaViewData } from "../../../../../queries/query-hooks/hcp-profile-hook";
import Skeleton from "../../../../../components/skeleton";

const NbaView = ({hcpId}) => {
  const [nbaTotalViewCount, setnbaTotalViewCount] = useState(0);
  const nbaColumn = [
    // {
    //   Header: "Channel",
    //   accessor: "channel",
    //   // Filter: DefaultColumnFilter,
    // },
    {
      Header: "Content ID",
      accessor: "content_id",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Content Type",
      accessor: "content_type",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Title",
      accessor: "content_title",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
          >
            <span className="btn-inner">{row.original.actionDetails}</span>
          </Button>
        </div>
      ),

      // Filter: DefaultColumnFilter,
    },
  ];

  // const { id: hcpId } = useParams();
  const onNbaSuccess = (res) => {
    if (res?.success) {
      setnbaTotalViewCount(res?.totalCount);
    }
  };

  const { data: nbaViewData, isLoading: isNbaViewDataLoading } =
    useGetNbaViewData(hcpId, !!hcpId, onNbaSuccess);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">{
            isNbaViewDataLoading ? <Skeleton width={100} height={40} /> : <>NBA View </>
          }</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100 overflow-auto">
          {!isNbaViewDataLoading && nbaViewData?.rows?.length > 0 ? (
            <ReactTable
              data={nbaViewData.rows}
              columns={nbaColumn}
              tdClass={"threeColTable"}
              thClass={"threeColTable"}
              // onTableStateChange={onTableStateChange}
              recordsTotal={nbaTotalViewCount}
              // initialState={post_json.current}
              // isRowSelectionAvailable={true}
              // onRowSelectionChange={(selectedList) =>
              //   onRowSelectionChange(selectedList)
              // }
              // recordsPerPage={post_json.current.pagination.limit}
            />
          ) : isNbaViewDataLoading ? (
            <Skeleton width={600} height={400} />
          ) : (
            <>No Data Found</>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default NbaView;
