import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Modal,
  Nav,
  Row,
  Tab,
  Dropdown,
  Image,
} from "react-bootstrap";
import Select from "react-select";
import { findIcon } from "../../../dashboard/icons";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Circularprogressbar from "../../../../components/circularprogressbar";
import ReactTable from "../../../../lib/table/react-table";
import Flatpickr from "react-flatpickr";
import medwiki from "../../../../assets/images/Medwiki.svg";
import ebook from "../../../../assets/images/EBook.svg";

const EngagementTaskDetails = () => {
  const ChatMode = [
    { value: "poke", label: "Poke" },
    { value: "poke 2", label: "poke 2" },
  ];

  const growth = [
    {
      // color: variableColors.primary,
      value: 15,
      id: "circle-progress-01",
      svg: <>15</>,
      name: "Certificate",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 3,
      id: "circle-progress-01",
      svg: <>3</>,
      name: "DocTube",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 25,
      id: "circle-progress-01",
      svg: <>25</>,
      name: "CPD",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 10,
      id: "circle-progress-01",
      svg: <>10</>,
      name: "Training",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 15,
      id: "circle-progress-01",
      svg: <>15</>,
      name: "Dashboard",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 3,
      id: "circle-progress-01",
      svg: <>3</>,
      name: "Digital CME",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 25,
      id: "circle-progress-01",
      svg: <>25</>,
      name: "Clinical Videos",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 60,
      id: "circle-progress-01",
      svg: <>60</>,
      name: "MedWiki",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 90,
      id: "circle-progress-01",
      svg: <>10</>,
      name: "SPQS",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 90,
      id: "circle-progress-01",
      svg: <>25</>,
      name: "Association Channels",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 90,
      id: "circle-progress-01",
      svg: <>10</>,
      name: "MasterCast",
      start: 0,
      end: 100,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 90,
      id: "circle-progress-01",
      svg: <>100</>,
      name: "TeleMed Lite",
      start: 120,
      end: 560,
      duration: 3,
    },
    {
      // color: variableColors.primary,
      value: 30,
      id: "circle-progress-01",
      svg: <>100</>,
      name: "Ebook",
      start: 120,
      end: 560,
      duration: 3,
    },
  ];

  const engagement_data = {
    data: [
      {
        slno: "1",
        pagetitle: "archiveVideoDetail",
        pageid: "438",
        servicetype: "Clinical Videos",
        specialityname: "Nephrology",
        duration: "0",
        utmsource: "MESSSMSPU2",
        datetime: "2023-06-12 12:50:17",
      },
      {
        slno: "2",
        pagetitle: "feedDetail",
        pageid: "11463",
        servicetype: "MedWiki",
        specialityname: "Internal Medicine",
        duration: "0",
        utmsource: "MEUDEMAIL",
        datetime: "2023-06-12 12:50:17",
      },
      {
        slno: "3",
        pagetitle: "detail",
        pageid: "342",
        servicetype: "SPQS",
        specialityname: "Internal Medicine",
        duration: "0",
        utmsource: "SPQSMSU0305",
        datetime: "2023-06-12 12:50:17",
      },
      {
        slno: "4",
        pagetitle: "sessiondetail	",
        pageid: "3677",
        servicetype: "Digital CME	",
        specialityname: "Cardiology",
        duration: "0",
        utmsource: "ENGC",
        datetime: "2023-06-12 12:50:17",
      },
    ],
    columns: [
      //   {
      //     Header: "",
      //     accessor: "checkBox",
      //     Cell: ({ row }) => <input type="checkbox" />,
      //   },
      {
        Header: "Sl No",
        accessor: "slno",
      },
      {
        Header: "Page Title",
        accessor: "pagetitle",
      },
      {
        Header: "Page ID",
        accessor: "pageid",
      },
      {
        Header: "Service Type",
        accessor: "servicetype",
      },
      {
        Header: "Speciality Name",
        accessor: "specialityname",
      },
      {
        Header: "Duration",
        accessor: "duration",
      },
      {
        Header: "UTM Source",
        accessor: "utmsource",
      },
      {
        Header: "Date & Time",
        accessor: "datetime",
      },
    ],
  };

  const view = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const details = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-22"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
        fill="currentColor"
      ></path>
    </svg>
  );

  const medwiki_data = {
    data: [
      {
        pagetitle: "Clinical Video Detail",
        pageid: "1662",
        specialityname: "Nephrology",
        utmsource: "MEEPSMSCLNAFDLAUG25",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        pagetitle: "Clinical Video Detail",
        pageid: "1662",
        specialityname: "Nephrology",
        utmsource: "MEEPSMSCLNAFDLAUG25",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        pagetitle: "Clinical Video Detail",
        pageid: "1662",
        specialityname: "Nephrology",
        utmsource: "MEEPSMSCLNAFDLAUG25",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        pagetitle: "archiveVideoDetail",
        pageid: "1662",
        specialityname: "Nephrology",
        utmsource: "MEEPSMSCLNAFDLAUG25",
        datetime: "2023-06-12",
        view: "0",
      },
    ],
    columns: [
      {
        Header: "Page Title",
        accessor: "pagetitle",
      },
      {
        Header: "Speciality Name",
        accessor: "specialityname",
      },
      {
        Header: "Date",
        accessor: "datetime",
      },
      {
        Header: "view",
        accessor: "view",
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-center">
              <Button
                className="btn btn-icon btn-sm rounded-pill"
                to="#"
                role="button"
              >
                <span className="btn-inner">{details}</span>
              </Button>
            </div>
          );
        },
      },
    ],
  };

  const referance_data = {
    data: [
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
      {
        select: "",
        pagetitle: "archiveVideoDetail",
        specialityname: "Nephrology",
        datetime: "2023-06-12",
        view: "0",
      },
    ],
    columns: [
      {
        Header: "select",
        accessor: "select",
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-center">
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" />
              </Form.Group>
            </div>
          );
        },
      },
      {
        Header: "Page Title",
        accessor: "pagetitle",
      },
      {
        Header: "Speciality Name",
        accessor: "specialityname",
      },
      {
        Header: "Date",
        accessor: "datetime",
      },
      {
        Header: "view",
        accessor: "view",
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-center">
              <Button
                className="btn btn-icon btn-sm rounded-pill me-3"
                to="#"
                role="button"
              >
                <span className="btn-inner">{view}</span>
              </Button>
              <Button
                className="btn btn-icon btn-sm rounded-pill"
                to="#"
                role="button"
              >
                <span className="btn-inner">{details}</span>
              </Button>
            </div>
          );
        },
      },
    ],
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card>
        <Card.Body className="pt-2 pb-3">
          <div className="d-flex gap-3">
            <div className="flex-grow-1 d-flex gap-2 flex-column">
              <h4 className="m-0 d-flex align-items-center">
                Engagement:
                <span className="px-2">
                  Endoscopic Management Of Hydrocephalus
                </span>
                <div className="d-flex gap-3 fs-7 ms-auto">
                  <span className="text-dark">User ID : 100687</span>
                  <span> | </span>
                  <span className="text-dark">Assigned By Amit Singh</span>
                </div>
              </h4>
              <div className="m-0 d-flex gap-3 flex-wrap mt-2">
                <div className="d-flex align-items-center gap-2">
                  <Badge pill bg="danger" className="fw-normal fs-7 px-3 py-2">
                    High
                  </Badge>
                  <Badge pill bg="info" className="fw-normal fs-7 px-3 py-2">
                    In Progress
                  </Badge>
                  <Badge
                    pill
                    bg="soft-warning"
                    className="fw-normal fs-7 px-3 py-2"
                  >
                    Deadline 03 May 2023
                  </Badge>
                  <Button
                    variant="outline-info"
                    className="gap-2 border-dashed justify-content-start px-2 py-1 fs-7"
                  >
                    {findIcon("Plus", "solid", "20")} Request Data Change
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="gap-2 border-dashed justify-content-start px-2 py-1 fs-7"
                  >
                    {findIcon("meta", "outline", "20")} Meta Profile
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="gap-2 border-dashed justify-content-start px-2 py-1 fs-7"
                  >
                    {findIcon("document", "outline", "20")} View Documents
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="right-panel d-flex flex-wrap gap-2 justify-content-start"> */}
            {/* <Button
                variant="outline-info"
                className="gap-2 border-dashed justify-content-start px-3"
              >
                {findIcon("Plus", "solid", "20")} Request Data Change
              </Button> */}
            {/* <Button
                variant="outline-primary"
                className="gap-2 border-dashed justify-content-start px-3"
              >
                {findIcon("meta", "outline", "20")} Meta Profile
              </Button> */}
            {/* <Button
                variant="outline-secondary w-100"
                className="gap-2 d-flex align-items-center border-dashed justify-content-start px-3"
              >
                {findIcon("Plus", "solid", "20")} Request Data Change
              </Button> */}
            {/* </div> */}
            {/* <div className="d-flex flex-wrap gap-2 justify-content-start">
              <Button
                variant="outline-primary"
                className="gap-2 border-dashed justify-content-start px-3 rounded-3 bg-soft-primary"
              >
                {findIcon("Document", "outline", "60")}
              </Button>
            </div> */}
          </div>
        </Card.Body>
      </Card>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Row className="">
                <Col lg={12} className="mb-3">
                  <div className="p-3 pb-0 mb-3 h-100 border border-1 border-dashed border-info rounded-4">
                    <div className="pb-2">
                      <span className="text-dark pe-2">Name :</span>
                      <span className="pe-2">Yash</span>
                      <span className="pe-2">Kumar</span>
                      <span>Soni</span>
                    </div>
                    <div className="d-flex gap-4">
                      <div className="pb-2">
                        <span className="text-dark pe-2">Degree* :</span>
                        <span>MBBS</span>
                      </div>
                      <div className="pb-2">
                        <span className="text-dark pe-2">Age :</span>
                        <span>0</span>
                      </div>
                      <div className="pb-2">
                        <span className="text-dark pe-2">Gender :</span>
                        <span>Male</span>
                      </div>
                      <div className="">
                        <span className="text-dark pe-2">DND :</span>
                        <span>No</span>
                      </div>
                    </div>
                    <div className="d-flex gap-4">
                      <div className="">
                        <span className="text-dark pe-2">Place :</span>
                        <span>India,&nbsp;</span>
                        <span>Madhya Pradesh,&nbsp;</span>
                        <span>Bhopal</span>
                      </div>
                      <div className="">
                        <span className="text-dark pe-2">Zone :</span>
                        <span>Central India</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} className="mb-3">
                  <div className="border border-1 border-dashed border-info rounded-4 p-3 pb-0 mb-3 h-100">
                    <div className="d-flex gap-4">
                      <div className="pb-2">
                        <span className="text-dark pe-2">UTMsource*:</span>
                        <span>DADBSMS220707HTVV2</span>
                      </div>
                      <div className="pb-2">
                        <span className="text-dark pe-2">UID*:</span>
                        <span>100687</span>
                      </div>
                    </div>
                    <div className="d-flex gap-4">
                      <div className="pb-2">
                        <span className="text-dark pe-2">Service Type:</span>
                        <span>Mastercast_311</span>
                      </div>
                      <div className="pb-2">
                        <span className="text-dark pe-2">Service Date:</span>
                        <span>2022-07-07</span>
                      </div>
                    </div>
                    <div className="d-flex gap-4">
                      <div className="">
                        <span className="text-dark pe-2">
                          Registration Date*:
                        </span>
                        <span>2021-03-23 20:42:16</span>
                      </div>
                      <div className="">
                        <span className="text-dark pe-2">Description:</span>
                        <span>6</span>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </Col>
                <Col lg={12} className="mb-3">
                  <div className="border border-1 border-dashed border-success rounded-4 p-3 pb-1 h-100">
                    <div className="pb-2">
                      <span className="text-dark pe-2">Owner:</span>
                      <span>Archita Bagchi</span>
                    </div>
                    <div className="d-flex gap-4">
                      <div className="pb-2">
                        <span className="text-dark pe-2">Medium:</span>
                        <span>0</span>
                      </div>
                      <div className="pb-2">
                        <span className="text-dark pe-2">User Type:</span>
                        <span>Doctor</span>
                      </div>
                      <div className="pb-2">
                        <span className="text-dark pe-2">Status:</span>
                        <span>Approved</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="border border-1 border-dashed border-warning rounded-4 p-3 pb-1 h-100">
                    <div className="d-flex gap-4">
                      <div className="pb-2">
                        <span className="text-dark pe-2">
                          Primary Speciality:
                        </span>
                        <span>General</span>
                      </div>
                      <div className="pb-2">
                        <span className="text-dark pe-2">
                          Interest Speciality:
                        </span>
                        <span>General</span>
                      </div>
                    </div>
                    <div className="pb-2">
                      <span className="text-dark pe-2">Register Number:</span>
                      <span>27313</span>
                    </div>
                    <div className="pb-2">
                      <span className="text-dark pe-2">Council:</span>
                      <span>Madhya Pradesh Medical Council (MPMC)</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Row>
                <Col className="d-flex flex-column gap-3">
                  <div className="overflow-hidden d-slider1">
                    <Tab.Container id="activity" defaultActiveKey="first">
                      <Row>
                        <Col lg={12}>
                          <Nav
                            variant="pills"
                            className="d-flex gap-3 flex-row w-100 mw-100 px-0 fs-6 mb-3 justify-content-between"
                          >
                            <Nav.Item className="flex-grow-1 text-center">
                              <Nav.Link
                                eventKey="first"
                                className="rounded-2 border border-1"
                              >
                                Read
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="flex-grow-1 text-center">
                              <Nav.Link
                                eventKey="second"
                                className="rounded-2 border border-1"
                              >
                                Watch
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="flex-grow-1 text-center">
                              <Nav.Link
                                eventKey="third"
                                className="rounded-2 border border-1"
                              >
                                CME
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="flex-grow-1 text-center">
                              <Nav.Link
                                eventKey="forth"
                                className="rounded-2 border border-1"
                              >
                                Others
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col lg={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="progress-widget d-flex align-items-start gap-4">
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    5
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">Medwiki</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    2
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">Epaper</p>
                                  </div>
                                </div>
                              </div>
                              <div class="card bg-body shadow-none mb-xl-0 mt-3">
                                <div class="card-body p-0 no-header-table">
                                  <ReactTable
                                    data={medwiki_data.data}
                                    columns={medwiki_data.columns}
                                    isHeaderShow={false}
                                    isPaginationShow={false}
                                  />
                                </div>
                              </div>
                              <div
                                href="#all-engagement"
                                className="w-fit-content mt-3 border text-info border-info rounded-2 px-3 py-2 cursor-pointer"
                              >
                                View All Activity
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="progress-widget d-flex align-items-start gap-4">
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    5
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">
                                      Clinical Video
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    2
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">
                                      Grand Round
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    2
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">DocTube</p>
                                  </div>
                                </div>
                              </div>
                              <div class="card bg-body shadow-none mb-xl-0 mt-3">
                                <div class="card-body p-0 no-header-table">
                                  <ReactTable
                                    data={medwiki_data.data}
                                    columns={medwiki_data.columns}
                                    isHeaderShow={false}
                                    isPaginationShow={false}
                                  />
                                </div>
                              </div>
                              <div
                                href="#all-engagement"
                                className="w-fit-content mt-3 border text-info border-info rounded-2 px-3 py-2 cursor-pointer"
                              >
                                View All Activity
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <div className="progress-widget d-flex align-items-start gap-4">
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    5
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">Live CME</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    2
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">
                                      MasterCast
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="card bg-body shadow-none mb-xl-0 mt-3">
                                <div class="card-body p-0 no-header-table">
                                  <ReactTable
                                    data={medwiki_data.data}
                                    columns={medwiki_data.columns}
                                    isHeaderShow={false}
                                    isPaginationShow={false}
                                  />
                                </div>
                              </div>
                              <div
                                href="#all-engagement"
                                className="w-fit-content mt-3 border text-info border-info rounded-2 px-3 py-2 cursor-pointer"
                              >
                                View All Activity
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="forth">
                              <div className="progress-widget d-flex align-items-start gap-4">
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    5
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">SPQ</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    2
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">Channel</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    2
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">
                                      Certificate
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center px-3 cursor-pointer">
                                  <Circularprogressbar
                                    // stroke={item.color}
                                    width="60px"
                                    height="60px"
                                    Linecap="rounded"
                                    trailstroke="#ddd"
                                    strokewidth="3px"
                                    style={{ width: 60, height: 60 }}
                                    value="100"
                                    // id={item.id}
                                  >
                                    2
                                  </Circularprogressbar>
                                  <div className="progress-detail ms-0 mt-2 fs-6">
                                    <p className="mb-0 text-black">
                                      Discuss & Refer
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="card bg-body shadow-none mb-xl-0 mt-3">
                                <div class="card-body p-0 no-header-table">
                                  <ReactTable
                                    data={medwiki_data.data}
                                    columns={medwiki_data.columns}
                                    isHeaderShow={false}
                                    isPaginationShow={false}
                                  />
                                </div>
                              </div>
                              <div
                                href="#all-engagement"
                                className="w-fit-content mt-3 border text-info border-info rounded-2 px-3 py-2 cursor-pointer"
                              >
                                View All Activity
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="row">
        <Col md={6} className="h-50">
          <Card>
            <Card.Header>
              <h4 className="m-0">Engagement Recommended</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <ol>
                  <li className="ms-3 mb-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <h6 className="mt-1 mb-2 fw-lighter fs-6">
                          Nixing Stroke Risk: Expert Tips for General
                          Practitioners
                        </h6>
                        <h6 className="text-black fs-7 border border-dashed border-1 bg-soft-warning w-fit-content p-1 rounded-2">
                          E-Mail
                        </h6>
                      </div>
                      <Button
                        variant="secondary"
                        className="gap-2 border-dashed justify-content-start px-3 ms-auto"
                      >
                        Send
                      </Button>
                    </div>
                  </li>
                  <li className="ms-3 mb-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <h6 className="mt-1 mb-2 fw-lighter fs-6">
                          Unveiling the Many Faces of RSV Infection: A
                          Comprehensive Review
                        </h6>
                        <h6 className="text-black fs-7 bg-soft-info w-fit-content p-1 rounded-2">
                          SMS
                        </h6>
                      </div>
                      <Button
                        variant="secondary"
                        className="gap-2 border-dashed justify-content-start px-3 ms-auto"
                      >
                        Send
                      </Button>
                    </div>
                  </li>
                  <li className="ms-3 mb-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <h6 className="mt-1 mb-2 fw-lighter fs-6">
                          Uncovering the Hidden Epidemic: AI and ML's Role in
                          Early PCOS Detection
                        </h6>
                        <h6 className="text-black fs-7 bg-soft-danger w-fit-content p-1 rounded-2">
                          Push Notification
                        </h6>
                      </div>
                      <Button
                        variant="secondary"
                        className="gap-2 border-dashed justify-content-start px-3 ms-auto"
                      >
                        Send
                      </Button>
                    </div>
                  </li>
                </ol>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <h4 className="m-0">Canned Response</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <ol>
                  <li className="ms-3 mb-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <h6 className="mt-1 mb-2 fw-lighter fs-6">
                          Nixing Stroke Risk: Expert Tips for General
                          Practitioners
                        </h6>
                        <h6 className="text-black fs-7 border border-dashed border-1 bg-soft-warning w-fit-content p-1 rounded-2">
                          E-Mail
                        </h6>
                      </div>
                      <Button
                        variant="outline-info"
                        className="gap-2 border-dashed justify-content-start px-3 ms-auto"
                      >
                        Send
                      </Button>
                    </div>
                  </li>
                  <li className="ms-3 mb-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <h6 className="mt-1 mb-2 fw-lighter fs-6">
                          Unveiling the Many Faces of RSV Infection: A
                          Comprehensive Review
                        </h6>
                        <h6 className="text-black fs-7 bg-soft-info w-fit-content p-1 rounded-2">
                          SMS
                        </h6>
                      </div>
                      <Button
                        variant="outline-info"
                        className="gap-2 border-dashed justify-content-start px-3 ms-auto"
                      >
                        Send
                      </Button>
                    </div>
                  </li>
                  <li className="ms-3 mb-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <h6 className="mt-1 mb-2 fw-lighter fs-6">
                          Uncovering the Hidden Epidemic: AI and ML's Role in
                          Early PCOS Detection
                        </h6>
                        <h6 className="text-black fs-7 bg-soft-danger w-fit-content p-1 rounded-2">
                          Push Notification
                        </h6>
                      </div>
                      <Button
                        variant="outline-info"
                        className="gap-2 border-dashed justify-content-start px-3 ms-auto"
                      >
                        Send
                      </Button>
                    </div>
                  </li>
                </ol>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="h-50">
          <Card>
            <Card.Header className="border-bottom d-flex justify-content-between align-items-center rounded-0 p-3">
              <div className="d-flex align-items-center gap-3">
                <div className="position-relative">
                  <Image
                    src="https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=1024x1024&w=is&k=20&c=5q1NiElGDfT8IxbxhEWKTSufxyn3oFVHNnPNPyDe6as="
                    alt="users"
                    className="img-fluid avatar-50 rounded-pill object-fit-cover"
                    loading="lazy"
                  />
                </div>
                <div className="d-flex flex-column">
                  <h5 className="mb-0">User Nameaaaa</h5>
                  <small className="text-capitalize">Online</small>
                </div>
              </div>
              <div
                href="#all-engagement"
                className="ms-auto me-3 border borer-dashed text-info border-info rounded-2 px-3 py-2 cursor-pointer"
              >
                View All Engagement
              </div>
              <Dropdown className=" d-inline-flex">
                <Dropdown.Toggle as="span" bsPrefix="text-body d-inline-flex">
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 20.6788C10 21.9595 11.0378 23 12.3113 23C13.5868 23 14.6265 21.9595 14.6265 20.6788C14.6265 19.3981 13.5868 18.3576 12.3113 18.3576C11.0378 18.3576 10 19.3981 10 20.6788ZM10 12.0005C10 13.2812 11.0378 14.3217 12.3113 14.3217C13.5868 14.3217 14.6265 13.2812 14.6265 12.0005C14.6265 10.7198 13.5868 9.67929 12.3113 9.67929C11.0378 9.67929 10 10.7198 10 12.0005ZM12.3113 5.64239C11.0378 5.64239 10 4.60192 10 3.3212C10 2.04047 11.0378 1 12.3113 1C13.5868 1 14.6265 2.04047 14.6265 3.3212C14.6265 4.60192 13.5868 5.64239 12.3113 5.64239Z"
                      fill="#8A92A6"
                    ></path>
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu as="ul" align="end">
                  <li>
                    <Dropdown.Item className="d-flex gap-2">
                      <span className="d-flex">
                        <svg
                          width="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M21.3309 7.44251C20.9119 7.17855 20.3969 7.1552 19.9579 7.37855L18.4759 8.12677C17.9279 8.40291 17.5879 8.96129 17.5879 9.58261V15.4161C17.5879 16.0374 17.9279 16.5948 18.4759 16.873L19.9569 17.6202C20.1579 17.7237 20.3729 17.7735 20.5879 17.7735C20.8459 17.7735 21.1019 17.7004 21.3309 17.5572C21.7499 17.2943 21.9999 16.8384 21.9999 16.339V8.66179C21.9999 8.1623 21.7499 7.70646 21.3309 7.44251Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M11.9051 20H6.11304C3.69102 20 2 18.3299 2 15.9391V9.06091C2 6.66904 3.69102 5 6.11304 5H11.9051C14.3271 5 16.0181 6.66904 16.0181 9.06091V15.9391C16.0181 18.3299 14.3271 20 11.9051 20Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      Video Calling
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item className="d-flex gap-2">
                      <span className="d-flex">
                        <svg
                          width="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M14.4183 5.49C13.9422 5.40206 13.505 5.70586 13.4144 6.17054C13.3238 6.63522 13.6285 7.08891 14.0916 7.17984C15.4859 7.45166 16.5624 8.53092 16.8353 9.92995V9.93095C16.913 10.3337 17.2675 10.6265 17.6759 10.6265C17.7306 10.6265 17.7854 10.6215 17.8412 10.6115C18.3043 10.5186 18.609 10.0659 18.5184 9.60018C18.1111 7.51062 16.5027 5.89672 14.4183 5.49Z"
                            fill="currentColor"
                          ></path>
                          <path
                            opacity="0.4"
                            d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4078 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6698 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793Z"
                            fill="currentColor"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.0317 12.9724C15.0208 16.9604 15.9258 12.3467 18.4656 14.8848C20.9143 17.3328 22.3216 17.8232 19.2192 20.9247C18.8306 21.237 16.3616 24.9943 7.6846 16.3197C-0.993478 7.644 2.76158 5.17244 3.07397 4.78395C6.18387 1.67385 6.66586 3.08938 9.11449 5.53733C11.6544 8.0765 7.04266 8.98441 11.0317 12.9724Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      Voice Calling
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item className="d-flex gap-2">
                      <span className="d-flex">
                        <svg
                          width="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z"
                            fill="currentColor"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.9807 13.2901C11.2707 13.2801 10.7007 12.7101 10.7007 12.0001C10.7007 11.3001 11.2807 10.7201 11.9807 10.7301C12.6907 10.7301 13.2607 11.3001 13.2607 12.0101C13.2607 12.7101 12.6907 13.2901 11.9807 13.2901ZM7.37033 13.2901C6.67033 13.2901 6.09033 12.7101 6.09033 12.0101C6.09033 11.3001 6.66033 10.7301 7.37033 10.7301C8.08033 10.7301 8.65033 11.3001 8.65033 12.0101C8.65033 12.7101 8.08033 13.2801 7.37033 13.2901ZM15.3105 12.0101C15.3105 12.7101 15.8805 13.2901 16.5905 13.2901C17.3005 13.2901 17.8705 12.7101 17.8705 12.0101C17.8705 11.3001 17.3005 10.7301 16.5905 10.7301C15.8805 10.7301 15.3105 11.3001 15.3105 12.0101Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      Add to Group
                    </Dropdown.Item>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="chat-body chat-body--reply-task-details">
              <div className="chat-day-title">
                <span className="main-title">Feb 1,2021</span>
              </div>
              <div className="iq-message-body iq-other-user">
                <div className="chat-profile">
                  <Image
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1180&q=80"
                    alt="chat-user"
                    className="avatar-40 rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ross Taylor, 13:10</small>
                  <div className="d-flex align-items-center justify-content-start">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        I want some teachnic for fast code can you help?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-current-user">
                <div className="chat-profile">
                  <Image
                    src="https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=1024x1024&w=is&k=20&c=5q1NiElGDfT8IxbxhEWKTSufxyn3oFVHNnPNPyDe6as="
                    alt="chat-user"
                    className="avatar-40 object-fit-cover rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ellyse Perry, 16:40</small>
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        Hey, I am looking for the best dashboard template. Could
                        you please help me to find it out?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-other-user">
                <div className="chat-profile">
                  <Image
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1180&q=80"
                    alt="chat-user"
                    className="avatar-40 rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ross Taylor, 13:10</small>
                  <div className="d-flex align-items-center justify-content-start">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        I want some teachnic for fast code can you help?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-current-user">
                <div className="chat-profile">
                  <Image
                    src="https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=1024x1024&w=is&k=20&c=5q1NiElGDfT8IxbxhEWKTSufxyn3oFVHNnPNPyDe6as="
                    alt="chat-user"
                    className="avatar-40 object-fit-cover rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ellyse Perry, 16:40</small>
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        Hey, I am looking for the best dashboard template. Could
                        you please help me to find it out?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-other-user">
                <div className="chat-profile">
                  <Image
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1180&q=80"
                    alt="chat-user"
                    className="avatar-40 rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ross Taylor, 13:10</small>
                  <div className="d-flex align-items-center justify-content-start">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        I want some teachnic for fast code can you help?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-current-user">
                <div className="chat-profile">
                  <Image
                    src="https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=1024x1024&w=is&k=20&c=5q1NiElGDfT8IxbxhEWKTSufxyn3oFVHNnPNPyDe6as="
                    alt="chat-user"
                    className="avatar-40 object-fit-cover rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ellyse Perry, 16:40</small>
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        Hey, I am looking for the best dashboard template. Could
                        you please help me to find it out?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="px-3 py-3 rounded-0 border border-1 border-info border-dashed bg-body">
              <form>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control chat-input"
                    placeholder="Write a message"
                    aria-label="Recipient's username"
                  />

                  <span className="input-group-text mgs-icon send-icon">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8325 6.67463L8.10904 12.4592L1.59944 8.38767C0.66675 7.80414 0.860765 6.38744 1.91572 6.07893L17.3712 1.55277C18.3373 1.26963 19.2326 2.17283 18.9456 3.142L14.3731 18.5868C14.0598 19.6432 12.6512 19.832 12.0732 18.8953L8.10601 12.4602"
                        stroke="currentcolor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </form>
            </Card.Footer>
          </Card>
        </Col>
      </div>

      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h4 className="m-0">Detailed Activity</h4>
        </div>
      </div>

      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <ReactTable
                data={engagement_data.data}
                columns={engagement_data.columns}
              />
              {/* <CommonTableLoader /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Col lg="12">
        <Card>
          <Card.Body>
            <div className="iq-timeline m-0 d-flex align-items-center justify-content-between position-relative">
              <ul className="list-inline p-0 m-0 w-100">
                <li>
                  <div className="time">
                    <span>1st Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots"></div>
                    <h6 className="mb-1">Read Medwiki</h6>
                    {/* <div className="d-inline-block w-100">
                      <p>Created by David Janathan Aaron</p>
                    </div> */}
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots"></div>
                    <h6 className="mb-1">Send Engagement for Live CME</h6>
                    <div className="d-inline-block w-100">
                      <p>Sarthaki Banerjee</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time bg-success">
                    <span>2nd Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots border-success"></div>
                    <h6 className="mb-1">Join Live CME</h6>
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots border-success"></div>
                    <h6 className="mb-1">Read Epaper</h6>
                  </div>
                </li>
                <li>
                  <div className="time">
                    <span>3rd Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Send MasterCast Engagement</h6>
                    <div className="d-inline-block w-100">
                      <p>Pranabesh kumar Nandi</p>
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available"
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Send MedWiki Engagement</h6>
                    <div className="d-inline-block w-100">
                      <p>Marketing and Sales</p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    {/* <h6 className="mb-1">Accepted by Vendor</h6>  */}
                    <div className="d-inline-block w-100">
                      <p>Nice Marketing and Sales</p>
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available"
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time bg-success">
                    <span>6th Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-success"></div>
                    <h6 className="mb-1">Nice Marketing and Sales</h6>
                    <div className="d-inline-block w-100">
                      <p>Added video Files for Approval</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time">
                    <span>8th Jan 2020</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Commented By Complience</h6>
                    <div className="d-inline-block w-100">
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available, but the majority have suffered
                        alteration in some form, by injected humour, or
                        randomised words which don't look even slightly
                        believable."
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Commented By Marketing</h6>
                    <div className="d-inline-block w-100">
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available, but the majority have suffered
                        alteration in some form, by injected humour, or
                        randomised words which don't look even slightly
                        believable."
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time bg-success">
                    <span>9th Jan 2020</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Commented By Medical</h6>
                    <div className="d-inline-block w-100">
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available."
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots"></div>
                    <h6 className="mb-1">
                      Comments Approved by Marketing Manager
                    </h6>
                    <div className="d-inline-block w-100">
                      <p>Sarthaki Banerjee</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time">
                    <span>6th Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-success"></div>
                    <h6 className="mb-1">Nice Marketing and Sales</h6>
                    <div className="d-inline-block w-100">
                      <p>Resubmit video Files for Approval</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Card.Body>
        </Card>
      </Col>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Engagement Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row d-flex align-items-center justify-content-center mb-3">
            <div className="col-4">Activity Type</div>
            <div className="col-7">
              <Form.Select
                name="type"
                className="selectpicker form-control"
                data-style="py-0"
              >
                <option value="">Select Activity</option>
                <option value="A">A</option>
                <option value="B">B</option>
              </Form.Select>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row d-flex align-items-center justify-content-center mb-3">
            <div className="col-4">Page ID:</div>
            <div className="col-7">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="text"
                  id="page_id"
                  autoComplete="Page ID"
                  placeholder="Page ID"
                  name="from_email"
                />
                <Form.Label htmlFor="from_email">Page ID</Form.Label>
              </Form.Floating>
            </div>
            <div className="col-1">{findIcon("Eye", "solid", 22)}</div>
          </div>
          <div className="row d-flex align-items-center justify-content-center mb-3">
            <div className="col-4">Engagement Type:</div>
            <div className="col-7">
              <Form.Select
                name="type"
                className="selectpicker form-control"
                data-style="py-0"
              >
                <option value="">Select Clause</option>
                <option value="is">Is</option>
                <option value="is_not">Is Not</option>
              </Form.Select>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row d-flex align-items-center justify-content-center mb-3">
            <div className="col-4">Activity Type</div>
            <div className="col-7">
              <Form.Select
                name="type"
                className="selectpicker form-control"
                data-style="py-0"
              >
                <option value="">Select Clause</option>
                <option value="is">Is</option>
                <option value="is_not">Is Not</option>
              </Form.Select>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row d-flex align-items-center justify-content-center mb-3">
            <div className="col-4">Template:</div>
            <div className="col-7">
              <Form.Select
                name="type"
                className="selectpicker form-control"
                data-style="py-0"
              >
                <option value="">Select Clause</option>
                <option value="is">Is</option>
                <option value="is_not">Is Not</option>
              </Form.Select>
            </div>
            <div className="col-1">{findIcon("Eye", "solid", 22)}</div>
          </div>
          <div className="row d-flex align-items-center justify-content-center mb-3">
            <div className="col-4">Image URL:</div>
            <div className="col-7">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="text"
                  id="media_url"
                  autoComplete="Media URL"
                  placeholder="Media URL"
                  name="media_url"
                />
                <Form.Label htmlFor="from_email">Media URL</Form.Label>
              </Form.Floating>
            </div>
            <div className="col-1"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EngagementTaskDetails;

// eslint-disable-next-line no-lone-blocks
{
  /* <Swiper
                      as="ul"
                      className="p-0 m-0 mb-2 list-inline swiper-wrapper"
                      slidesPerView={5}
                      spaceBetween={15}
                      modules={[Navigation]}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      breakpoints={{
                        320: { slidesPerView: 1 },
                        550: { slidesPerView: 2 },
                        991: { slidesPerView: 4 },
                        1400: { slidesPerView: 4 },
                        1500: { slidesPerView: 4 },
                        1920: { slidesPerView: 6 },
                        2040: { slidesPerView: 6 },
                        2440: { slidesPerView: 6 },
                      }}
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      {growth.map((item, index) => {
                        return (
                          <SwiperSlide
                            className="card card-slide mb-2"
                            key={index}
                          >
                            <Card.Body className="p-3">
                              <div className="progress-widget flex-column">
                                <Circularprogressbar
                                  stroke={item.color}
                                  width="60px"
                                  height="60px"
                                  Linecap="rounded"
                                  trailstroke="#ddd"
                                  strokewidth="6px"
                                  style={{ width: 60, height: 60 }}
                                  value={item.value}
                                  id={item.id}
                                >
                                  {item.svg}
                                </Circularprogressbar>
                                <div className="progress-detail ms-0 mt-3 fs-6">
                                  <p className="mb-0">{item.name}</p>
                                </div>
                              </div>
                            </Card.Body>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <div className="swiper-button swiper-button-next d-flex align-items-center justify-content-center z-1 top-50 bg-warning rounded-circle text-white mt-n4 me-n2 swiper-button-icon-only">
                      {findIcon("Arrow Right", "outline", "20")}
                    </div>
                    <div className="swiper-button swiper-button-prev d-flex align-items-center justify-content-center z-1 top-50 bg-warning rounded-circle text-white mt-n4 ms-n2 swiper-button-icon-only">
                      {findIcon("Arrow Left", "outline", "20")}
                    </div> */
}
