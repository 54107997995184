import { memo, Fragment } from "react";

// components
import Card from "../../../../components/bootstrap/card";

// React-bootstrap
import { Row, Col, Button, Container, Image } from "react-bootstrap";

//component
import Autheffect from "../components/auth-effect";
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.svg";
const Authemail = memo(() => {
  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Container>
          <Row className="d-flex align-items-center iq-auth-container justify-content-center">
            <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
              <Image
                src={ciplaUnityLogo}
                alt="CLIRNET Unity"
                className="ciplaLogo mb-2"
              />
              <h5 className="h5 lh-base">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </h5>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Body>
                  <h4 className="pb-3">Success!</h4>
                  <p>
                    A email has been send to your email@domain.com. Please check
                    for an email from company and click on the included link to
                    reset your password.
                  </p>
                  <Button>Back to home</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

Authemail.displayName = "Authemail";
export default Authemail;
