import React, { memo } from "react";
import { Card, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useHcpDetails } from "../../../../../queries/query-hooks/hcp-profile-hook";
import HcpInformationSkeleton from "../../../../../skeleton/hcp/HcpProfileDetails/HcpInformationSkeleton";

const SystemInformation = ({hcpDetail ,isHcpDetailsLoading}) => {
  // const { id: hcpId } = useParams();
  // //   const commonPayload = { hcp_id: hcpId };
  // const onSuccess = (response) => {
  //   //response
  //   //console.log("reponse", response);
  // };

  // const {
  //   data: hcpDetail,
  //   refetch: refetchHcpDetails,
  //   isLoading,
  // } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  const getDndChannelNames = (input) => {
    const channelNames = input.map((item) => item.dnd.channel_name);
    return channelNames.join(", ");
  };

  if (isHcpDetailsLoading) {
    return <HcpInformationSkeleton />;
  }

  return (
    <Card className="h-100 bg-soft-warning">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">System Information</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
          <Row>
            <div className="col-7 mb-3">MDMID</div>
            <div className="col-5 mb-3">{hcpDetail?.hcp_id}</div>
            <div className="col-7 mb-3">DND</div>
            <div className="col-5 mb-3">
              {hcpDetail?.hcp_to_dnd?.length > 0
                ? getDndChannelNames(hcpDetail?.hcp_to_dnd)
                : "NA"}
            </div>
            <div className="col-7 mb-3">Registration Date</div>
            <div className="col-4 mb-3">
              {hcpDetail?.registration_date ?? "NA"}
            </div>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default memo(SystemInformation);
