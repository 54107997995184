import { Fragment, useEffect, useState, useRef, useCallback } from "react";

//react-bootstrap
import { Row, Col, Form } from "react-bootstrap";

//components
import Card from "../../../../../components/bootstrap/card";

// React-bootstrap
import { Button } from "react-bootstrap";
import {
  ADD_PRINCIPLE_ENTITY,
  ALL_PRINCIPLE_ENTITIES,
  DELETE_PRINCIPLE_ENTITY,
} from "../../../../../api/endpoints/principle-entity-endpoints";
import { useFormik } from "formik";
import * as yup from "yup";
import postService from "../../../../../api/services/post-service";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import React from "react";
import ReactTable from "../../../../../lib/table/react-table";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import { useLocation, useParams } from "react-router-dom";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { getService } from "../../../../../api/services/get-services";
import { GET_PRINCIPLE_ENTITY_BY_ID } from "../../../../../api/endpoints/principle-entity-endpoints";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllPrincipleEntities = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [principleEntites, setPrincipleEntities] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [viewedPrincipal, setViewedPrincipal] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedPrincipal, setSelectedPrincipal] = useState(null);
  const [principalId, setPrincipalId] = useState(null);
  const { pathname } = useLocation();
  const { redirectTo } = useRedirect();
  const { id } = useParams();
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
  });
  //yup and formik for validation of form fields of principle_entity_name
  const validationSchema = yup.object().shape({
    principle_entity_name: yup
      .string()
      .required("Principle Entity Name is required"),
    principle_entity_description: yup
      .string()
      .required("Principle Entity Description is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      principle_entity_name: "",
      principle_entity_description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("VALUES", values);
      //if id exists then add principle entity with parent id
      //else add principle entity with parent id 0
      let postObject = {};
      if (id) {
        postObject = {
          principal_entity_name: values.principle_entity_name,
          principal_entity_description: values.principle_entity_description,
          principal_entity_parent_id: id,
        };
      } else {
        postObject = {
          principal_entity_name: values.principle_entity_name,
          principal_entity_description: values.principle_entity_description,
          principal_entity_parent_id: 0,
        };
      }

      let response = await postService(ADD_PRINCIPLE_ENTITY, postObject);
      console.log("ADD_PRINCIPLE_ENTITY_RESPONSE", response);
      if (response.data.success) {
        fetchPrincipleEntities(post_json.current);
        resetForm();
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  const fetchPrincipleEntities = async (data) => {
    let response = await postService(ALL_PRINCIPLE_ENTITIES, data);
    console.log("PRINCIPLE_ENTITY_SERVICE_RESPONSE", response.data.data.rows);
    console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
    if (response.data.data.rows.length > 0) {
      setPrincipleEntities(response.data.data.rows);
      setTotalCount(response.data.data.totalCount);
      //   alert(response.data.data.totalCount);
      setLoading(false);
    }
  };
  const getPrincipalDetails = async (principal_entity_id) => {
    let viewedEntityDetails = await getService(
      GET_PRINCIPLE_ENTITY_BY_ID + "/" + principal_entity_id
    );
    if (viewedEntityDetails?.data?.data) {
      setViewedPrincipal(viewedEntityDetails?.data?.data);
    }
  };
  useEffect(() => {
    if (id) {
      post_json.current = {
        default: "all",
        pagination: {
          limit: 10,
          offset: 0,
        },
        filter: {
          principal_entity_parent_id: parseInt(id),
        },
      };
      setPrincipalId(id);
      getPrincipalDetails(id);
    } else {
      setViewedPrincipal(null);
    }
    fetchPrincipleEntities(post_json.current);
  }, [id]);

  const onViewPrincipleEntityClick = async (principle_entity) => {
    const {
      principal_entity_id,
      principal_entity_name,
      principal_entity_description,
    } = principle_entity;
    // alert(principalId)
    getPrincipalDetails(principal_entity_id);
    redirectTo("master-management/principle-entity/" + principal_entity_id);
  };
  const onEditPrincipleEntityClick = (principle_entity) => {
    console.log("principle_entity===>", principle_entity);
    setIsEdit(true);
    setValues({
      principle_entity_name: principle_entity.principal_entity_name,
      principle_entity_description:
        principle_entity.principal_entity_description,
    });
  };
  const onDeletePrincipleEntityClick = async () => {
    console.log("selectedPrincipal", selectedPrincipal);
    let response = await postService(DELETE_PRINCIPLE_ENTITY, {
      principal_entity_id: selectedPrincipal,
    });
    console.log("DELETE_PRINCIPLE_ENTITY_RESPONSE", response);
    if (response.data.success) {
      fetchPrincipleEntities(post_json.current);
    }
  };
  const onDeletePrincipal = (principle) => {
    setSelectedPrincipal(principle.principal_entity_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Principal Entity ID",
      accessor: "principal_entity_id",
    },
    {
      Header: "Principal Entity Name",
      accessor: "principal_entity_name",
    },
    {
      Header: "Principal Entity Description",
      accessor: "principal_entity_description",
    },
    {
      Header: "Principal Entity Parent ID",
      accessor: "principal_entity_parent_id",
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={(data) => {
            onViewPrincipleEntityClick(data);
          }}
          onEdit={(data) => onEditPrincipleEntityClick(data)}
          onDelete={(data) => onDeletePrincipal(data)}
        />
      ),
    },
  ];
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchPrincipleEntities(payload);
    },
    [principleEntites, loading, post_json.current]
  );
  console.log("id@@", viewedPrincipal?.principal_entity_id);
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="align-items-center pb-4">
              <div className="d-flex flex-column justify-content-start">
                <h4 className="card-title">Principal Entity</h4>
                {viewedPrincipal && (
                  <div className="d-flex flex-column justify-content-start mt-4">
                    <h6>
                      Principal entity name :{" "}
                      {viewedPrincipal.principal_entity_name}
                    </h6>
                    <h6>
                      Principal entity description :{" "}
                      {viewedPrincipal.principal_entity_description}
                    </h6>
                  </div>
                )}
              </div>

              <Button
                type="button"
                variant="warning"
                className="fw-bold rounded-3"
                onClick={() => setShowForm(!showForm)}
              >
                Add {id ? "Child " : ""}Principal Entity
              </Button>
            </Card.Header>
            <Card.Body className="p-0 pb-4">
              {showForm && (
                <div className="addForm p-4 pb-4 pt-0">
                  <Form>
                    <div className="row border border-1 py-3 m-0 rounded-3">
                      <div className="d-flex flex-row gap-3">
                        <Form.Floating className=" custom-form-floating form-group mb-0 flex-fill">
                          <Form.Control
                            type="text"
                            className=""
                            id="floatingInput6"
                            placeholder="Enter Entity Name"
                            name="principle_entity_name"
                            value={values.principle_entity_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.principle_entity_name &&
                              !!errors.principle_entity_name
                            }
                          />
                          <label htmlFor="floatingInput">Name</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.principle_entity_name}
                          </Form.Control.Feedback>
                        </Form.Floating>
                        <Form.Floating className=" custom-form-floating form-group mb-0 flex-fill">
                          <Form.Control
                            type="text"
                            className=""
                            id="floatingInput6"
                            placeholder="Enter Entity Description"
                            name="principle_entity_description"
                            value={values.principle_entity_description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.principle_entity_description &&
                              !!errors.principle_entity_description
                            }
                          />
                          <label htmlFor="floatingInput">Description</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.principle_entity_description}
                          </Form.Control.Feedback>
                        </Form.Floating>
                        <Button
                          type="button"
                          variant="btn btn-primary flex-fill"
                          onClick={handleSubmit}
                        >
                          {isEdit ? "Edit" : "Add"}
                        </Button>{" "}
                        <Button
                          type="button"
                          variant="btn btn-danger flex-fill"
                          onClick={() => setShowForm(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
              {!loading ? (
                principleEntites.length > 0 ? (
                  <ReactTable
                    data={principleEntites}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
        {/* View Page */}
        <DeleteModal
          show={deleteModalShow}
          modalClose={() => setDeleteModalShow(false)}
          onSubmit={onDeletePrincipleEntityClick}
        />
        {/* View Page Details */}
      </Row>
    </Fragment>
  );
};

export default AllPrincipleEntities;
