import SkeletonComponent from "@mui/material/Skeleton";
import React from "react";

const Skeleton = ({
  variant = "text",
  width = 60,
  height = 20,
  className = "mb-2",
  ...props
}) => { 
  return (
    <SkeletonComponent
      variant={variant}
      width={width}
      height={height}
      className={className}
      {...props}
    />
  );
};

export default Skeleton;
