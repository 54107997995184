import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ADD_SOCIAL_LINK_POST,
  ALL_SOCIAL_LINK_POST,
  DELETE_SOCIAL_LINK_POST,
  UPDATE_SOCIAL_LINK_POST,
} from "../../../../../api/endpoints/social-link-endpoints";
import toast from "react-hot-toast";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const SocialLink = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [SocialLinkList, setSocialLinkList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewSocialLink, setViewSocialLink] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedSocialLink, setSelectedSocialLink] = useState(null);
  const post_json = useRef({
    filter: {},
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      social_link_type_id: "desc",
    },
  });

  const columns = [
    {
      Header: "Social Link ID",
      accessor: "social_link_type_id",
    },
    {
      Header: "Social Link Type",
      accessor: "social_link_type_name",
      Cell: ({ row }) => {
        return row.values.social_link_type_name
          ? row.values.social_link_type_name
          : "";
      },
    },
    {
      Header: "Social Link Description",
      accessor: "social_link_type_description",
      Cell: ({ row }) => {
        return row.values.social_link_type_description
          ? row.values.social_link_type_description
          : "";
      },
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ row }) => {
        return row.values.created_by ? row.values.created_by.name : "";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        // <span className="d-flex align-items-start justify-content-start">
        //   {row.values.status?.value}
        // </span>
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="info"
            to="#"
            role="button"
            onClick={() => viewModal(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Eye", "dual-tone", 32)}
            </span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            onClick={() => onEditSocialLinkClick(row.original)}
          >
            <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="danger"
            to="#"
            role="button"
            onClick={() => onDeleteSocialLink(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Trash", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const validationSchema = yup.object().shape({
    social_link_type_name: yup
      .string()
      // .matches(/^[A-Za-z]+$/, "Only alphabetic characters are allowed")
      .trim()
      .required("Social Link Type is required"),
    social_link_type_description: yup
      .string()
      // .matches(/^[A-Za-z]+$/, "Only alphabetic characters are allowed")
      .trim()
      .required("Social Link Description is required"),
    status: yup.number(),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      social_link_type_name: "",
      social_link_type_description: "",
      // status: 1,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values===>", values);
      const postValues = {
        social_link_type_name: values.social_link_type_name.trim(),
        social_link_type_description:
          values.social_link_type_description.trim(),
      };

      try {
        //setLoading(true);
        const response = await postService(
          isEdit
            ? UPDATE_SOCIAL_LINK_POST + "/" + selectedSocialLink
            : ADD_SOCIAL_LINK_POST,
          {
            ...postValues,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          console.log("SOCIAL_LINK response", response);
          setLoading(false);
          setCreateModalShow(false);
          fetchSocialLink(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(response?.data?.message);
          // toast.success(isEdit ? "Social links Updated Successfully" : "New Social link Added Successfully");
        }
      } catch (error) {
        setLoading(false);
        console.log("SOCIAL_LINK_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  console.log("formic test", values, errors);

  const fetchSocialLink = async (data) => {
    try {
      // alert("fetching");
      //setLoading(true);
      const response = await postService(ALL_SOCIAL_LINK_POST, data);
      // console.log("SOCIAL_LINK_SERVICE response===>", response);
      if (response.data.data.rows.length > 0) {
        setSocialLinkList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("SOCIAL_LINK_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchSocialLink(post_json.current);
  }, []);

  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchSocialLink(payload);
    },
    [SocialLinkList, loading, post_json.current]
  );

  const viewModal = async (data) => {
    // console.log("Social Link===>", data);
    let dataJson = {
      "Social Link Type": data.social_link_type_name,
      "Social Link Description": data.social_link_type_description,
      "Created By": data.created_by.name,
      Status: data.status.value,
    };
    setViewSocialLink(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteSocialLink = async () => {
    try {
      //setLoading(true);
      const response = await getService(
        `${DELETE_SOCIAL_LINK_POST}/${selectedSocialLink}`
      );
      if (response) {
        // console.log("SOCIAL_LINK delete response===>", response);
        setLoading(false);
        setDeleteModalShow(false);
        fetchSocialLink(post_json.current);
        toast.success("Social Link Deleted Successfully");
      }
    } catch (error) {
      setLoading(false);
      // console.log("SOCIAL_LINK_SERVICE_ERROR", error);
    }
  };
  const onDeleteSocialLink = (data) => {
    setSelectedSocialLink(data.social_link_type_id);
    setDeleteModalShow(true);
  };
  const handleVariableStatus = (e) => {
    setValues({ ...values, status: parseInt(e.target.value) });
  };
  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditSocialLinkClick = (data) => {
    // console.log("Social link===>", data.status?.id);
    setIsEdit(true);
    setSelectedSocialLink(data.social_link_type_id);

    const editValues = {
      social_link_type_name: data?.social_link_type_name,
      social_link_type_description: data?.social_link_type_description,
      status: data?.status?.id,
    };

    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  useEffect(() => {
    console.log("values status===>", values.status);
  }, [values.status]);

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Social Link</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Social Link
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                SocialLinkList.length > 0 ? (
                  <ReactTable
                    data={SocialLinkList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteSocialLink}
      />
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewSocialLink}
      />
      {/* create a add Priority Name modal with Priority Name name and description */}
      <Modal
        show={createModalShow}
        // size="lg"
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Social Link Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Social Link Name */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Social Link Type Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Variable Name"
                name="social_link_type_name"
                onChange={handleChange}
                value={values.social_link_type_name}
                onBlur={handleBlur}
                isInvalid={
                  touched.social_link_type_name && errors.social_link_type_name
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.social_link_type_name}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Social Link Description */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Social Link Type Description{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Variable Name"
                name="social_link_type_description"
                onChange={handleChange}
                value={values.social_link_type_description}
                onBlur={handleBlur}
                isInvalid={
                  touched.social_link_type_description &&
                  errors.social_link_type_description
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.social_link_type_description}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Social Link status */}
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Variable Status</Form.Label>
                <Form.Select
                  type="type"
                  name="status"
                  value={values.status}
                  // onChange={handleVariableStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="1">Enable</option>
                  <option value="2">Disable</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default SocialLink;
