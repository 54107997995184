import { memo, useRef, useState, useEffect } from "react";
import { Col, Modal } from "react-bootstrap";
import smsMobile from "../../../../assets/images/mobile-bg.png";
import whatsappMobile from "../../../../assets/images/mobileWhatsapp.png";
import ReactEmailEditor from "../../../../lib/email-editor/react-email-editor";
import GrapejsEditor from "../../../../lib/email-editor/grapejs-editor";

const TemplatePreviewModal = ({
  show,
  modalClose,
  type,
  selectedTemplateData = {},
}) => {
  const emailEditorRef = useRef(null);
  const [presetHTML, setPresetHTML] = useState(null);
  const [editor, setEditor] = useState(null);
  console.log("selectedTemplateData", selectedTemplateData);
  useEffect(() => {
    if (type == "Email" && "email_content" in selectedTemplateData) {
      setPresetHTML(selectedTemplateData?.email_content);
    } else if (type == "Email" && "content" in selectedTemplateData) {
      setPresetHTML(selectedTemplateData?.content);
    } else {
    }
  }, [type, selectedTemplateData]);
  return (
    <>
      <Modal
        show={show}
        // size="lg"
        size={`${type === "Email" ? "" : "lg"}`}
        fullscreen={type === "Email" ? true : false}
        onHide={modalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">{selectedTemplateData?.name ?? ""}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex">
          {(type === "WhatsApp" || type === "Message") && (
            <Col lg={3} xs={12} className="mx-auto">
              <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                {/* <h5>Preview</h5> */}
                <div className="position-relative flex-fill">
                  <img
                    src={type === "WhatsApp" ? whatsappMobile : smsMobile}
                    alt="mobile"
                    className="h-auto w-100 d-block position-relative z-0"
                  />
                  <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                    <div className="h-100 rounded-5 p-3 mobile-preview position-relative mt-4">
                      <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base shadow-lg shadow bg-white">
                        {/* {selectedSms.content} */}
                        {selectedTemplateData?.content ?? ""}
                      </span>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </Col>
          )}
          {type === "Email" && presetHTML && (
            <div className="flex-grow-1 reactEmailEditorPrrnt">
              {/* {console.log("preset html", presetHTML)} */}
              <GrapejsEditor
                ref={emailEditorRef}
                previewMode
                presetData={presetHTML}
                setEditor={(e) => setEditor(e)}
                editor={editor}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(TemplatePreviewModal);
