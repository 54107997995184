import { memo, Fragment, useState, useEffect, useCallback } from "react";
import Select from "react-select";
//Components
import Card from "../../../../components/bootstrap/card";
import { Badge, Button, Form, FormCheck, Modal } from "react-bootstrap";
import { findIcon } from "../../../dashboard/icons";
import TemplateListModal from "./template-list-modal";
import TemplatePreviewModal from "./template-preview-modal";
import CreateTemplateModal from "../../../../components/partials/common/create-template-modal";
import { STATUS_CONSTANTS } from "../../action/constants/action-constants";

const SelectTemplateCard = memo(
  ({
    svg,
    colors,
    type,
    variationDetails,
    obj,
    createTemplate,
    options,
    isButtonEnable = true,
    templateView,
    variantionDisable,
    isChannelEnable = true,
    selectedTemplateData = {},
    selectedChannelTemplate,
    id,
    view,
    disableChannel,
  }) => {
    const [templateData, setTemplateData] = useState({});
    const [showTemplates, setShowTemplates] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [templateModalShow, setTemplateModalShow] = useState(false);
    // const [selectedTemplate, setSelectedTemplate] = useState(
    //   Object.keys(selectedTemplateData).length > 0 ? selectedTemplateData : null
    // );

    const getSelectedTemplate = (data) => {
      // console.log("template list data", data);
      setTemplateData(data);
    };
    //   []
    // );
    const showPreviewHandler = useCallback(() => {
      setShowPreview(!showPreview);
    }, [showPreview]);
    useEffect(() => {
      if (Object.keys(templateData).length > 0) {
        selectedChannelTemplate(id, type, templateData);
      }
    }, [templateData, id, type]);
    // useEffect(() => {
    //   if (!isButtonEnable) {
    //     setSelectedTemplate(null);
    //   }
    // }, [isButtonEnable]);
    // const showPreviewHandler = () => {
    //   if (!isButtonEnable) return;
    //   setShowPreview(true);
    // };
    let isTemplateSelected =
      Object.keys(selectedTemplateData).length > 0 &&
      selectedTemplateData?.id !== "" &&
      selectedTemplateData?.name !== "" &&
      selectedTemplateData?.approve !== ""
        ? true
        : false;

    const selectTemplate = () => {
      setShowTemplates(true);
    };
    const templateModalHandler = useCallback(() => {
      setTemplateModalShow(!templateModalShow);
    }, [templateModalShow]);
    let spamScoreDone = selectedTemplateData?.name && !disableChannel;

    console.log("selectedTemplateData ", selectedTemplateData);
    return (
      <Fragment>
        <Card className={`mb-0 ${isChannelEnable ? "" : "bg-soft-secondary"}`}>
          <Card.Body className="p-3">
            <div className="d-flex align-items-center">
              <div
                className={`d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-${colors}`}
              >
                {svg}
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="mb-0">{type}</h6>
                <h6 className="fw-normal mb-0 text-secondary">
                  <span className="small">
                    {isTemplateSelected
                      ? selectedTemplateData?.name
                      : "No template selected"}
                  </span>
                  {isTemplateSelected && (
                    <Badge
                      pill
                      bg={
                        selectedTemplateData?.approve?.id ===
                        STATUS_CONSTANTS.APPROVED
                          ? "success"
                          : "danger"
                      }
                      className="fw-normal ms-2"
                    >
                      {selectedTemplateData?.approve?.id ===
                      STATUS_CONSTANTS.APPROVED
                        ? "Approved"
                        : "Pending"}
                    </Badge>
                  )}
                </h6>
              </div>
              {(isTemplateSelected || view) && spamScoreDone && (
                <Button
                  variant={`soft-${colors}`}
                  onClick={showPreviewHandler}
                  className={`rounded-circle flex-shrink-0 avatar-40 lh-1 d-flex align-items-center justify-content-center p-1`}
                >
                  {findIcon("Eye", "solid", "24")}
                </Button>
              )}
            </div>

            {!view && !disableChannel && (
              <div className="d-flex mt-3 gap-2">
                <Button
                  className="w-100 px-3 flex-grow-1 text-nowrap"
                  variant={`${colors}`}
                  type="submit"
                  disabled={!isChannelEnable}
                  onClick={selectTemplate}
                >
                  {`${isTemplateSelected ? "Change" : "Select"} template`}
                </Button>
                <Button
                  className="w-100 px-2 flex-shrink-1 text-nowrap"
                  variant={`outline-${colors}`}
                  type="submit"
                  onClick={templateModalHandler}
                  disabled={!isChannelEnable}
                >
                  Create new
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
        <TemplateListModal
          showTemplates={showTemplates}
          modalClose={() => setShowTemplates(false)}
          type={type}
          options={options}
          selectedTemplateData={selectedTemplateData}
          getSelectedTemplate={getSelectedTemplate}
          // selectedTemplate={selectedTemplate}
        />
        <TemplatePreviewModal
          show={showPreview}
          modalClose={showPreviewHandler}
          type={type}
          selectedTemplateData={selectedTemplateData}
        />
        <CreateTemplateModal
          show={templateModalShow}
          modalClose={templateModalHandler}
          type={type}
        />
      </Fragment>
    );
  }
);

SelectTemplateCard.displayName = "SelectTemplateCard";
export default SelectTemplateCard;
