import { FILTER_VALUE_SEPARATOR } from "../../lib/table/constants";
import dayjs from "dayjs";
export const storages = {
  local: "localStorage",
  session: "sessionStorage",
};
export const nameValidator = (name = "") => {
  const nameRegex = /^[A-Za-z ]+$/;
  if (name != "") {
    return nameRegex.test(name?.trim());
  } else {
    return false;
  }
};
export const phoneNumberValidator = (number = "") => {
  const phoneNumberRegex = /^\+?[1-9][0-9]{7,14}$/;
  if (number != "") {
    return phoneNumberRegex.test(number?.trim());
  } else {
    return false;
  }
};
export const emailValidator = (email = "") => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email != "") {
    return emailRegex.test(email?.trim());
  } else {
    return false;
  }
};
export const multiSelectOptHandler = (selectedOptions = []) => {
  if (selectedOptions && selectedOptions.length > 0) {
    let selectedValueArr = selectedOptions.map((i) => i.value);
    return selectedValueArr;
  } else {
    return [];
  }
};

// create a function to map the status to bg props of Badge react-bootsrap
export const statusToBg = (status) => {
  switch (status) {
    case 1:
      return "primary";
    case 2:
      return "secondary";
    case 3:
      return "danger";
    case 4:
      return "warning";
    case 5:
      return "info";
    case 6:
      return "success";
    default:
      return "info";
  }
};

export const changeTableStateToPayload = (tableState) => {
  let { pageIndex, pageSize, sortBy, filters } = tableState;
  console.group("Table state");
  console.log(tableState);
  console.groupEnd("Table state");
  let payload = {};
  // payload.default = "all";
  payload.pagination = {
    limit: pageSize,
    offset: pageIndex * pageSize,
  };
  payload.sort =
    sortBy.length > 0
      ? {
          [sortBy[0].id]: sortBy[0].desc ? "desc" : "asc",
        }
      : {};

  if (filters.length > 0) {
    payload.filter = {};

    // if same type of filter is applied on same column then it will be merged into one
    filters.forEach((filter) => {
      console.log("each filter", filter);
      payload.filter[filter.id] = {
        type: !isNaN(parseInt(filter?.value))
          ? "eq"
          : filter?.value?.split(FILTER_VALUE_SEPARATOR).length > 1
          ? filter?.value?.split(FILTER_VALUE_SEPARATOR)[0]
          : "like",

        value: !isNaN(parseInt(filter?.value))
          ? parseInt(filter.value)
          : filter.value.split(FILTER_VALUE_SEPARATOR).length > 1
          ? filter.value.split(FILTER_VALUE_SEPARATOR)[1]
          : filter.value,
      };
    });
  }
  return payload;
};
export const changePayloadToTableState = (payload) => {
  if (!payload) return { pageIndex: 0, pageSize: 10, sortBy: [], filters: [] };

  let { pagination, sort, filter } = payload;
  let tableState = {};
  if (!pagination) {
    pagination = {
      limit: 10,
      offset: 0,
    };
  }
  tableState.pageIndex = pagination.offset / pagination.limit;
  tableState.pageSize = pagination.limit;
  tableState.sortBy = [];
  if (sort) {
    if (Object.keys(sort).length > 0) {
      tableState.sortBy.push({
        id: Object.keys(sort)[0],
        desc: sort[Object.keys(sort)[0]] === "desc" ? true : false,
      });
    }
  }
  tableState.filters = [];

  if (filter) {
    if (Object.keys(filter).length > 0) {
      Object.keys(filter).forEach((key) => {
        console.log("EACH KEY IN CHANGE PAYLOAD", key, filter[key]);
        // for (const _eachFilterValue of filter[key].value) {
        tableState.filters.push({
          id: key,
          value: filter[key].value,
        });
        // }
      });
    }
  }
  return tableState;
};

export function getValuesByKey(obj, key) {
  const values = [];

  function recursiveSearch(obj) {
    if (typeof obj === "object") {
      for (let prop in obj) {
        if (prop === key) {
          values.push(obj[prop]);
        } else {
          recursiveSearch(obj[prop]);
        }
      }
    }
  }

  recursiveSearch(obj);
  return values;
}

export function modifyObjectValue(objects, targetKey, targetValue, newValue) {
  const foundObject = objects.find((obj) => obj[targetKey] === targetValue);

  if (foundObject) {
    foundObject[targetKey] = newValue;
  }
}

export function modifyKeyNames(array, keyMappings) {
  return new Promise((resolve) => {
    const modifiedArray = [];

    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      const newObj = {};

      for (let key in keyMappings) {
        if (obj.hasOwnProperty(key)) {
          const newKey = keyMappings[key];
          newObj[newKey] = obj[key];
        }
      }

      modifiedArray.push(newObj);
    }

    resolve(modifiedArray);
  });
}

export const getSubtext = (text, length = 20) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};
export const parseQueryString = (queryString) => {
  let params = {};
  let searchParams = new URLSearchParams(queryString);
  for (let key of searchParams.keys()) {
    params[key] = searchParams.get(key);
  }

  return params;
};
export const getFormatedDate = (date) => {
  //format using dayjs
  return date ? dayjs(date).format("DD MMM YYYY") : "";
};
export function deleteKeyFromObject(obj, keyToDelete) {
  // Create a new object and copy all key-value pairs except the one to be deleted
  const newObj = { ...obj };
  delete newObj[keyToDelete];
  return newObj;
}
