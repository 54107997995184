//scss
import "shepherd.js/dist/css/shepherd.css";
import "flatpickr/dist/flatpickr.css";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/pro.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/customizer.scss";
import "./assets/custom/scss/custom.scss";
import "./assets/scss/custom/hcp-profile/hcpprofile.scss";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// Redux Selector / Action
import { useDispatch } from "react-redux";
// import state selectors
import { setSetting } from "./store/setting/actions";
import Backdrop from "./components/common-components/backdrop";
import useAbly from "./components/custom-hooks/use-ably";

function App({ children }) {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();
  dispatch(setSetting());
  useAbly();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">{children}</div>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <Backdrop />
    </QueryClientProvider>
  );
}

export default App;
