import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ADD_CITY_POST,
  ALL_CITY_POST,
  COUNTRY_LIST_FOR_CITY_GET,
  DELETE_CITY_POST,
  STATE_LIST_FOR_CITY_GET,
  UPDATE_CITY_POST,
  ZONE_LIST_FOR_CITY_GET,
} from "../../../../../api/endpoints/city-endpoints";
import toast from "react-hot-toast";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllCity = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewCity, setViewCity] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  console.log("zoneList", zoneList);
  const [stateList, setStateList] = useState([]);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      city_id: "desc",
    },
  });
  //yup and formik for validation of form fields of priority_name

  const validationSchema = yup.object().shape({
    city_name: yup.string().required("City Name is required"),
    // city_code: yup.string().required("City code is required"),
    city_country_id: yup.string().required("Country is required"),
    // city_id: yup.string().required("City id is required"),
    city_state_id: yup.string().required("State is required"),
    city_zone_id: yup.string().required("Zone is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      city_name: "",
      // city_code: "",
      city_country_id: "",
      // city_id: "",
      city_state_id: "",
      city_zone_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("values===>", values);

      const postValues = {
        ...values,
        city_name: values.city_name.trim(),
      };

      try {
        // setLoading(true);
        const response = await postService(
          isEdit ? UPDATE_CITY_POST + "/" + selectedCity : ADD_CITY_POST,
          {
            ...postValues,
            // city_active: 1,
            status: 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchCity(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(
            isEdit ? "City Updated Successfully" : "City Added Successfully"
          );
          // resetForm();
        }
      } catch (error) {
        setLoading(false);
        console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  console.log("formic test", values, errors);
  //fetch countries
  const fetchCity = async (data) => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_CITY_POST, data);
      const response_country_list = await getService(
        COUNTRY_LIST_FOR_CITY_GET,
        data
      );
      console.log(
        "COUNTRY_LIST_RESPONSE",
        response_country_list.data.data.country
      );
      console.log("CITY_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setCityList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
      if (response_country_list?.data?.data?.country?.length > 0) {
        setCountryList(response_country_list?.data?.data?.country);
      }
    } catch (error) {
      setLoading(false);
      console.log("CITY_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (city) => {
    console.log("city===>", city);
    let dataJson = {
      Country: city.city_country_id.country_name,
      State: city.city_state_id.state_name,
      Zone: city.city_zone_id.zone_name,
      // "City ID": city.city_id,
      "City Name": city.city_name,
      // "City Code": city.city_code,
    };
    setViewCity(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteCity = async () => {
    try {
      // setLoading(true);
      const response = await getService(`${DELETE_CITY_POST}/${selectedCity}`);
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchCity(post_json.current);
        toast.success("City Deleted Successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("CITY_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchCity(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchCity(payload);
    },
    [cityList, loading, post_json.current]
  );
  const onDeleteCity = (city) => {
    setSelectedCity(city.city_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "City ID",
      accessor: "city_id",
    },
    {
      Header: "City Name",
      accessor: "city_name",
    },
    {
      Header: "Country",
      accessor: "city_country_id",
      Cell: ({ row }) => row.original.city_country_id.country_name,
    },
    {
      Header: "State",
      accessor: "city_state_id",
      Cell: ({ row }) => row.original.city_state_id.state_name,
    },
    {
      Header: "Zone",
      accessor: "city_zone_id",
      Cell: ({ row }) => row.original.city_zone_id.zone_name,
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="info"
            to="#"
            role="button"
            onClick={() => viewModal(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Eye", "dual-tone", 32)}
            </span>
          </Button>

          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            onClick={() => onEditCityClick(row.original)}
          >
            <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="danger"
            to="#"
            role="button"
            onClick={() => onDeleteCity(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Trash", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setStateList([]);
    setZoneList([]);
    setCreateModalShow(true);
  };
  const onEditCityClick = (city) => {
    console.log("city===>", city);
    getZoneList(city.city_country_id.country_id);
    getStateList(city.city_zone_id.zone_id);
    setIsEdit(true);
    setSelectedCity(city.city_id);

    const editValues = {
      city_name: city?.city_name,
      // city_code: city.city_code,
      city_country_id: city?.city_country_id?.country_id,
      // city_id: city.city_id,
      city_state_id: city?.city_state_id?.state_id,
      city_zone_id: city?.city_zone_id?.zone_id,
    };

    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  const getZoneList = async (countryId) => {
    console.log("getZoneList", countryId);
    try {
      const response_zone_list = await getService(
        ZONE_LIST_FOR_CITY_GET + "/" + countryId
      );
      console.log("ZONE_LIST", response_zone_list?.data?.data?.zone);
      if (response_zone_list?.data?.data?.zone?.length > 0) {
        setZoneList(response_zone_list?.data?.data?.zone);
      }
    } catch (error) {
      console.log("CITY_ZONE_ERROR", error);
    }
  };
  const getStateList = async (zoneId) => {
    try {
      const response_state_list = await getService(
        STATE_LIST_FOR_CITY_GET + "/" + zoneId
      );
      console.log("STATE_LIST", response_state_list?.data?.data?.state);
      if (response_state_list?.data?.data?.state?.length > 0) {
        setStateList(response_state_list?.data?.data?.state);
      }
    } catch (error) {
      console.log("CITY_STATE_ERROR", error);
    }
  };
  console.log("formic", values);
  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All City Names</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add City Name
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                cityList?.length > 0 ? (
                  <ReactTable
                    data={cityList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteCity}
      />
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewCity}
      />
      {/* create a add Priority Name modal with Priority Name name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} City Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* COUNTRY */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Country <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter City Country Id"
                name="city_country_id"
                onChange={(e) => {
                  handleChange(e);
                  getZoneList(e.target.value);
                }}
                value={values.city_country_id}
                onBlur={handleBlur}
                isInvalid={touched.city_country_id && errors.city_country_id}
              >
                <option value="">Select Association Country</option>
                {countryList.map((_c) => (
                  <option value={_c?.country_id}>{_c?.country_name}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.city_country_id}
              </Form.Control.Feedback>
            </Form.Group>
            {/* ZONE */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Zone <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter City State Id"
                name="city_zone_id"
                onChange={(e) => {
                  handleChange(e);
                  getStateList(e.target.value);
                }}
                disabled={!values.city_country_id}
                value={values.city_zone_id}
                onBlur={handleBlur}
                isInvalid={touched.city_zone_id && errors.city_zone_id}
              >
                <option value="">Select Zone</option>
                {zoneList.length > 0 &&
                  zoneList?.map((_z) => (
                    <option value={_z?.zone_id}>{_z?.zone_name}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.city_zone_id}
              </Form.Control.Feedback>
            </Form.Group>
            {/* STATE */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                State <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter City State Id"
                name="city_state_id"
                onChange={handleChange}
                disabled={!values.city_zone_id}
                value={values.city_state_id}
                onBlur={handleBlur}
                isInvalid={touched.city_state_id && errors.city_state_id}
              >
                <option value="">Select State</option>
                {stateList.length > 0 &&
                  stateList?.map((_s) => (
                    <option
                      value={_s?.state_id}
                      // selected={_s?.state_name === values.city_state_id}
                    >
                      {_s?.state_name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.city_state_id}
              </Form.Control.Feedback>
            </Form.Group>
            {/* CITY ID */}
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>City Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City Id"
                name="city_id"
                onChange={handleChange}
                value={values.city_id}
                onBlur={handleBlur}
                isInvalid={touched.city_id && errors.city_id}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city_id}
              </Form.Control.Feedback>
            </Form.Group> */}
            {/* CITY NAME */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                City Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City Name"
                name="city_name"
                onChange={handleChange}
                value={values.city_name}
                onBlur={handleBlur}
                isInvalid={touched.city_name && errors.city_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city_name}
              </Form.Control.Feedback>
            </Form.Group>
            {/* CITY CODE */}
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>City Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City Code"
                name="city_code"
                onChange={handleChange}
                value={values.city_code}
                onBlur={handleBlur}
                isInvalid={touched.city_code && errors.city_code}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city_code}
              </Form.Control.Feedback>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllCity;
