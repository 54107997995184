import React, { useEffect, useState, memo } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { findIcon } from "../../../dashboard/icons";
import TemplateFilter from "../../../../components/common-components/template-filter";

const Filter = ({ type = "content-studio" }) => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const getFilterActivateStatus = () => setIsFilterActive(!isFilterActive);
  const [isResetEnable, setIsResetEnable] = useState(false);
  const [filterData, setFilterData] = useState({});
  const getFilterData = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };
  // const setSortData = (data) => {
  //   setFilterData({ ...filterData, ShortData: data });
  // };

  const dropdownList = [
    { id: 1, value: "Title", check: false },
    { id: 2, value: "Approved By", check: false },
    { id: 3, value: "Content Name", check: false },
    { id: 4, value: "Brand", check: false },
    { id: 5, value: "Status", check: false },
    { id: 6, value: "Approved By", check: false },
    { id: 7, value: "Creation Date", check: false },
    { id: 8, value: "Action", check: false },
  ];
  const resetStatusHandler = () => {
    if (Object.keys(filterData).length > 0) {
      // if(Object.values(filterData))
      setIsResetEnable(true);
    } else {
      setIsResetEnable(false);
    }
  };
  const resetDataHandler = (data = false) => {
    if (data) {
      setFilterData({});
    }
  };
  useEffect(() => {
    resetStatusHandler();
  }, [filterData]);
  console.log("filterData", filterData, isResetEnable);
  return (
    <>
      <Col className="d-flex justify-content-between flex-column flex-lg-row align-items-start align-items-md-center">
        <div className="form-group input-group search-input mb-3 mb-lg-0 w-100 w-md-auto">
          <input
            name="search"
            type="search"
            className="form-control"
            // placeholder={`Search ${type==='content-studio'?'Template':type}...`}
            placeholder={`Search...`}
            value={filterData?.search ?? ""}
            onChange={(e) => getFilterData(e)}
          />
          <span className="input-group-text">
            {findIcon("Search", "outline", "20")}
          </span>
        </div>
        <div className="ms-lg-auto">
          <TemplateFilter
            getFilterActivateStatus={getFilterActivateStatus}
            dropdownList={dropdownList}
            getFilterData={getFilterData}
            filterData={filterData}
            isResetEnable={isResetEnable}
            resetDataHandler={resetDataHandler}
          />
        </div>
      </Col>
      {isFilterActive && (
        <div className="col-12 border border-0 border-md-1 p-0 p-md-4 rounded-3 mt-4">
          <div className="d-flex flex-column flex-md-row mb-3 gap-3">
            <Form.Floating className="custom-form-floating flex-grow-1">
              <Form.Control
                name="CreationDateRange"
                value={filterData?.CreationDateRange ?? ""}
                type="date"
                className=""
                autoComplete="Creation Date Range"
                placeholder="Creation Date Range"
                onChange={(e) => getFilterData(e)}
              />
              <label htmlFor="floatingInput">Creation Date Range</label>
            </Form.Floating>
            <Form.Floating className="custom-form-floating flex-grow-1">
              <Form.Control
                name="ApprovalDateRang"
                value={filterData?.ApprovalDateRang ?? ""}
                type="date"
                className=""
                autoComplete="Approval Date Range"
                placeholder="Approval Date Range"
                onChange={(e) => getFilterData(e)}
              />
              <label htmlFor="floatingInput">Approval Date Range</label>
            </Form.Floating>
            <Form.Floating className="custom-form-floating flex-grow-1">
              <Form.Select
                name="CreatedBy"
                className="form-select"
                onChange={(e) => getFilterData(e)}
                value={filterData?.CreatedBy ?? ""}
              >
                <option defaultValue="">-- Select Creator--</option>
                <option defaultValue="1">Sumit Mandol</option>
                <option defaultValue="2">Indranil Kundu</option>
                <option defaultValue="3">Sanjoy Dey</option>
              </Form.Select>
              <label htmlFor="floatingInput">Created By</label>
            </Form.Floating>
            <Form.Floating className="custom-form-floating flex-grow-1">
              <Form.Select
                name="ApprovedBy"
                className="form-select"
                onChange={(e) => getFilterData(e)}
                value={filterData?.ApprovedBy ?? ""}
              >
                <option defaultValue="">-- Select Approver--</option>
                <option defaultValue="1">Sumit Mandol</option>
                <option defaultValue="2">Indranil Kundu</option>
                <option defaultValue="3">Sanjoy Dey</option>
              </Form.Select>
              <label htmlFor="floatingInput">Approved By</label>
            </Form.Floating>
          </div>
          <div className="d-flex flex-column flex-md-row gap-3">
            <Form.Floating className="custom-form-floating flex-grow-1">
              <Form.Control
                name="Brand"
                value={filterData?.Brand ?? ""}
                type="name"
                className=""
                autoComplete="Brand"
                placeholder="Please enter Brand"
                onChange={(e) => getFilterData(e)}
              />
              <label htmlFor="floatingInput">Brand</label>
            </Form.Floating>
            <Form.Floating className="custom-form-floating flex-grow-1">
              <Form.Select
                name="TemplateType"
                value={filterData?.TemplateType ?? ""}
                className="form-select"
                onChange={(e) => getFilterData(e)}
              >
                <option defaultValue="">-- Select Template --</option>
                <option defaultValue="1">PDF</option>
                <option defaultValue="2">PPT</option>
                <option defaultValue="3">Document</option>
                <option defaultValue="3">Infographic</option>
                <option defaultValue="3">Article</option>
                <option defaultValue="3">HTML</option>
              </Form.Select>
              <label htmlFor="floatingInput">Template Type</label>
            </Form.Floating>
            <Form.Floating className="custom-form-floating flex-grow-1">
              <Form.Select
                name="Status"
                value={filterData?.Status ?? ""}
                className="form-select"
                onChange={(e) => getFilterData(e)}
              >
                <option defaultValue="">-- Select Status --</option>
                <option defaultValue="1">Approved</option>
                <option defaultValue="2">Rejected</option>
                <option defaultValue="3">Edit Requested</option>
                <option defaultValue="3">Draft</option>
              </Form.Select>
              <label htmlFor="floatingInput">Status</label>
            </Form.Floating>
          </div>
          <div className="d-flex mt-3">
            <Button variant="primary" className="ms-auto">
              Search
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Filter);
