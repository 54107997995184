import React, { memo, useState } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

const SubmitButton = ({
  onClick,
  children,
  className = "",
  type = "button",
  disabled = false,
  variant = "primary",
  ...rest
}) => {
  const handleClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        onClick && onClick(); // Trigger the original action
      }
    });
  };

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      className={className}
      variant={variant}
      type={type}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default memo(SubmitButton);
