import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ADD_COUNTRY_POST,
  ALL_COUNTRY_POST,
  COUNTRY_LIST_GET,
  DELETE_COUNTRY_POST,
  UPDATE_COUNTRY_POST,
} from "../../../../../api/endpoints/country-endpoints";
import toast from "react-hot-toast";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllCountry = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewCountry, setViewCountry] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      country_id: "desc",
    },
  });
  //yup and formik for validation of form fields of priority_name

  const validationSchema = yup.object().shape({
    country_name: yup.string().required("Country Name is required"),
    country_code: yup.string().required("Country Code is required"),
    country_isd_code: yup.string().required("Country ISD Code is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      country_name: "",
      country_code: "",
      country_isd_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("values===>", values);

      const postValues = {
        country_name: values.country_name.trim(),
        country_code: values.country_code.trim(),
        country_isd_code: values.country_isd_code.trim(),
      };

      try {
        // setLoading(true);
        const response = await postService(
          isEdit
            ? UPDATE_COUNTRY_POST + "/" + selectedCountry
            : ADD_COUNTRY_POST,
          {
            ...postValues,
            // country_active: 1,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchCountry(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(response?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  console.log("formic test", values, errors);
  //fetch countries
  const fetchCountry = async (data) => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_COUNTRY_POST, data);
      console.log("COUNTRY_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setCountryList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("COUNTRY_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (country) => {
    console.log("country===>", country);
    let dataJson = {
      "Country ID": country.country_id,
      "Country Name": country.country_name,
      "Country Code": country.country_code,
      "Country ISD Code": country.country_isd_code,
      "Created by": country.created_by.name,
      Status: country.status.value,
    };
    setViewCountry(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteCountry = async () => {
    try {
      // setLoading(true);
      const response = await getService(
        `${DELETE_COUNTRY_POST}/${selectedCountry}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchCountry(post_json.current);
        toast.success("Country deleted successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("COUNTRY_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchCountry(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchCountry(payload);
    },
    [countryList, loading, post_json.current]
  );
  const onDeleteCountry = (country) => {
    setSelectedCountry(country.country_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Country ID",
      accessor: "country_id",
    },
    {
      Header: "Country Name",
      accessor: "country_name",
    },
    {
      Header: "Country Code",
      accessor: "country_code",
    },
    {
      Header: "Country ISD Code",
      accessor: "country_isd_code",
    },
    {
      Header: "Created by",
      accessor: "created_by",
      Cell: ({ value }) => value.name,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="info"
            to="#"
            role="button"
            onClick={() => viewModal(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Eye", "dual-tone", 32)}
            </span>
          </Button>

          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            onClick={() => onEditCountryClick(row.original)}
          >
            <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="danger"
            to="#"
            role="button"
            onClick={() => onDeleteCountry(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Trash", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditCountryClick = (country) => {
    console.log("country===>", country);
    setIsEdit(true);
    setSelectedCountry(country.country_id);

    const editValues = {
      country_name: country?.country_name,
      country_code: country?.country_code,
      country_isd_code: country?.country_isd_code,
      status: country?.status?.id,
    };
    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Country Names</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Country Name
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                countryList.length > 0 ? (
                  <ReactTable
                    data={countryList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteCountry}
      />
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewCountry}
      />
      {/* create a add Priority Name modal with Priority Name name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Country Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* COUNTRY NAME */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Country Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country Name"
                name="country_name"
                onChange={handleChange}
                value={values.country_name}
                onBlur={handleBlur}
                isInvalid={touched.country_name && errors.country_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.country_name}
              </Form.Control.Feedback>
            </Form.Group>
            {/* COUNTRY CODE */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Country Code <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country Code"
                name="country_code"
                onChange={handleChange}
                value={values.country_code}
                onBlur={handleBlur}
                isInvalid={touched.country_code && errors.country_code}
              />
              <Form.Control.Feedback type="invalid">
                {errors.country_code}
              </Form.Control.Feedback>
            </Form.Group>
            {/* COUNTRY ISD CODE */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Country ISD Code <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country ISD Code"
                name="country_isd_code"
                onChange={handleChange}
                value={values.country_isd_code}
                onBlur={handleBlur}
                isInvalid={touched.country_isd_code && errors.country_isd_code}
              />
              <Form.Control.Feedback type="invalid">
                {errors.country_isd_code}
              </Form.Control.Feedback>
            </Form.Group>
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="status"
                  value={values?.status}
                  isInvalid={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Status</option>
                  <option value="1">Enabled</option>
                  <option value="2">Disabled</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllCountry;
