import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Card from "../../../../components/bootstrap/card";
import { Row, Col, Button, Dropdown, FormCheck } from "react-bootstrap";
import TreeView, { flattenTree } from "react-accessible-treeview";
import cx from "classnames";
import { findIcon } from "../../../dashboard/icons";
import ReactTable from "../../../../lib/table/react-table";
import {
  CONTENT_DETAILS_GET,
  CONTENT_LIST_POST,
  FILE_TYPES_GET,
  PRINICIPLE_ENTITIES_GET,
} from "../../../../api/endpoints/content-endpoints";
import postService from "../../../../api/services/post-service";
import { getService } from "../../../../api/services/get-services";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import CustomToggle from "../../../../components/dropdowns";
import {
  changeTableStateToPayload,
  getSubtext,
} from "../../../../common/utils/utils";
import ContentCard from "../components/content-card";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import MycontentLoader from "../../../../skeleton/creative-library/mycontent-loader";
import ContentCardLoader from "../../../../skeleton/creative-library/content-card-loader";
import TableLoader from "../../../../skeleton/creative-library/table-loader";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import PageNotFound from "../../../PageNotFound";
import PaginationBar from "../../../../lib/table/pagination-bar";

const pageSize = 10;
const MyContent = () => {
  const params = useParams();
  const [pageTitle, setPageTitle] = React.useState("My Content"); //state for page title
  const [selectedEntityId, setSelectedEntityId] = React.useState(null); //state for selected entity id
  const [entities, setEntities] = React.useState([]); //state for principle entities
  const [show, setShow] = React.useState(false);

  const [folder, setFolder] = React.useState(null); //state for principle entities
  const [content, setContent] = React.useState(null); //state for content
  const [totalCount, setTotalCount] = React.useState(null); //state for total count of content
  const [fileType, setFileType] = React.useState(null); //state for file type
  const [selectedFileType, setSelectedFileType] = React.useState(null); //state for selected file types
  const [isListView, setIsListView] = React.useState(false); //state for list view
  const [tableState, setTableState] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
  });
  const initialFileState = {
    title: "",
    file_url: "",
    description: "",
    createdBy: "",
    brand: "",
    icon: "",
    type: "",
  };
  const [showFilePreview, setShowFilePreview] = React.useState("");
  const [fileDetails, setFileDetails] = React.useState(initialFileState);
  const [loading, setLoading] = useState(true);
  const viewModalClose = () => setShow(false);
  const totalPage = useMemo(
    () => Math.ceil(totalCount / pageSize),
    [totalCount, pageSize]
  );

  function formatJSON(data, parentId = 0) {
    const result = [];
    const children = data.filter(
      (entity) => entity.principal_entity_parent_id === parentId
    );

    children.forEach((child) => {
      const node = {
        name: child.principal_entity_name,
        children: formatJSON(data, child.principal_entity_id),
      };
      result.push(node);
    });

    return result;
  }

  //function to traverse the tree structured data and find the children which are blank array and assign a new property to it
  function traverseTree(data) {
    data.forEach((item) => {
      if (item.children.length === 0) {
        item.isLeaf = true;
      } else {
        traverseTree(item.children);
      }
    });

    console.log("tree_data", data);
  }

  const fetchPrincipleEntites = async (params = {}, callback) => {
    const [response, file_types] = await Promise.all([
      getService(PRINICIPLE_ENTITIES_GET),
      getService(FILE_TYPES_GET),
    ]);

    let principal_entity_data = response?.data?.data;
    let file_type_data = file_types?.data?.data;
    if (principal_entity_data?.length > 0 && file_type_data?.length > 0) {
      setEntities(principal_entity_data);

      setFileType(file_type_data);
      const formattedJSON = formatJSON(
        principal_entity_data.map((item) => {
          return {
            principal_entity_id: item.principal_entity_id,
            principal_entity_name: item.principal_entity_name,
            principal_entity_parent_id: item.principal_entity_parent_id,
          };
        })
      );

      console.log("formatted", formattedJSON);
      traverseTree(formattedJSON);
      const tree = flattenTree({ name: "", children: formattedJSON });
      console.log("FLATTEN_TREE", tree);
      setFolder(tree);
      callback(principal_entity_data, file_type_data, params);
    }
  };
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      content_id: "desc",
    },
  });
  const fetchContents = async () => {
    const response = await postService(CONTENT_LIST_POST, post_json.current);
    console.log("contents_response", response?.data?.data?.rows);
    console.log("total_count", response?.data?.data?.totalCount);
    console.log("COLUMN", Object.keys(response?.data?.data?.rows[0]));
    setContent(response?.data?.data?.rows);
    setTotalCount(response?.data?.data?.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    // setLoading(true);
    fetchPrincipleEntites(params, (entities, file_type, params) => {
      console.log("entities", entities);
      console.log("file_type", file_type);
      console.log("params", params);

      if (params?.entity) {
        let { principal_entity_id, principal_entity_name } = entities?.find(
          (item) => item.principal_entity_id === params.entity
        );
        setSelectedEntityId(principal_entity_id);
        setPageTitle(principal_entity_name);
        post_json.current = {
          ...post_json.current,
          filter: {
            ...post_json.current.filter,
            content_brand_id: {
              type: "eq",
              value: principal_entity_id,
            },
          },
        };
      }
      if (params?.file_type) {
        let { file_type_id, file_type_name } = file_type?.find(
          (item) => item.file_type_code == params.file_type
        );
        setSelectedFileType(file_type_id);
        setPageTitle(file_type_name);
        post_json.current = {
          ...post_json.current,
          filter: {
            ...post_json.current.filter,
            content_file_type_id: {
              type: "eq",
              value: file_type_id,
            },
          },
        };
      }
      fetchContents();
      // fetchFileTypes();
      console.log("params", params);

      return () => {
        setEntities([]);
        setFileType([]);
        setFolder(null);
        setContent(null);
        setTotalCount(null);
        setSelectedEntityId(null);
        setSelectedFileType(null);
        setIsListView(false);
        setTableState({
          pageIndex: 0,
          pageSize: 10,
          sortBy: [],
          filters: [],
        });
        setShowFilePreview("");
        setFileDetails(initialFileState);

        post_json.current = {
          default: "all",
          pagination: {
            limit: 10,
            offset: 0,
          },
          sort: {
            content_id: "desc",
          },
        };
      };
    });
  }, [params]);

  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return (
      <>
        <span className="folder-icon position-absolute">
          {findIcon("Folder", "dual-tone", "18")}
        </span>
        <span className="down-arrow-icon position-absolute transition">
          {findIcon("Chevron Down", "outline", "18")}
        </span>
      </>
    );
  };
  const svg1 = findIcon("Eye", "outline");

  const columns = useMemo(
    () => [
      {
        Header: "Content Title",
        accessor: "content_title",
        Cell: ({ value }) => (value ? getSubtext(value, 20) : "No Title"),
      },
      {
        Header: "Content Description",
        accessor: "content_description",
        Cell: ({ value }) => (value ? getSubtext(value, 20) : "No Description"),
      },
      {
        Header: "Content Publication Date",
        accessor: "content_publication_date",
        Cell: ({ value }) =>
          value ? dayjs(value).format("DD/MM/YYYY") : "No Date",
      },
      {
        Header: "Content Brand",
        accessor: "content_brand_id",
        Cell: ({ value }) => value?.principal_entity_name,
      },
      {
        Header: "Content File Type",
        accessor: "content_file_type_id",
        Cell: ({ value }) => value?.file_type_code,
      },
      // {
      //   Header: "Content Privacy Status",
      //   accessor: "content_privacy_status",
      //   Cell: ({ value }) => value?.value ?? "None",
      // },
      // {
      //   Header: "Content Deeplink",
      //   accessor: "content_deeplink",
      // },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => value?.value ?? "None",
      },
      {
        Header: "Action",
        accessor: "action",

        Cell: ({ row }) => (
          <ActionButtonBar
            row={row}
            permissions={["view"]}
            onView={(data) => {
              onClickPreviewContent(data.content_id, false);
            }}
          />
        ),
      },
    ],
    []
  );

  const onEachNodeClick = (element) => {
    console.log("element", element);
    if (element.children.length === 0) {
      //fetch content
      console.log("fetch content");
      let id = entities?.find(
        (item) => item.principal_entity_name === element.name
      ).principal_entity_id;
      console.log("id", id);
      post_json.current = {
        ...post_json.current,
        filter: {
          ...post_json.current.filter,
          content_brand_id: {
            type: "eq",
            value: id,
          },
        },
      };
      // alert(id);
      setSelectedEntityId(id);
      setPageTitle(element.name);
      fetchContents();
    }
  };
  const handleFileTypeChange = (id) => {
    setSelectedFileType(id);
    post_json.current = {
      ...post_json.current,
      filter: {
        ...post_json.current.filter,
        content_file_type_id: {
          type: "eq",
          value: id,
        },
      },
    };
    fetchContents();
  };
  const onTableStateChange = useCallback(
    (state) => {
      console.log("TABLE_STATE", state);
      setTableState(state);
      let payload = changeTableStateToPayload(state);
      console.log("payload", payload);
      // alert(selectedEntityId);
      //if entity and file type is selected then add it to post_json.current otherwise keep the filter of payload
      post_json.current = {
        ...payload,
        filter: {
          ...payload.filter,
          ...(selectedEntityId && {
            content_brand_id: {
              type: "eq",
              value: selectedEntityId,
            },
          }),
          ...(selectedFileType && {
            content_file_type_id: {
              type: "eq",
              value: selectedFileType,
            },
          }),
        },
      };
      console.log("post_json", post_json.current);
      fetchContents();
    },
    [selectedEntityId, selectedFileType, post_json.current, fetchContents]
  );
  const onPageChange = (pageIndex) => {
    console.log("pageIndex", pageIndex);
    setTableState((prevState) => ({
      ...prevState,
      pageIndex: pageIndex,
    }));
    post_json.current = {
      ...post_json.current,
      pagination: {
        ...post_json.current.pagination,
        offset: pageIndex * pageSize,
      },
      filter: {
        ...post_json.current.filter,
        ...(selectedEntityId && {
          content_brand_id: {
            type: "eq",
            value: selectedEntityId,
          },
        }),
        ...(selectedFileType && {
          content_file_type_id: {
            type: "eq",
            value: selectedFileType,
          },
        }),
      },
    };
    fetchContents();
  };

  const onClickPreviewContent = useCallback(
    async (id, isContentCard = true) => {
      let response = await getService(CONTENT_DETAILS_GET + id);
      if (response.isError) {
        console.log(response);
      } else {
        console.log("CONTENT_RESPONSE", response);
        let fileResponse = response.data.data.detail;
        let {
          content_to_file = {},
          content_to_av = {},
          content_to_text = {},
          content_brand_id = {},
          content_file_type_id = {},
          created_by = {},
          content_to_image = {},
        } = fileResponse;
        let content = content_to_file || content_to_av || content_to_text;
        let {
          title_html,
          description_html,
          storage_link = "",
          path = "",
        } = content;
        let file_url = storage_link || path || "";

        if (fileResponse) {
          setFileDetails((prev) => {
            return {
              ...prev,
              title: title_html,
              description: description_html,
              file_url: file_url,
              citation: content_to_text?.citation_html ?? "",
              createdBy: created_by?.name,
              coverImage: content_to_image?.content_image_id?.thumbnail,
              brand: content_brand_id?.principal_entity_name,
              icon: content_file_type_id?.file_type_icon,
              type: content_file_type_id?.file_type_code,
            };
          });
          isContentCard ? setShowFilePreview(id) : setShow(true);
        } else {
          toast.error("File not found");
        }
      }
    },
    [showFilePreview, fileDetails, show]
  );
  console.log("fileDetails", fileDetails, showFilePreview);
  const onViewModalClose = useCallback(() => {
    setShowFilePreview("");
    setFileDetails(initialFileState);
  }, [showFilePreview, fileDetails]);

  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body className="p-3">
              <Row className="h-100">
                {Object.keys(params)?.length == 0 && (
                  <Col md={4}>
                    <Card.Body className="d-flex px-3 flex-column h-100 border border-2 border-dashed border-soft-primary rounded-3">
                      <h4 className="mb-3">Find in folder</h4>
                      {!loading ? (
                        folder?.length > 0 && (
                          <>
                            <TreeView
                              className="list-unstyled flex-grow-1 custom-TreeView"
                              data={folder}
                              aria-label="Checkbox tree"
                              onExpand={(node) => {
                                console.log("on expand", node);
                              }}
                              nodeRenderer={({
                                element,
                                isBranch,
                                isExpanded,
                                getNodeProps,
                                level,
                                handleExpand,
                                className,
                              }) => {
                                return (
                                  <div
                                    {...getNodeProps({ onClick: handleExpand })}
                                    style={{ marginLeft: 5 * (level - 1) }}
                                  >
                                    <div
                                      onClick={() => onEachNodeClick(element)}
                                    >
                                      {isBranch ? (
                                        <ArrowIcon isOpen={isExpanded} />
                                      ) : (
                                        <span className="folder-icon position-absolute">
                                          {findIcon(
                                            "Chevron Arrow Right",
                                            "dual-tone",
                                            "16"
                                          )}
                                        </span>
                                      )}
                                      <span
                                        className={
                                          pageTitle == element.name
                                            ? "text-primary"
                                            : ""
                                        }
                                      >
                                        {element.name}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </>
                        )
                      ) : (
                        <MycontentLoader />
                      )}
                    </Card.Body>
                  </Col>
                )}
                <Col lg={Object.keys(params)?.length > 0 ? 12 : 8}>
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                    <h5 className="m-0">
                      {pageTitle ? pageTitle : "All Contents"}
                    </h5>
                    <div className="d-flex align-items-center gap-3">
                      {/* <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          variant="btn btn-sm btn-primary rounded-2 btn-icon"
                        >
                          <i className="btn-inner">
                            {findIcon("Adjustment", "dual-tone", "18")}
                          </i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="end p-0 sub-drop">
                          <Card className="m-0 shadow-none">
                            <Card.Body className="px-2 py-2 max-17 scroll-thin  rounded-4">
                              {fileType &&
                                fileType.map((item, index) => {
                                  return (
                                    <div key={index + 1}>
                                      <FormCheck.Input
                                        className="me-2"
                                        type="radio"
                                        name="fileType"
                                        value={item.file_type_id}
                                        checked={
                                          selectedFileType == item.file_type_id
                                        }
                                        onChange={(e) =>
                                          handleFileTypeChange(e.target.value)
                                        }
                                      />
                                      <FormCheck.Label className="">
                                        {item.file_type_name}
                                      </FormCheck.Label>
                                    </div>
                                  );
                                })}
                            </Card.Body>
                          </Card>
                        </Dropdown.Menu>
                      </Dropdown> */}

                      <ul
                        className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center flex-nowrap justify-content-end gap-2"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li
                          className="nav-item ms-2"
                          role="presentation"
                          // onClick={() => setIsListView(false)}
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              as={CustomToggle}
                              variant="btn btn-sm btn-primary rounded-2 btn-icon"
                            >
                              <i className="btn-inner">
                                {findIcon("Adjustment", "dual-tone", "18")}
                              </i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="end p-0 sub-drop">
                              <Card className="m-0 shadow-none">
                                <Card.Body className="px-2 py-2 max-17 scroll-thin  rounded-4">
                                  {fileType &&
                                    fileType.map((item, index) => {
                                      return (
                                        <div key={index + 1}>
                                          <FormCheck.Input
                                            className="me-2"
                                            type="radio"
                                            name="fileType"
                                            value={item.file_type_id}
                                            checked={
                                              selectedFileType ==
                                              item.file_type_id
                                            }
                                            onChange={(e) =>
                                              handleFileTypeChange(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <FormCheck.Label className="">
                                            {item.file_type_name}
                                          </FormCheck.Label>
                                        </div>
                                      );
                                    })}
                                </Card.Body>
                              </Card>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <li
                          className="nav-item ms-2"
                          role="presentation"
                          onClick={() => setIsListView(false)}
                        >
                          <Button
                            bsPrefix={
                              "nav-link btn-sm btn-icon rounded-pill" +
                              (isListView ? "" : " active")
                            }
                            id="grid-view-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-grid-view"
                            to="#"
                            role="tab"
                            aria-controls="pills-grid-view"
                            aria-selected="true"
                          >
                            <span className="btn-inner d-inline-flex">
                              {findIcon("View Grid", "dual-tone")}
                            </span>
                          </Button>
                        </li>
                        <li
                          className="nav-item ms-2"
                          role="presentation"
                          onClick={() => setIsListView(true)}
                        >
                          <Button
                            bsPrefix={
                              "nav-link btn-sm btn-icon rounded-pill" +
                              (isListView ? " active" : "")
                            }
                            id="list-view-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-list-view"
                            to="#"
                            role="tab"
                            aria-controls="pills-list-view"
                            aria-selected="false"
                          >
                            <span className="btn-inner d-inline-flex">
                              {findIcon("List", "dual-tone")}
                            </span>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {content && isListView && (
                    <ReactTable
                      data={content}
                      recordsTotal={totalCount}
                      columns={columns}
                      // isRowSelectionAvailable
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      isPagination={true}
                      recordsPerPage={10}
                    />
                  )}
                  {!isListView && (
                    <>
                      <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
                        {!loading ? (
                          content?.length > 0 ? (
                            content?.map((item, index) => (
                              <Col key={`content-${index}`}>
                                <ContentCard
                                  key={`content-${index}`}
                                  id={item.content_id}
                                  src={item?.image?.thumbnail}
                                  date={item.content_publication_date}
                                  title={item.content_title}
                                  citation={fileDetails?.citation}
                                  titleHtml={fileDetails?.title}
                                  coverImage={fileDetails?.coverImage}
                                  descriptionHtml={fileDetails?.description}
                                  description={fileDetails?.description}
                                  brand={
                                    item.content_brand_id?.principal_entity_name
                                  }
                                  icon={
                                    item?.content_file_type_id
                                      ? item?.content_file_type_id
                                          ?.file_type_icon
                                      : ""
                                  }
                                  onClickPreview={onClickPreviewContent}
                                  file={fileDetails?.file_url}
                                  filePreviewModal={
                                    showFilePreview == item.content_id
                                  }
                                  type={
                                    item?.content_file_type_id
                                      ? item?.content_file_type_id
                                          ?.file_type_code
                                      : ""
                                  }
                                  onViewModalClose={onViewModalClose}
                                  createdBy={item.created_by?.name}
                                />
                              </Col>
                            ))
                          ) : (
                            <PageNotFound />
                          )
                        ) : (
                          <ContentCardLoader />
                        )}
                      </Row>
                      <PaginationBar
                        gotoPage={onPageChange}
                        onNextPage={() => {}}
                        onPreviousPage={() => {}}
                        totalPage={totalPage}
                        pageIndex={tableState.pageIndex}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {fileDetails?.file_url && show && (
        <FilePreviewModal
          mediaType={fileDetails?.type}
          show={show}
          viewModalClose={() => setShow(false)}
          title={fileDetails?.title}
          brand={fileDetails?.brand}
          createdBy={fileDetails?.createdBy}
          description={fileDetails?.description}
          icon={fileDetails?.icon}
          file_url={fileDetails?.file_url}
        />
      )}
    </>
  );
};

export default React.memo(MyContent);
