import app_config from "../../common/data/app_config";
import { getStorage } from "../../utilities/setting";
import axios from "axios";

const uploadService = async (endpoint, data) => {
	let accessToken = "";
	if (!accessToken) {
		accessToken = getStorage("access_token")
			? `Bearer ${getStorage("access_token")}`
			: "";
	}
	try {
		let response = await axios({
			method: "POST",
			url: app_config.api_url + "/" + endpoint,
			data,
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: accessToken,
			},
		});
		if (response) {
			return response;
		}
	} catch (error) {
		return null;
	}
};

export default uploadService;
