import { useCallback, useEffect, useRef, useState } from "react";
import Card from "../../../../components/bootstrap/card";

// Componet
// import Video from "../../content-library/components/video.js";

//Img
import { Button, Col, Row } from "react-bootstrap";
import { findIcon } from "../../../dashboard/icons";
// import ContentCard from "../components/resource-content";
// import ReactPlayer from "react-player";
import {
  GET_RESOURCES_LIST,
  RESOURCES_DETAILS,
} from "../../../../api/endpoints/help-and-support-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import ContentCard from "../../content-library/components/content-card";
import ContentUploadModal from "../components/content-upload-modal";
import app_config from "../../../../common/data/app_config";
import ResourcesLoader from "../../../../skeleton/helpsupport/resources-loader";
import PageNotFound from "../../../PageNotFound";

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  768: 2,
  500: 1,
};

const Resources = () => {
  const [show, setShow] = useState(false);
  const [resourcesList, setResourcesList] = useState([]);
  const [resourcesPath, setResourcesPath] = useState("");
  const [showFilePreview, setShowFilePreview] = useState("");
  // const currentID = useRef("");
  const showModalHandler = useCallback(() => setShow(!show), [show]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      fetchResourcesList();
    }
  }, [show]);
  useEffect(() => {
    setLoading(true);
    fetchResourcesList();
  }, []);
  const fetchResourcesList = async () => {
    const response = await postService(GET_RESOURCES_LIST, {
      filter: {},
      pagination: {
        limit: 5,
        offset: 0,
      },
      sort: {
        resource_id: "desc",
      },
    });
    console.log("GET_RESOURCES_LIST", response.data?.data?.rows);
    // const allFileTypeArray = [...new Set(response.data?.data?.filetypes.map(
    //   (i) => i.file_content_type
    // ))];
    if (response.data?.data?.rows.length > 0) {
      setResourcesList(response.data?.data?.rows);
      setLoading(false);
    }

    // const modifiedResourcesList=allFileTypeArray.map((i)=>{return i})
  };
  const fileTypeNameHandler = (type) => {
    switch (type) {
      case "audio_video":
        return "Audio & Video";
      case "text":
        return "Text";
      case "interactive_video":
        return "Interactive Video";
      case "zip":
        return "Zip";
      case "image":
        return "Image";
      case "PDF":
        return "PDF";
      case "HTML":
        return "HTML";

      default:
        return "";
    }
  };
  const fetchResourcesDetails = useCallback(
    async (id) => {
      // if (currentID.current === id) {
      //   return;
      // }
      const response = await getService(RESOURCES_DETAILS + id);
      if (response && response?.data?.success) {
        setResourcesPath(response?.data?.data?.resource_path);
        // currentID.current = id;
        setShowFilePreview(id);
      }
      console.log("resourcesDetails", response);
    },
    [resourcesPath]
  );
  const onViewModalClose = () => setShowFilePreview("");
  console.log("resourcesPath", resourcesPath);
  return (
    <>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={showModalHandler}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <h3 className="mb-4">Resources</h3>
      <Card>
        <Card.Body>
          {/* <Row className="mb-4">
            <Col lg="7">
              <div className="position-relative iq-video-hover mb-2 rounded-3">
                <Link
                  //  onClick={() => videoOnSlide(props.id)}
                  to="#"
                >
                  <Image src={img1} className="img-fluid w-100" alt="" />
                  <div className="iq-btn-video btn btn-white text-primary rounded-pill btn-icon position-absolute">
                    <span className="text-white">
                      {findIcon("Chevron Right", "solid")}
                    </span>
                  </div>
                </Link>
              </div>
            </Col>
            <Col lg="5">
              <div className="d-flex flex-column gap-2 bg-soft-warning px-3 py-3 rounded-3">
                <h3>Getting Started: A Beginner's Guide to Medical Officer</h3>
                <span>
                  <b>Introduction:</b> Start with an introduction that provides
                  an overview of the video's purpose and what viewers can expect
                  to learn or achieve by watching it.
                </span>
                <span>
                  <b>Problem Statement:</b> Clearly define the problem or
                  challenge that the video aims to address. Explain why it's
                  important and how it can impact users.
                </span>
                <span>
                  <b>Step-by-Step Instructions:</b> Break down the solution or
                  troubleshooting process into clear and concise steps.
                  Demonstrate each step visually, explaining the actions
                  required and the expected outcomes.
                </span>
              </div>
            </Col>
          </Row> */}
          <h5>All files</h5>
          <Row className="row-cols-1 row-cols-md-4 row-cols-lg-6">
            {resourcesList && resourcesList.length > 0 && !loading ? (
              resourcesList.map((i) => (
                <Col key={i.file_type_id}>
                  <ContentCard
                    title={i.resource_name}
                    description={i.resource_description ?? ""}
                    extraClass={""}
                    icon={
                      i?.resource_file_type_id
                        ? i?.resource_file_type_id?.file_type_icon
                        : ""
                    }
                    id={i.resource_id}
                    trash={false}
                    type={
                      i?.resource_file_type_id
                        ? i?.resource_file_type_id?.file_type_code
                        : ""
                    }
                    file={`${app_config.fileStoragePath}${resourcesPath}`}
                    onClickPreview={fetchResourcesDetails}
                    // filePreviewModal={!!resourcesPath}
                    filePreviewModal={showFilePreview == i.resource_id}
                    onViewModalClose={onViewModalClose}
                  />
                </Col>
              ))
            ) : (
              <ResourcesLoader />
            )}
            {/* <Col>
              <ContentCard
                imageDate="Dec 13, 2020"
                imgName="Advanced Techniques and Solutions"
                imgLink="2 day ago"
                imgChange={img2}
                id={2}
                type="video"
              />
            </Col>
            <Col>
              <ContentCard
                imageDate="Dec 13, 2020"
                imgName="Best Practices and Recommendations"
                imgLink="a month ago"
                imgChange={img3}
                id={3}
                type="video"
              />
            </Col>
            <Col>
              <ContentCard
                imageDate="Dec 13, 2020"
                imgName="How to Get Help and Assistance"
                imgLink="a month ago"
                imgChange={img4}
                id={4}
                type="video"
              />
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
      {/* <Card>
        <Card.Body>
          <h5>Tutorial PDF Files</h5>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
            <Col>
              <ContentCard
                imageDate="Dec 13, 2020"
                imgName="Troubleshooting Tips and Tricks"
                imgLink="just now"
                imgChange={pdf}
                id={1}
                type="pdf"
              />
            </Col>
            <Col>
              <ContentCard
                imageDate="Dec 13, 2020"
                imgName="Advanced Techniques and Solutions"
                imgLink="2 day ago"
                imgChange={pdf}
                id={2}
                type="pdf"
              />
            </Col>
            <Col>
              <ContentCard
                imageDate="Dec 13, 2020"
                imgName="Best Practices and Recommendations"
                imgLink="a month ago"
                imgChange={pdf}
                id={3}
                type="pdf"
              />
            </Col>
            <Col>
              <ContentCard
                imageDate="Dec 13, 2020"
                imgName="How to Get Help and Assistance"
                imgLink="a month ago"
                imgChange={pdf}
                id={4}
                type="pdf"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card> */}
      <ContentUploadModal showModalHandler={showModalHandler} show={show} />
    </>
  );
};

export default Resources;
