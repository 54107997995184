import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ADD_STATE_POST,
  ALL_STATE_POST,
  COUNTRY_LIST_GET,
  DELETE_STATE_POST,
  STATE_DETAILS_GET,
  UPDATE_STATE_POST,
  ZONE_LIST_GET,
} from "../../../../../api/endpoints/state-endpoints";
import toast from "react-hot-toast";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllState = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewState, setViewState] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      state_id: "desc",
    },
  });
  //yup and formik for validation of form fields of priority_name

  const validationSchema = yup.object().shape({
    state_name: yup.string().required("State Name is required"),
    state_zone_id: yup.string().required("Zone is required"),
    // state_code: yup.string().required("State Code is required"),
    state_country_id: yup.string().required("Country is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      state_name: "",
      state_zone_id: "",
      // state_code: "",
      state_country_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("values===>", values);

      const postValues = {
        ...values,
        state_name: values.state_name.trim(),
      };

      try {
        // setLoading(true);
        const response = await postService(
          isEdit ? UPDATE_STATE_POST + "/" + selectedState : ADD_STATE_POST,
          {
            ...postValues,
            // state_active: 1,
            status: 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchState(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(
            isEdit ? "State Updated Successfully" : "State Added Successfully"
          );
        }
      } catch (error) {
        setLoading(false);
        console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  console.log("formic test", values, errors);
  //fetch countries
  const fetchState = async (data) => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_STATE_POST, data);
      const response_country_list = await getService(COUNTRY_LIST_GET, data);
      console.log(
        "COUNTRY_LIST_RESPONSE",
        response_country_list.data.data.country
      );
      if (response_country_list?.data?.data?.country?.length > 0) {
        setCountryList(response_country_list?.data?.data?.country);
      }
      console.log("STATE_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setStateList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("STATE_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (state) => {
    console.log("state===>", state);
    let dataJson = {
      // "State ID": state.state_id,
      Country: state.state_country_id.country_name,
      Zone: state.state_zone_id.zone_name,
      "State Name": state.state_name,
      // "State Code": state.state_code,
    };
    try {
      const response = await getService(
        STATE_DETAILS_GET + "/" + state.state_id
      );
      console.log("STATE_DETAIL_SERVICE_RESPONSE", response.data.data);
      // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      // if (response.data.data.rows.length > 0) {
      //   setStateList(response.data.data.rows);
      //   setTotalCount(response.data.data.totalCount);
      //   setLoading(false);
      // }
    } catch (error) {
      console.log("STATE_SERVICE_ERROR", error);
    }

    setViewState(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteState = async () => {
    try {
      // setLoading(true);
      const response = await getService(
        `${DELETE_STATE_POST}/${selectedState}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchState(post_json.current);
        toast.success("State Deleted Successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("STATE_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchState(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchState(payload);
    },
    [stateList, loading, post_json.current]
  );
  const onDeleteState = (state) => {
    setSelectedState(state.state_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "State ID",
      accessor: "state_id",
    },
    {
      Header: "State Name",
      accessor: "state_name",
    },
    // {
    //   Header: "State Code",
    //   accessor: "state_code",
    // },
    {
      Header: "Country",
      accessor: "state_zone_id",
      Cell: ({ row }) => row?.original?.state_country_id?.country_name ?? "N/A",
    },
    {
      Header: "Zone",
      accessor: "state_country_id",
      Cell: ({ row }) => row?.original?.state_zone_id?.zone_name ?? "N/A",
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="info"
            to="#"
            role="button"
            onClick={() => viewModal(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Eye", "dual-tone", 32)}
            </span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            onClick={() => onEditStateClick(row.original)}
          >
            <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="danger"
            to="#"
            role="button"
            onClick={() => onDeleteState(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Trash", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    // setCountryList([])
    setZoneList([]);
    setCreateModalShow(true);
  };
  const onEditStateClick = (state) => {
    console.log("state===>", state);
    getZoneList(state.state_country_id.country_id);
    setIsEdit(true);
    setSelectedState(state.state_id);

    const editValues = {
      state_name: state?.state_name,
      state_country_id: state?.state_country_id?.country_id,
      // state_code: state?.state_code,
      state_zone_id: state?.state_zone_id?.zone_id,
    };
    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  const getZoneList = async (countryId) => {
    try {
      const response_zone_list = await getService(
        ZONE_LIST_GET + "/" + countryId
      );
      console.log("ZONE_LIST", response_zone_list?.data?.data?.zone);
      if (response_zone_list?.data?.data?.zone?.length > 0) {
        setZoneList(response_zone_list?.data?.data?.zone);
      }
    } catch (error) {
      console.log("ASSOCIATION_ZONE_ERROR", error);
    }
  };

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All State Names</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add State Name
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                stateList.length > 0 ? (
                  <ReactTable
                    data={stateList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteState}
      />
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewState}
      />
      {/* create a add Priority Name modal with Priority Name name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} State Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* country */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Country <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter State Country Id"
                name="state_country_id"
                onChange={(e) => {
                  handleChange(e);
                  getZoneList(e.target.value);
                }}
                value={values.state_country_id}
                onBlur={handleBlur}
                isInvalid={touched.state_country_id && errors.state_country_id}
              >
                <option value="">Select Country</option>
                {countryList.length > 0 &&
                  countryList.map((_c) => (
                    <option value={_c?.country_id}>{_c?.country_name}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.state_country_id}
              </Form.Control.Feedback>
            </Form.Group>
            {/* zone */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Zone <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                type="text"
                placeholder="Enter State Zone Id"
                name="state_zone_id"
                onChange={handleChange}
                value={values.state_zone_id}
                onBlur={handleBlur}
                isInvalid={touched.state_zone_id && errors.state_zone_id}
                disabled={!values.state_country_id}
              >
                <option value="">Select Zone</option>
                {zoneList.length > 0 &&
                  zoneList.map((_z) => (
                    <option value={_z?.zone_id}>{_z?.zone_name}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.state_zone_id}
              </Form.Control.Feedback>
            </Form.Group>
            {/* State name */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                State Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter State Name"
                name="state_name"
                onChange={handleChange}
                value={values.state_name}
                onBlur={handleBlur}
                isInvalid={touched.state_name && errors.state_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.state_name}
              </Form.Control.Feedback>
            </Form.Group>

            {/* state code */}
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>State Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter State Code"
                name="state_code"
                onChange={handleChange}
                value={values.state_code}
                onBlur={handleBlur}
                isInvalid={touched.state_code && errors.state_code}
              />
              <Form.Control.Feedback type="invalid">
                {errors.state_code}
              </Form.Control.Feedback>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllState;
