import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, FormCheck, Image, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";

import * as dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import {
  CONTENT_ADD_POST,
  CONTENT_CREATE_GET,
  CONTENT_STUDIO_SPAM_CHECK,
  CONTENT_STUDIO_UPDATE,
} from "../../../../api/endpoints/content-studio-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import { modifyKeyNames } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import DeleteModal from "../../../../components/partials/common/delete-modal";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";
import SpamScoreModal from "../../../../components/partials/common/spam-score-modal";
import SelectTemplateCard from "../component/select-template-card";
import CreateContentStudioModal from "../component/create-content-studio-modal";
import { useDispatch, useSelector } from "react-redux";
import { contentStudioGetData } from "../../../../store/content-studio/content-studio.selector";
import SubmitButton from "../../../../components/form-components/submit-button";
import { useContentStudioDetails } from "../../../../queries/query-hooks/content-studio-hook";
import { getContentStudio } from "../../../../store/content-studio/content-studio.action";
import postServiceAxios from "../../../../api/services/post-service-axios";
import withAuthorization from "../../../../components/hoc/with-authorization";

const emailObjectKeyMapping = {
  email_id: "id",
  email_name: "name",
  email_content: "email_content",
  approve: "approve",
};

const smsObjectKeyMapping = {
  sms_id: "id",
  sms_name: "name",
  sms_content: "content",
  approve: "approve",
};
const whatsappObjectKeyMapping = {
  whatsapp_id: "id",
  whatsapp_name: "name",
  whatsapp_content: "content",
  approve: "approve",
};

const CreateContentStudio = () => {
  const { id: contentStudioId } = useParams();
  const dispatch = useDispatch();
  const [createCsLoaing, setCreateCsLoading] = useState(false);
  const [contentStudioData, setContentStudioData] = useState({});
  const [templateList, setTemplateList] = useState({
    whatsapp: [],
    email: [],
    sms: [],
  });
  const [variationName, setVariationName] = useState("");
  const [contentName, setContentName] = useState("");
  const [showContentListModal, setShowContentListModal] = useState(false);
  const [showSpamScoretModal, setShowSpamScoretModal] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [spamScoreModalStep, setSpamScoreModalStep] = useState(1);
  let contentStudioDataRedux = useSelector(contentStudioGetData);
  const [loaderMessage, setLoaderMessage] = useState(null);
  const [progressMessage, setProgressMessage] = useState(null);
  const [checkStataus, setCheckStatus] = useState({
    whatsapp: false,
    sms: false,
    email: false,
  });
  const spamCheckStatus = useRef({
    whatsapp: { check: false },
    email: { check: false },
    sms: { check: false },
  });
  const deleteVariationName = useRef(null);
  const deleteVariationId = useRef(null);
  const variationId = useRef(null);
  const submitButtonRef = useRef([]);
  const { pathname } = useLocation();
  const { redirectTo } = useRedirect();

  const onContentStudioSuccess = (data) => {
    let variationList = [
      {
        id: 0,
        variation_name: "variation 1",
        templates: {
          whatsapp: {
            id: "",
            name: "",
            content: "",
            approve: "",
          },
          email: {
            id: "",
            name: "",
            content: "",
            approve: "",
          },
          sms: {
            id: "",
            name: "",
            content: "",
            approve: "",
          },
        },
        spam_score: {
          whatsapp: {
            id: 0,
            score: 0,
            messsage: "",
          },
          email: {
            id: 0,
            score: 0,
            messsage: "",
          },
          sms: {
            id: 0,
            score: 0,
            messsage: "",
          },
        },
        is_checkSpam_disable: false,
        is_variation_name_edit: false,
      },
    ];
    if (data) {
      if (data && Object.keys(data?.variations).length > 0) {
        variationList =
          Object.keys(data?.variations).length > 0
            ? Array(Object.keys(data?.variations).length)
                .fill(0)
                .map((_v, idx) => {
                  return {
                    id: idx,
                    variation_name: Object.keys(data?.variations)[idx],
                    templates: {
                      whatsapp: channelTemplateInitialDataHandler(
                        data,
                        "variations",
                        idx,
                        "whatsapp"
                      ),
                      email: channelTemplateInitialDataHandler(
                        data,
                        "variations",
                        idx,
                        "email"
                      ),
                      sms: channelTemplateInitialDataHandler(
                        data,
                        "variations",
                        idx,
                        "sms"
                      ),
                    },
                    spam_score: 0,
                    is_checkSpam_disable: false,
                    is_variation_name_edit: false,
                    is_content_name_edit: false,
                  };
                })
            : [];
      }

      setContentStudioData({
        ...contentStudioDataRedux,
        variations: variationList,
      });

      console.log("variationList:", variationList);
    } else {
      toast.error("Something went wrong! When fetching content files details");
    }
  };

  useEffect(() => {
    if (contentStudioId > 0) {
      setIsInEditMode(true);
    } else {
      setIsInEditMode(false);
    }
  }, [contentStudioId]);

  useEffect(() => {
    refetchDetails();
  }, [isInEditMode]);

  const {
    data: detailsData,
    isLoading: detailsLoader,
    refetch: refetchDetails,
  } = useContentStudioDetails(
    contentStudioId,
    isInEditMode,
    onContentStudioSuccess
  );

  const channelTemplateInitialDataHandler = (
    data,
    fieldType,
    index,
    channelType
  ) => {
    return Object.keys(Object.values(data[fieldType])[index]).includes(
      channelType
    )
      ? Object.values(data[fieldType])[index][channelType]
      : {
          id: "",
          name: "",
          content: "",
          approve: "",
        };
  };

  useEffect(() => {
    getCreateContentStudioData();
    return () => {
      setCreateCsLoading(false);
      setContentStudioData({});
      setTemplateList({
        whatsapp: [],
        email: [],
        sms: [],
      });
      setVariationName("");
      setContentName("");
      setShowContentListModal(false);
      setShowSpamScoretModal(false);
      setIsInEditMode(false);
      setFilePreview(false);
      setDeleteModalShow(false);
    };
  }, []);

  useEffect(() => {
    if (contentStudioDataRedux) {
      setContentStudioData(
        structuredClone({
          ...contentStudioDataRedux,
        })
      );
      submitButtonRef.current =
        contentStudioDataRedux.variations.length > 0 &&
        contentStudioDataRedux.variations.map((_s) => {
          return { id: _s.id, status: checkPendingTemplate(_s) };
        });
    }
  }, [contentStudioDataRedux]);

  function isallCheckDone(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== true) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (isallCheckDone(checkStataus)) {
      setSpamScoreModalStep(3);
    }
    console.log("spam check status", spamCheckStatus.current);
  }, [checkStataus]);
  const checkPendingTemplate = (_s, updatedChannelStatus) => {
    let channelStatus = updatedChannelStatus
      ? updatedChannelStatus
      : contentStudioDataRedux.channelStatus;
    let templates = _s.templates;
    let isWhatsappPending = !templates?.whatsapp?.approve;
    let isEmailPending = !templates?.email?.approve;
    let isSmsPending = !templates?.sms?.approve;
    let isAnyPendingTemp =
      (channelStatus.whatsapp ? isWhatsappPending : false) ||
      (channelStatus.email ? isEmailPending : false) ||
      (channelStatus.sms ? isSmsPending : false);

    return isAnyPendingTemp;
  };
  const submitTextType = contentStudioId ? "Update" : "Submit";

  const isSubmitButtonDisable = useMemo(() => {
    let isWhatsappDisable = !contentStudioData?.channelStatus?.whatsapp;
    let isEmailDisable = !contentStudioData?.channelStatus?.email;
    let isSmsDisable = !contentStudioData?.channelStatus?.sms;

    if (isWhatsappDisable && isEmailDisable && isSmsDisable) {
      return true;
    } else {
      let isAnyPendingTemplate = submitButtonRef.current
        .map((i) => i.status)
        .includes(true);

      if (pathname.includes("edit")) {
        let contentStudioNameUpdate =
          contentStudioDataRedux.content_studio_name ==
          contentStudioData.content_studio_name;
        let contentStudioContentUpdate =
          contentStudioDataRedux.content_studio_content_id ==
          contentStudioData.content_studio_content_id;
        return (
          isAnyPendingTemplate &&
          contentStudioNameUpdate &&
          contentStudioContentUpdate
        );
      } else {
        return isAnyPendingTemplate;
      }
    }
  }, [contentStudioData]);

  const getVariationName = (e) => {
    setVariationName(e.target.value);
  };

  const saveVariationNameHandler = (id) => {
    setContentStudioData({
      ...contentStudioData,
      variations: contentStudioData?.variations?.map((_v) => {
        if (_v.id === id) {
          _v.variation_name = variationName;
          _v.is_variation_name_edit = !_v.is_variation_name_edit;
        }
        return _v;
      }),
    });
  };

  const cancelVariationNameHandler = (id) =>
    setContentStudioData({
      ...contentStudioData,
      variations: contentStudioData?.variations?.map((_v) => {
        if (_v.id === id) {
          _v.is_variation_name_edit = !_v.is_variation_name_edit;
          setVariationName(_v.variation_name);
        }
        return _v;
      }),
    });

  const deleteVariationRow = () => {
    setContentStudioData({
      ...contentStudioData,
      variations: contentStudioData.variations.filter(
        (_v) => _v.id !== deleteVariationId.current
      ),
    });
    submitButtonRef.current.filter((_s) => _s.id !== deleteVariationId.current);
  };

  const editVariationName = (id) => {
    setContentStudioData({
      ...contentStudioData,
      variations: contentStudioData.variations.map((_v) => {
        if (_v.id === id) {
          _v.is_variation_name_edit = !_v.is_variation_name_edit;
          setVariationName(_v.variation_name);
        }
        return _v;
      }),
    });
  };

  const channelsCheckHandler = (type, checked) => {
    let updatedChannelStatus = {
      ...contentStudioData.channelStatus,
      [type]: !contentStudioData.channelStatus[type],
    };
    let updatedVariations = contentStudioData?.variations?.map((_v, idx) => {
      _v.templates[type] = {
        id: "",
        name: "",
        content: "",
        approve: "",
      };
      return _v;
    });
    submitButtonRef.current =
      updatedVariations.length > 0 &&
      updatedVariations.map((_s) => {
        return {
          id: _s.id,
          status: checkPendingTemplate(_s, updatedChannelStatus),
        };
      });
    setContentStudioData({
      ...contentStudioData,
      channelStatus: updatedChannelStatus,
      variations: updatedVariations,
    });
    // submitButtonRef.current
  };

  const addVariationRow = () => {
    let channelTempDetails = {
      id: "",
      name: "",
      content: "",
      approve: "",
    };
    setContentStudioData({
      ...contentStudioData,
      variations: [
        ...contentStudioData.variations,
        {
          id:
            contentStudioData.variations[
              contentStudioData.variations.length - 1
            ].id + 1,
          variation_name: `variation ${
            contentStudioData.variations.length + 1
          }`,
          templates: {
            whatsapp: channelTempDetails,
            email: channelTempDetails,
            sms: channelTempDetails,
          },
          spam_score: 0,
          is_checkSpam_disable: false,
          is_variation_name_edit: false,
        },
      ],
    });
    submitButtonRef.current = [
      ...submitButtonRef.current,
      {
        id: submitButtonRef.current[submitButtonRef.current.length - 1].id + 1,
        status: true,
      },
    ];
  };

  const isSpamScoreButtonDisable = (id) => {
    console.group("isSpamScoreButtonDisable");
    let disableSpamScore = contentStudioData?.variations?.filter(
      (_v) => _v.id == id
    );
    console.log("disableSpamScore", disableSpamScore);
    let isWhatsappChannelEnable = false;
    let isEmailChannelEnable = false;
    let isSmsChannelEnable = false;
    let channelStatus = contentStudioData?.channelStatus;
    console.log("channelStatus", channelStatus);
    let isAllChannelDisable =
      !channelStatus.whatsapp && !channelStatus.email && !channelStatus.sms;

    console.log("isAllChannelDisable", isAllChannelDisable);

    if (disableSpamScore && disableSpamScore?.length > 0) {
      let templates = disableSpamScore[0]?.templates;
      console.log("templates", templates);
      isWhatsappChannelEnable =
        channelStatus.whatsapp && templates?.whatsapp?.id;
      isEmailChannelEnable = channelStatus?.email && templates?.email?.id;
      isSmsChannelEnable = channelStatus?.sms && templates?.sms?.id;
    }
    console.groupEnd("isSpamScoreButtonDisable");
    if (isWhatsappChannelEnable && isEmailChannelEnable && isSmsChannelEnable) {
      if (isAllChannelDisable) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getCreateContentStudioData = async (e) => {
    if (createCsLoaing) return false;
    try {
      setCreateCsLoading(true);
      const response = await getService(`${CONTENT_CREATE_GET}`, {});

      if (response?.data && !response?.isError) {
        setCreateCsLoading(false);
        if (Object.keys(response?.data?.data?.templates)?.length > 0) {
          const modifiedEmailTemplateOpt = await modifyKeyNames(
            response?.data?.data?.templates?.email,
            emailObjectKeyMapping
          );
          const modifiedSmsTemplateOpt = await modifyKeyNames(
            response?.data?.data?.templates?.sms,
            smsObjectKeyMapping
          );
          const modifiedWhatsappTemplateOpt = await modifyKeyNames(
            response?.data?.data?.templates?.whatsapp,
            whatsappObjectKeyMapping
          );
          setTemplateList({
            ...templateList,
            whatsapp: modifiedWhatsappTemplateOpt,
            email: modifiedEmailTemplateOpt,
            sms: modifiedSmsTemplateOpt,
          });
        }
      } else {
      }
    } catch (error) {
      setCreateCsLoading(false);
    }
  };

  const selectedChannelTemplate = useCallback(
    (id, type, data) => {
      let updatedChannelStatus = contentStudioData
        ? contentStudioData.channelStatus
        : contentStudioDataRedux.channelStatus;
      let updatedVariations = contentStudioData.variations.map((_v) => {
        let typeHandler =
          type.toLowerCase() == "message" ? "sms" : type.toLowerCase();
        if (_v.id === id) {
          _v.templates[typeHandler] = data;
        }
        return _v;
      });

      submitButtonRef.current =
        updatedVariations.length > 0 &&
        updatedVariations.map((_s) => {
          return {
            id: _s.id,
            status: checkPendingTemplate(_s, updatedChannelStatus),
          };
        });

      setContentStudioData((prevState) => ({
        ...prevState,
        variations: updatedVariations,
      }));
    },
    [contentStudioData]
  );

  const filePreviewHandler = useCallback(
    () => setFilePreview(!filePreview),
    [filePreview]
  );

  const submitContentStudio = async () => {
    let postApiAction = `${CONTENT_STUDIO_UPDATE}${contentStudioId}`;
    if (!contentStudioData?.content_studio_content_id) return false;
    let postData = {
      content_studio_name: contentStudioData?.content_studio_name,
      content_studio_content_id: contentStudioData?.content_studio_content_id,
      variations: contentStudioData?.variations?.map((_p) => {
        return {
          variation_name: _p.variation_name,
          templates: Object.keys(_p.templates).reduce((obj, eachTemplate) => {
            if (_p.templates[eachTemplate].id !== "") {
              obj[eachTemplate] = _p.templates[eachTemplate].id;
            }
            return obj;
          }, {}),

          spam_score: _p.spam_score,
        };
      }),
    };
    const response = await postService(postApiAction, postData);
    if (response.data.success) {
      toast.success(response.data.message);
      redirectTo("content-studio/listing");
    } else {
      toast.error(response.data.message);
    }
  };

  const editContentFileHandler = (e) => {
    setShowContentListModal(!showContentListModal);
  };

  const checkSpamScorePost = async (payload, callbacks) => {
    try {
      const response = await postServiceAxios(
        CONTENT_STUDIO_SPAM_CHECK,
        payload
      );
      callbacks(response?.data);
    } catch (error) {
      callbacks("error");
    }
  };

  const checkSpamScore = (templatesData = {}) => {
    setShowSpamScoretModal(!showSpamScoretModal);
    const { templates, variation_name } = templatesData ?? {};
    // -------------------------------------------
    if (
      "templates" in templatesData &&
      templates?.email &&
      templates?.email?.email_content &&
      !spamCheckStatus?.current?.email?.check
    ) {
      const emailCheckPayload = {
        type: "email",
        subject: templates?.email?.email_content,
        body: templates?.email?.email_content,
      };
      setLoaderMessage("Analysing Email Template");

      checkSpamScorePost(emailCheckPayload, (data) => {
        if (data && data?.output?.success) {
          setProgressMessage(
            `Email Spam Score for  ${variation_name} is ${data?.output?.data?.score}`
          );
          spamCheckStatus.current.email.check = true;
          spamCheckStatus.current.email.checkData = data?.output?.data;
          setCheckStatus((prev) => ({ ...prev, email: true }));
        } else {
          setSpamScoreModalStep(2);
        }
      });
    }

    // ----------------------------------------
    if (
      "templates" in templatesData &&
      templates?.sms &&
      templates?.sms?.content &&
      !spamCheckStatus?.current?.sms?.check
    ) {
      const smsCheckPayload = {
        type: "sms",
        content: templates?.sms?.content,
      };
      setLoaderMessage("Analysing SMS Template");
      checkSpamScorePost(smsCheckPayload, (data) => {
        if (data && data?.output?.success) {
          setProgressMessage(
            `SMS Spam Score for  ${variation_name} is  ${data?.output?.data?.score}`
          );
          spamCheckStatus.current.sms.check = true;
          spamCheckStatus.current.sms.checkData = data?.output?.data;
          setCheckStatus((prev) => ({ ...prev, sms: true }));
        } else {
          setSpamScoreModalStep(2);
        }
      });
    }

    // -----------------------------------------
    if (
      "templates" in templatesData &&
      templates?.whatsapp &&
      templates?.whatsapp?.content &&
      !spamCheckStatus?.current?.whatsapp?.check
    ) {
      const whatsappCheckPayload = {
        type: "whatsapp",
        content: templates?.whatsapp?.content,
      };

      checkSpamScorePost(whatsappCheckPayload, (data) => {
        if (data && data?.output?.success) {
          setProgressMessage(
            `Whatsapp Spam Score for  ${variation_name} is ${data?.output?.data?.score}`
          );
          spamCheckStatus.current.whatsapp.check = true;
          spamCheckStatus.current.whatsapp.checkData = data?.output?.data;
          setCheckStatus((prev) => ({ ...prev, whatsapp: true }));
        } else {
          setSpamScoreModalStep(2);
        }
      });
    }
  };

  const spamScoreModalHandler = (id) => {
    const tempData = contentStudioData?.variations;
    variationId.current = id;
    // checkSpamScore(tempData[id]);
    setShowSpamScoretModal(!showSpamScoretModal);
  };

  const onCheckSpamScoreClick = (id) => {
    const tempData = contentStudioData?.variations;
    variationId.current = id;
    setSpamScoreModalStep(1);
    checkSpamScore(tempData[id]);
  };

  const deleteModalHandler = () => {
    setDeleteModalShow(!deleteModalShow);
  };

  const getContentName = (e) => {
    setContentName(e.target.value);
  };

  const saveContentStudioName = () => {
    setContentStudioData({
      ...contentStudioData,
      is_content_name_edit: !contentStudioData?.is_content_name_edit,
      content_studio_name: contentName,
    });
  };

  const cancelContentStudioName = () => {
    setContentName(contentStudioData?.content_studio_name);
    setContentStudioData({
      ...contentStudioData,
      is_content_name_edit: !contentStudioData?.is_content_name_edit,
    });
  };

  const editContentStudioName = () => {
    setContentStudioData({
      ...contentStudioData,
      is_content_name_edit: !contentStudioData?.is_content_name_edit,
    });
  };

  const isSpamScoreDone = useCallback(
    (id, data) => {
      if (data) {
        setContentStudioData((prevState) => ({
          ...prevState,
          variations: contentStudioData.variations.map((_v) => {
            if (_v.id === id) {
              _v.is_checkSpam_disable = true;
            }
            return _v;
          }),
        }));
      }
    },
    [contentStudioData]
  );

  const editVariation = (id) => {
    setContentStudioData((prevState) => ({
      ...prevState,
      variations: contentStudioData.variations.map((_v) => {
        if (_v.id === id) {
          _v.is_checkSpam_disable = false;
        }
        return _v;
      }),
    }));
  };

  const selectContentFile = useCallback(
    (item) => {
      setContentStudioData({
        ...contentStudioData,
        content_studio_content_id: item?.id,
        content_studio_content_name: item?.fileName,
        content_studio_content_icon: item?.fileTypeIcon,
        content_studio_content_publish_date: item?.publicationDate,
        content_studio_content_description: item?.fileDescription,
        content_studio_content_type: item?.fileType,
        content_studio_content_created_by: item?.createdBy,
        content_studio_content_url: item.fileLink,
      });
      setShowContentListModal(false);
    },
    [contentStudioData]
  );

  console.log("contentStudioData", contentStudioData.is_content_name_edit);
  return (
    <>
      <Row>
        <div className="d-flex justify-content-start align-items-center gap-2 mb-2">
          {contentStudioData.is_content_name_edit ? (
            <>
              <input
                type="text"
                value={contentName}
                onChange={getContentName}
                className="border-1 rounded-3 px-2 py-1"
              />
              <Button
                variant="success"
                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                type="submit"
                onClick={saveContentStudioName}
                disabled={
                  contentName === contentStudioData?.content_studio_name
                }
              >
                {findIcon("Check", "outline", "18")}
              </Button>
              <Button
                variant="danger"
                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                type="submit"
                onClick={cancelContentStudioName}
              >
                {findIcon("Close-2", "outline", "14")}
              </Button>
            </>
          ) : (
            contentStudioData?.content_studio_name && (
              <>
                <h4 className="mb-0">
                  {contentStudioData?.content_studio_name}
                </h4>
                {
                  <span
                    onClick={editContentStudioName}
                    className="cursor-pointer d-inline-flex"
                  >
                    {findIcon("Pencil", "dual-tone", "20")}
                  </span>
                }
              </>
            )
          )}
        </div>
        <Col lg="12" className="p-0 p=md-3">
          <Card>
            <Card.Body className="p-2 p-md-3">
              <Row>
                <Col lg={"9"} className="pb-2 pb-md-0">
                  <div className="d-flex align-items-stretch mt-0 p-3 border border-1 rounded-3 h-100">
                    <div
                      className={`d-flex align-items-center justify-content-center avatar-50 rounded-3 p-2 flex-shrink-0 bg-soft-primary`}
                    >
                      {contentStudioData?.content_studio_content_icon && (
                        <Image
                          src={contentStudioData?.content_studio_content_icon}
                          className="mw-100 mx-100 h-auto icon-24"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="flex-grow-1 mx-3 d-flex flex-column justify-content-start">
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        {contentStudioData?.content_studio_content_name && (
                          <>
                            <h6 className="mb-0">
                              {contentStudioData?.content_studio_content_name}
                            </h6>

                            {
                              <span
                                onClick={editContentFileHandler}
                                className="cursor-pointer d-inline-flex"
                              >
                                {findIcon("Pencil", "dual-tone", "20")}
                              </span>
                            }
                          </>
                        )}
                      </div>
                      {contentStudioData?.content_studio_content_publish_date && (
                        <h6 className="mt-1 fw-normal text-secondary">
                          <small>
                            {dayjs(
                              contentStudioData?.content_studio_content_publish_date
                            ).format("DD MMM YYYY")}
                          </small>
                        </h6>
                      )}
                      {contentStudioData?.content_studio_content_description && (
                        <p className="mb-2">
                          {
                            contentStudioData?.content_studio_content_description
                          }
                        </p>
                      )}
                      <div class="d-flex justify-content-start gap-2">
                        {contentStudioData?.content_studio_content_created_by && (
                          <span class="fs-6">
                            Created By
                            {
                              contentStudioData?.content_studio_content_created_by
                            }
                          </span>
                        )}
                      </div>
                    </div>

                    <Button
                      variant="soft-primary"
                      onClick={filePreviewHandler}
                      className="rounded-circle flex-shrink-0 avatar-40 lh-1 d-flex align-items-center justify-content-center p-1"
                    >
                      {findIcon("Eye", "solid", "24")}
                    </Button>
                  </div>
                </Col>

                <Col lg="3">
                  <div className="d-flex flex-column align-items-start gap-2 p-3 bg-soft-primary rounded-3">
                    <h5 className="mb-1">Channels</h5>
                    <Form.Check className="form-switch form-check-reverse w-100">
                      <FormCheck.Input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={contentStudioData?.channelStatus?.whatsapp}
                        onChange={(e) =>
                          channelsCheckHandler("whatsapp", e.target.checked)
                        }
                      />
                      <FormCheck.Label
                        className={`form-check-label d-flex gap-2 align-items-center w-100`}
                        htmlFor="flexSwitchCheckChecked"
                      >
                        {findIcon("Whatsapp", "dual-tone", "20")}WhatsApp
                      </FormCheck.Label>
                    </Form.Check>
                    <Form.Check className="form-switch form-check-reverse w-100">
                      <FormCheck.Input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked2"
                        checked={contentStudioData?.channelStatus?.email}
                        onChange={(e) =>
                          channelsCheckHandler("email", e.target.checked)
                        }
                      />
                      <FormCheck.Label
                        className={`form-check-label d-flex gap-2 align-items-center w-100`}
                        htmlFor="flexSwitchCheckChecked2"
                      >
                        {findIcon("Email", "dual-tone", "20")}
                        Email
                      </FormCheck.Label>
                    </Form.Check>
                    <Form.Check className="form-switch form-check-reverse w-100">
                      <FormCheck.Input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked3"
                        checked={contentStudioData?.channelStatus?.sms}
                        onChange={(e) =>
                          channelsCheckHandler("sms", e.target.checked)
                        }
                      />
                      <FormCheck.Label
                        className={`form-check-label d-flex gap-2 align-items-center w-100`}
                        htmlFor="flexSwitchCheckChecked3"
                      >
                        {findIcon("Message-2", "dual-tone", "20")}
                        SMS
                      </FormCheck.Label>
                    </Form.Check>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12" className="mt-4">
                  {contentStudioData &&
                    contentStudioData?.variations?.length > 0 &&
                    contentStudioData?.variations?.map((_v, index) => (
                      <Card
                        className={`bg-soft-${
                          _v.is_checkSpam_disable ? "secondary" : "primary"
                        } mb-3`}
                        key={index + 1}
                      >
                        <Card.Header className="flex-column flex-md-row align-items-start align-items-md-center p-2 p-md-3 pb-3 pb-md-0">
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            {_v.is_variation_name_edit ? (
                              <>
                                <input
                                  type="text"
                                  value={variationName}
                                  onChange={getVariationName}
                                  className="border-0 rounded-3 px-2 py-1"
                                />
                                <Button
                                  variant="success"
                                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                  type="submit"
                                  onClick={() =>
                                    saveVariationNameHandler(_v.id)
                                  }
                                  disabled={variationName === _v.variation_name}
                                >
                                  {findIcon("Check", "outline", "18")}
                                </Button>
                                <Button
                                  variant="danger"
                                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                  type="submit"
                                  onClick={() =>
                                    cancelVariationNameHandler(_v.id)
                                  }
                                >
                                  {findIcon("Close-2", "outline", "14")}
                                </Button>
                              </>
                            ) : (
                              _v.variation_name && (
                                <>
                                  <h5 className="mb-0">{_v.variation_name}</h5>
                                  {_v.variation_name && (
                                    <span
                                      onClick={() => editVariationName(_v.id)}
                                      className="cursor-pointer d-inline-flex"
                                    >
                                      {findIcon("Pencil", "dual-tone", "20")}
                                    </span>
                                  )}
                                </>
                              )
                            )}
                          </div>
                          {
                            <div className="justify-content-center align-items-center d-flex gap-2 mt-3 mt-md-0">
                              {_v.is_checkSpam_disable ? (
                                <Button
                                  variant="success"
                                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                  type="submit"
                                  onClick={() => editVariation(_v.id)}
                                >
                                  {findIcon("Pencil", "dual-tone", "20")}
                                </Button>
                              ) : (
                                //
                                <Button
                                  variant="outline-primary"
                                  className="flex-grow-1 flex-md-grow-0"
                                  type="submit"
                                  disabled={isSpamScoreButtonDisable(_v.id)}
                                  onClick={() => onCheckSpamScoreClick(_v.id)}
                                >
                                  Check Spam Score & Self Test
                                </Button>
                              )}
                              {contentStudioData?.variations?.length > 1 && (
                                <Button
                                  variant="danger"
                                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                  type="submit"
                                  onClick={() => {
                                    deleteVariationId.current = _v.id;
                                    deleteVariationName.current =
                                      _v.variation_name;
                                    deleteModalHandler();
                                  }}
                                >
                                  {findIcon("Trash", "solid", "18")}
                                </Button>
                              )}
                            </div>
                          }
                        </Card.Header>
                        <Card.Body className="p-2 p-md-3">
                          <Row className="mt-n3">
                            <Col lg={4} className="mt-3">
                              <SelectTemplateCard
                                svg={findIcon("Whatsapp", "dual-tone", "22")}
                                colors={
                                  contentStudioData?.channelStatus?.whatsapp
                                    ? "success"
                                    : "secondary"
                                }
                                type="WhatsApp"
                                view={false}
                                selectedTemplateData={
                                  _v?.templates?.whatsapp ?? {}
                                }
                                selectedChannelTemplate={
                                  selectedChannelTemplate
                                }
                                options={templateList?.whatsapp}
                                id={_v.id}
                                // templateView={templateView}
                                isChannelEnable={
                                  contentStudioData?.channelStatus?.whatsapp
                                }
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                            <Col lg={4} className="mt-3">
                              <SelectTemplateCard
                                svg={findIcon("Email", "dual-tone", "20")}
                                colors={
                                  contentStudioData?.channelStatus?.email
                                    ? "warning"
                                    : "secondary"
                                }
                                type="Email"
                                view={false}
                                selectedTemplateData={
                                  _v?.templates?.email ?? {}
                                }
                                selectedChannelTemplate={
                                  selectedChannelTemplate
                                }
                                options={templateList?.email}
                                id={_v.id}
                                // templateView={templateView}
                                isChannelEnable={
                                  contentStudioData?.channelStatus?.email
                                }
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                            <Col lg={4} className="mt-3">
                              {console.log("selected template sms", _v)}
                              <SelectTemplateCard
                                svg={findIcon("Message-2", "dual-tone", "20")}
                                colors={
                                  contentStudioData?.channelStatus?.sms
                                    ? "info"
                                    : "secondary"
                                }
                                type="Message"
                                view={false}
                                selectedTemplateData={_v?.templates?.sms ?? {}}
                                selectedChannelTemplate={
                                  selectedChannelTemplate
                                }
                                options={templateList?.sms}
                                id={_v.id}
                                // templateView={templateView}
                                isChannelEnable={
                                  contentStudioData?.channelStatus?.sms
                                }
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}

                  {
                    <div className="justify-content-center d-flex gap-3">
                      <Button
                        variant="outline-info"
                        className="flex-grow-1 flex-md-grow-0 gap-2 d-flex align-items-center justify-content-center border-dashed"
                        type="submit"
                        // disabled={createCsLoaing}
                        onClick={() => addVariationRow()}
                      >
                        {findIcon("Plus", "solid", "18")}
                        Add More Variation
                      </Button>
                    </div>
                  }
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col
          className={`d-flex justify-content-end flex-column flex-md-row mb-5 gap-2`}
        >
          {
            <>
              <SubmitButton
                className=""
                type="submit"
                onClick={submitContentStudio}
              >
                Save as Draft
              </SubmitButton>
              <SubmitButton
                className=""
                type="submit"
                disabled={isSubmitButtonDisable}
                onClick={submitContentStudio}
              >
                {submitTextType} Content Studio
              </SubmitButton>
            </>
          }
        </Col>
      </Row>

      {showSpamScoretModal && contentStudioData && (
        <SpamScoreModal
          show={showSpamScoretModal}
          modalClose={spamScoreModalHandler}
          variations={contentStudioData?.variations}
          channelStatus={contentStudioData?.channelStatus}
          isSpamScoreDone={isSpamScoreDone}
          variationId={variationId.current}
          loaderMessage={loaderMessage}
          modalStep={spamScoreModalStep}
          progressMessage={progressMessage}
          content_studio_id={contentStudioId}
        />
      )}
      <FilePreviewModal
        mediaType={contentStudioData?.content_studio_content_type}
        show={filePreview}
        viewModalClose={filePreviewHandler}
        title={contentStudioData?.content_studio_content_name}
        brand=""
        createdBy=""
        createdOn=""
        description=""
        icon=""
        file_url={contentStudioData?.content_studio_content_url}
        titleHtml=""
        coverImage=""
        descriptionHtml=""
        citation=""
      />
      <DeleteModal
        show={deleteModalShow}
        modalClose={deleteModalHandler}
        type={"content-studio"}
        onSubmit={deleteVariationRow}
        variationName={deleteVariationName.current}
      />
      <CreateContentStudioModal
        show={showContentListModal}
        handleCreateModalClose={editContentFileHandler}
        stepToShow={2}
        selectTemplateForContent={selectContentFile}
      />
    </>
  );
};

export default memo(CreateContentStudio);
