import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";

import { GET_PROFILE_DETAILS } from "../../api/endpoints/profile-endpoints";
import { PROFILE_DETAILS } from "../query-constant/profile-constant";

const staleTime = 300000;
const cacheTime = 600000;
export const useGetProfileDetails = (onSuccess) => {
  return useQuery(
    [PROFILE_DETAILS],
    () => axiosInstance.get(GET_PROFILE_DETAILS),
    {
      onSuccess,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
