import { memo, useCallback, useRef, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";

import * as dayjs from "dayjs";
import { useParams } from "react-router-dom";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";
import SelectTemplateCard from "../component/select-template-card";
import {
  useContentStudioDetails,
  useFileDetails,
} from "../../../../queries/query-hooks/content-studio-hook";
import { toast } from "react-hot-toast";
import withAuthorization from "../../../../components/hoc/with-authorization";
const ContentStudioDetails = () => {
  const { redirectTo } = useRedirect();
  const { id } = useParams();
  const contentLibraryId = useRef(null);
  const [fileDetailsApiEnabled, setFileDetailsApiEnabled] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [variations, setVariations] = useState([]);

  const channelTemplateInitialDataHandler = (
    data,
    fieldType,
    index,
    channelType
  ) => {
    return Object.keys(Object.values(data[fieldType])[index]).includes(
      channelType
    )
      ? Object.values(data[fieldType])[index][channelType]
      : {
          id: "",
          name: "",
          content: "",
          approve: "",
        };
  };

  const onFileDetailsGetSuccess = (data) => {
    console.log("onContentStudioSuccess1:", data);
  };

  const onContentStudioSuccess = (data) => {
    if (data) {
      console.log("onContentStudioSuccess12:", data);
      contentLibraryId.current = data?.content_studio_content_id;
      const variationList =
        Object.keys(data?.variations).length > 0
          ? Array(Object.keys(data?.variations).length)
              .fill(0)
              .map((_v, idx) => {
                return {
                  id: idx,
                  variation_name: Object.keys(data?.variations)[idx],
                  templates: {
                    whatsapp: channelTemplateInitialDataHandler(
                      data,
                      "variations",
                      idx,
                      "whatsapp"
                    ),
                    email: channelTemplateInitialDataHandler(
                      data,
                      "variations",
                      idx,
                      "email"
                    ),
                    sms: channelTemplateInitialDataHandler(
                      data,
                      "variations",
                      idx,
                      "sms"
                    ),
                  },
                  spam_status: 0,
                  is_checkSpam_disable: false,
                  is_variation_name_edit: false,
                  is_content_name_edit: false,
                };
              })
          : [];
      console.log("variationList", variationList);
      setVariations(variationList);
      setFileDetailsApiEnabled(true);
    } else {
      toast.error("Something went wrong! When fetching content files details");
    }
  };
  // new code
  const { data: detailsData, isLoading: contentStudioLoader } =
    useContentStudioDetails(id, true, onContentStudioSuccess);

  const { data: fileDetailsData, isLoading: fileDetailsLoader } =
    useFileDetails(
      contentLibraryId.current,
      fileDetailsApiEnabled,
      onFileDetailsGetSuccess
    );

  const filePreviewHandler = useCallback(
    () => setFilePreview(!filePreview),
    [filePreview]
  );

  return (
    <>
      <Row>
        <div className="d-flex justify-content-start align-items-center gap-2 mb-2">
          {detailsData?.content_studio_name && (
            <h4 className="mb-0">{detailsData?.content_studio_name}</h4>
          )}
        </div>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-end gap-2">
                <Button
                  variant="success"
                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                  type="submit"
                  onClick={() => redirectTo(`content-studio/edit/${id}`)}
                >
                  {findIcon("Pencil", "dual-tone", "20")}
                </Button>
              </div>
              <Row>
                <Col lg={"12"}>
                  <div className="d-flex mt-3 p-3 border border-1 rounded-3">
                    <div
                      className={`d-flex align-items-center justify-content-center avatar-50 rounded-3 p-2 flex-shrink-0 bg-soft-primary`}
                    >
                      {fileDetailsData?.detail?.content_file_type_id
                        ?.file_type_icon && (
                        <Image
                          src={
                            fileDetailsData?.detail?.content_file_type_id
                              ?.file_type_icon
                          }
                          className="mw-100 mx-100 h-auto icon-24"
                          alt="file  icon"
                        />
                      )}
                    </div>
                    <div className="flex-grow-1 ms-3 d-flex flex-column justify-content-center">
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        {fileDetailsData?.detail?.content_title && (
                          <>
                            <h6 className="mb-0">
                              {fileDetailsData?.detail?.content_title}
                            </h6>
                          </>
                        )}
                      </div>
                      {fileDetailsData?.detail?.content_publication_date && (
                        <h6 className="mt-1 fw-normal text-secondary">
                          <small>
                            {dayjs(
                              fileDetailsData?.detail?.content_publication_date
                            ).format("DD MMM YYYY")}
                          </small>
                        </h6>
                      )}
                      {fileDetailsData?.detail?.content_description && (
                        <p className="mb-2 textDoubleLine">
                          {fileDetailsData?.detail?.content_description}
                        </p>
                      )}
                      <div class="d-flex justify-content-start gap-2">
                        {fileDetailsData?.detail?.created_by?.name && (
                          <span class="fs-6">
                            Created By{" "}
                            {fileDetailsData?.detail?.created_by?.name}
                          </span>
                        )}
                      </div>
                    </div>

                    <Button
                      variant="soft-primary"
                      onClick={filePreviewHandler}
                      className="rounded-circle flex-shrink-0 avatar-40 lh-1 d-flex align-items-center justify-content-center p-1"
                    >
                      {findIcon("Eye", "solid", "24")}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12" className="mt-4">
                  {variations?.length > 0 &&
                    variations?.map((_v, index) => (
                      <Card
                        className={`bg-soft-${"primary"} mb-3`}
                        key={index + 1}
                      >
                        <Card.Header className="align-items-center p-3 pb-0">
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            {_v.variation_name && (
                              <>
                                <h5 className="mb-0">{_v.variation_name}</h5>
                              </>
                            )}
                          </div>
                        </Card.Header>
                        <Card.Body className="p-3">
                          <Row className="mt-n3">
                            <Col lg={4} className="mt-3">
                              <SelectTemplateCard
                                svg={findIcon("Whatsapp", "dual-tone", "22")}
                                colors={"success"}
                                type="WhatsApp"
                                view={true}
                                selectedTemplateData={
                                  _v?.templates?.whatsapp ?? {}
                                }
                                id={_v.id}
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                            <Col lg={4} className="mt-3">
                              <SelectTemplateCard
                                svg={findIcon("Email", "dual-tone", "20")}
                                colors={"warning"}
                                type="Email"
                                view={true}
                                selectedTemplateData={
                                  _v?.templates?.email ?? {}
                                }
                                id={_v.id}
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                            <Col lg={4} className="mt-3">
                              <SelectTemplateCard
                                svg={findIcon("Message-2", "dual-tone", "20")}
                                colors={"info"}
                                type="Message"
                                view={true}
                                selectedTemplateData={_v?.templates?.sms ?? {}}
                                id={_v.id}
                                disableChannel={_v.is_checkSpam_disable}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                </Col>
                <Col lg="12">
                  <Button
                    variant="secondary"
                    className="flex-grow-1 flex-md-grow-0 gap-2 d-flex align-items-center justify-content-center border-dashed"
                    type="submit"
                    onClick={() => {
                      redirectTo("content-studio/listing");
                    }}
                  >
                    {findIcon("Arrow Left", "outline", "18")}
                    Back
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className={`d-flex justify-content-${"start"} mb-5 gap-2`}></Col>
      </Row>
      {fileDetailsData?.detail && (
        <FilePreviewModal
          mediaType={
            fileDetailsData?.detail?.content_file_type_id?.file_type_code
          }
          show={filePreview}
          viewModalClose={filePreviewHandler}
          title={fileDetailsData?.detail?.content_title}
          brand={
            fileDetailsData?.detail?.content_brand_id?.principal_entity_name
          }
          createdBy={fileDetailsData?.detail?.created_by?.name}
          createdOn={fileDetailsData?.detail?.created_at}
          description={
            fileDetailsData?.detail?.content_to_file?.description_html
          }
          icon={fileDetailsData?.detail?.content_file_type_id?.file_type_icon}
          file_url={fileDetailsData?.detail?.content_to_file?.path}
          titleHtml={fileDetailsData?.detail?.content_to_file?.title_html}
          coverImage=""
          descriptionHtml={
            fileDetailsData?.detail?.content_to_file?.description_html
          }
          citation={fileDetailsData?.detail?.content_to_text?.citation_html}
        />
      )}
    </>
  );
};

export default memo(ContentStudioDetails);
