import React, { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Masonry from "react-masonry-css";
// React-dropzone
import "react-dropzone-uploader/dist/styles.css";

// Componet
import Document from "../components/document.js";
import Images from "../components/images.js";
import Video from "../components/video.js";
import Folder from "../components/folder";

//Img
import img1 from "../../../../assets/modules/file-manager/images/5.png";
import img2 from "../../../../assets/modules/file-manager/images/6.png";
import img3 from "../../../../assets/modules/file-manager/images/7.png";
import img4 from "../../../../assets/modules/file-manager/images/8.png";
import pdf from "../../../../assets/modules/file-manager/images/pdf.svg";
import excel from "../../../../assets/modules/file-manager/images/excel.svg";
import word from "../../../../assets/modules/file-manager/images/word.svg";
import img9 from "../../../../assets/modules/file-manager/images/svg/14.svg";
import img10 from "../../../../assets/modules/file-manager/images/svg/15.svg";
const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  768: 2,
  500: 1,
};
const ContentSearch = () => {
  return (
    <Fragment>
      <Row>
        {/* heading of the page */}
        <h1 className="fs-2  mb-3 content-title">Content Dashboard</h1>
        <Col lg="12">
          <Card>
            <Card.Body className="overflow-hidden">
              <iframe
                style={{ minHeight: "80vh", marginTop: "-5.4rem" }}
                src="https://atlas.nomic.ai/map/8ce86d25-f5ca-465e-9053-23d4c986651d/0e3ea15b-ea69-4070-9d46-e16bab70c5a5"
                // style="border:0px #ffffff none;"
                name="myiFrame"
                scrolling="no"
                frameborder="1"
                marginheight="0px"
                marginwidth="0px"
                height="400px"
                width="600px"
                className="w-100 border-0 mt-n5"
                allowfullscreen
              ></iframe>
            </Card.Body>
          </Card>
        </Col>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <Folder foldername="Image" foldersvg="1" img={img3} id="1" />
          <Folder foldername="Video" foldersvg="2" img={img2} id="2" />
          <Folder foldername="Documents" foldersvg="3" img={img1} id="3" />
          <Folder foldername="Audio" foldersvg="4" img={img9} id="4" />
          <Folder foldername="Movies" foldersvg="5" img={img2} id="5" />
          <Folder foldername="Assignment" foldersvg="6" img={img10} id="6" />
          <Folder foldername="Ui-Kit" foldersvg="7" img={img3} id="7" />
          <Folder foldername="Design" foldersvg="8" img={img3} id="8" />
          <Document
            imageDate="Dec 13, 2020"
            media="pdf"
            imgName="Doc-123472578.pdf"
            imgLink="just now"
            imgChange={pdf}
            imgMb="5mb"
            docType="PDF"
            docName="demo.pdf"
          ></Document>
          <Images
            imageDate="Dec 13, 2020"
            imgName="1234725783"
            imgLink="just now"
            imgChange={img1}
            id={6}
          ></Images>
          <Video
            imageDate="Dec 13, 2020"
            imgName="1234725783.mp4"
            imgLink="just now"
            imgChange={img1}
            id={6}
          ></Video>
          <Document
            imageDate="Dec 13, 2020"
            media="xlxs"
            imgName="Doc-25783.xlsx"
            imgLink="2 days ago"
            imgChange={excel}
            imgMb="2mb"
            docType="XLSX"
            docName="demo.xlsx"
          ></Document>
          <Document
            imageDate="Dec 13, 2020"
            media="word"
            imgName="abc-25783.doc"
            imgLink="a month ago"
            imgChange={word}
            imgMb="7mb"
            docType="DOCX"
            docName="demo.docx"
          ></Document>
        </Masonry>
      </Row>
    </Fragment>
  );
};

export default ContentSearch;
