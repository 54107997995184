import React from "react";
import { Button, Modal } from "react-bootstrap";

const DeleteModal = ({ show, modalClose, onSubmit, type, variationName }) => {
  const confirmDelete = () => {
    onSubmit();
    modalClose();
  };
  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onClick={modalClose}>
          <Modal.Title id="contained-modal-title-vcenter">
            Please Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This action will permanently delete{" "}
            {type === "content-studio" ? <b>{variationName}</b> : "the file"}.
            Are you sure you want to proceed?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={modalClose}>Cancel</Button>
          <Button type="button" variant="danger" onClick={confirmDelete}>
            Yes I'm sure
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
