import React from "react";
import { Row, Col, Button, Dropdown, Image, Form } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";

const Research = () => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body className="overflow-hidden">
              <iframe
                style={{ minHeight: "80vh", marginTop: "-5.4rem" }}
                src="https://img-cdn.clirnet.com/tsne_2_5F9kPWEEV.html"
                // src="https://atlas.nomic.ai/map/8ce86d25-f5ca-465e-9053-23d4c986651d/0e3ea15b-ea69-4070-9d46-e16bab70c5a5"
                // style="border:0px #ffffff none;"
                name="myiFrame"
                scrolling="no"
                frameborder="1"
                marginheight="0px"
                marginwidth="0px"
                height="400px"
                width="600px"
                className="w-100 border-0 mt-n5"
                allowfullscreen
              ></iframe>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Research;
