import React, { useState } from "react";
import { Card } from "react-bootstrap";
import CustomToggle from "../../../../components/dropdowns";
import Chart from "react-apexcharts";
import { usePostOnboardingTaskAnalytics } from "../../../../queries/query-hooks/onboarding-hook";
import AnalyticsDropdown from "./AnalyticsDropDown";
import dateFilterOptions from "../Constant";
import OnboardingCardSkeleton from "../../../../skeleton/onboarding/OnboardingCardSkeleton"

const TaskAnalytics = () => {
  const [payload, setPayload] = useState({
    employee_id: "",
    type: dateFilterOptions.initial,
    next: "",
    prev: "",
    startdate: "",
    enddate: "",
  });

  const { data: onboardingTaskAnalytics , isLoading : isonboardingTaskLoading } =
    usePostOnboardingTaskAnalytics(payload);

  const handleDropdownChange = (type) => {
    setPayload({
      ...payload,
      type: type,
    });
  };

  let callAnalytics = {
    series: [
      {
        name: "Total",
        type: "area",
        data: onboardingTaskAnalytics?.data?.task,
      },
      {
        name: "Completed",
        type: "column",
        data: onboardingTaskAnalytics?.data?.completed_task,
      },
      {
        name: "Rejected",
        type: "area",
        data: onboardingTaskAnalytics?.data?.rejected_task,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      zoom: {
        enabled: false,
      },
      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: onboardingTaskAnalytics?.data?.labels,
      markers: {
        size: 0,
      },
      xaxis: {
        categories: onboardingTaskAnalytics?.data?.labels,
      },
      yaxis: {
        title: {
          text: "Points",
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " points";
            }
            return y;
          },
        },
      },
    },
  };

  if(isonboardingTaskLoading) {
    return <OnboardingCardSkeleton/>
  }
 
  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
        <Card.Title>
          <h4 className="mb-0"> Task Analytics</h4>
          <p className="mb-0 fs-7 text-capitalize mt-2">{payload.type}</p>
        </Card.Title>
        <AnalyticsDropdown
          handleDropdownChange={handleDropdownChange}
          type={payload.type}
        />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <Chart
          options={callAnalytics?.options}
          series={callAnalytics?.series}
          type="line"
        />
      </Card.Body>
    </Card>
  );
};

export default TaskAnalytics;
