import React from "react";
import { Card, Col, Row, Badge } from "react-bootstrap";

const ContentDetails = () => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div>
                <span className="text-primary">03 May 2023</span>
                <Badge pill bg="soft-info" className="ms-2 fw-normal">
                  CiplaMED
                </Badge>
                <Badge pill bg="soft-info" className="ms-2 fw-normal">
                  Respiratory
                </Badge>
                <h4 className="mt-2">Onboarding Task</h4>
                {/* <div className='d-flex gap-3'>
                  <span className='text-dark fs-6'>Brand Name</span>
                  <span> | </span>
                  <span className='text-dark fs-6'>Infographic</span>
                </div> */}
                <p className="mt-3">
                  Onboard and collect consent from this user. Also, try to
                  verify and update information.
                </p>
                <div className="d-flex justify-content-end gap-3">
                  <span className="text-dark fs-6">Onboarding Department</span>
                  <span> | </span>
                  <span className="text-dark fs-6">Created By Amit Singh</span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ContentDetails;
