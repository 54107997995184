import React from "react";
import { Card, Row } from "react-bootstrap";
import { useHcpDetails } from "../../../../../queries/query-hooks/hcp-profile-hook";
import { useParams } from "react-router-dom";
import HcpInformationSkeleton from "../../../../../skeleton/hcp/HcpProfileDetails/HcpInformationSkeleton";

const CampaignInformation = ({hcpDetail ,isHcpDetailsLoading}) => {
  // const { id: hcpId } = useParams();
  // //   const commonPayload = { hcp_id: hcpId };
  // const onSuccess = (response) => {
  //   //response
  //   //console.log("reponse", response);
  // };

  // const {
  //   data: hcpDetail,
  //   refetch: refetchHcpDetails,
  //   isLoading,
  // } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  if (isHcpDetailsLoading) {
    return <HcpInformationSkeleton />;
  }

  return (
    <Card className="h-100 bg-soft-warning">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Campaign Information</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
          <Row>
            <div className="col-7 mb-3">Utm Source</div>
            <div className="col-5 mb-3">{hcpDetail?.hcp_utmsource}</div>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CampaignInformation;
