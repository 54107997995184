import React, { useState } from 'react'
import { Modal, Col, Form, Button } from 'react-bootstrap'
import SubmitButton from '../../../../components/form-components/submit-button'

export default function AddCommentsModal({ show, onSubmit, onHide }) {
    const [comment, setComment] = useState("");
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please write Reason
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Col lg={12} className="mb-4">
                    <Form.Floating
                        controlId="floatingTextarea2"
                        label="Comments"
                        className="custom-form-floating"
                    >
                        <Form.Control
                            as="textarea"
                            placeholder="Leave a comment here"
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <Form.Label>Comments</Form.Label>
                    </Form.Floating>
                </Col>
                <Col lg={12} className="d-flex justify-content-end">
                    <Button
                        tSubmitButtonype="submit"
                        onClick={() => onSubmit(comment)}
                    >
                        Submit
                    </Button>
                </Col>
            </Modal.Body>
        </Modal>
    )
}
