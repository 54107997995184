import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import SocialLink from "../pages/all-social-link";


export const SocialLinkRouter = [
  {
    path: "/master-management/social-link",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <SocialLink />,
      },
    ],
  },
];