import React, { memo } from "react";
import { Card, Image } from "react-bootstrap";
import Skeleton from "../../../../../components/skeleton";
import { useHcpDetails } from "../../../../../queries/query-hooks/hcp-profile-hook";
import avatars11 from "../../../../../assets/images/avatars/01.png";
import { useParams } from "react-router-dom";

function ProfileLogo() {
  const { id: hcpId } = useParams();

  const onSuccess = (response) => {
    //response
    //console.log("reponse", response);
  };

  const commonPayload = { hcp_id: hcpId };

  const {
    data: hcpDetail,
    refetch: refetchHcpDetails,
    isLoading: isHcpDetailsLoading,
  } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  return (
    <Card className="mb-3">
      <Card.Body className="p-3">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="d-flex flex-wrap align-items-center">
            <div className="position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
              {isHcpDetailsLoading ? (
                <Skeleton height={80} width={80} variant="circular" />
              ) : (
                <Image
                  className="theme-color-default-img  img-fluid rounded-pill avatar-100"
                  src={avatars11}
                  alt="profile-pic"
                />
              )}

              {/* <Image
              className="theme-color-default-img  img-fluid rounded-pill avatar-60"
              src={
                "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?q=10&h=300"
              }
              alt="profile-pic"
            /> */}
            </div>
            {isHcpDetailsLoading ? (
              <Skeleton width={150} />
            ) : (
              (hcpDetail?.hcp_first_name ||
                hcpDetail?.hcp_to_primary_speciality) && (
                <div className="d-flex flex-wrap flex-column align-items-start mb-3 mb-sm-0">
                  {
                    <h4 className="me-2 mb-0 fs-5">
                      {hcpDetail?.hcp_first_name +
                        " " +
                        hcpDetail.hcp_middle_name +
                        " " +
                        hcpDetail?.hcp_last_name}
                    </h4>
                  }
                  {hcpDetail?.hcp_to_primary_speciality && (
                    <span>
                      {hcpDetail?.hcp_to_primary_speciality?.speciality_name}
                    </span>
                  )}
                </div>
              )
            )}
          </div>
          {/* <div className="d-flex flex-column align-items-center">
          <div className="d-flex flex-row gap-3">
            <div className="text-center bg-soft-primary rounded-3 px-3 py-2">
              <h5 className="mb-1">4</h5>
              <p className="m-0 fs-7">Annual Login</p>
            </div>
            <div className="text-center bg-soft-secondary rounded-3 px-3 py-2">
              <h5 className="mb-1">35 Mins</h5>
              <p className="m-0 fs-7">Per Session</p>
            </div>
            <div className="text-center bg-soft-success rounded-3 px-3 py-2">
              <h5 className="mb-1">24.05.23</h5>
              <p className="m-0 fs-7">Last Click</p>
            </div>
          </div>
        </div> */}
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(ProfileLogo);
