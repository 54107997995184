import React from "react";
import NotFound from "../assets/images/404.jpg";
import { Col, Container, Row } from "react-bootstrap";
import useRedirect from "../components/custom-hooks/use-redirect";
const PageNotFound = ({
  title = "The content you are looking for is not available.",
  message = "In the meantime, you can explore trending contents or",
  backgroundImage,
  onFallbackClick,
  buttonText = "Go To Dashboard",
}) => {
  const { redirectTo } = useRedirect();
  const fallbackClick = () => {
    if (onFallbackClick) {
      onFallbackClick();
    } else {
      redirectTo("dashboard");
    }
  };
  return (
    <>
      <div className="w-100 float-start">
        <div className="container-fluid">
          <section className="w-100 float-start cmnPageContent">
            <div className="w-100 float-start ">
              <div className=" d-flex justify-content-center align-items-center flex-column position-relative overflow-hidden mb-5">
                {backgroundImage ? (
                  <img
                    src={backgroundImage}
                    alt={title}
                    height={"500"}
                    width={"500"}
                  ></img>
                ) : (
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 3000 2000"
                    enableBackground="new 0 0 3000 2000"
                    xmlSpace="preserve"
                    className="unavailableIcon w-50"
                  >
                    <g>
                      <path
                        fill="#EBF3FA"
                        d="M2573.27,1840.87c-148.19,0-2151.66-4.32-2151.66-4.56c-0.83-1.94-254.33-312.65,64.88-637.5   c158.17-160.96-145.25-369.49,0-548.54c248.81-306.7,494.99-133.97,700.07-220.23c36.24-15.23,72.6-39.73,109-77.34   c20.49-21.15,42.94-39.64,66.98-55.61c3.69-2.46,7.41-4.86,11.17-7.19c14.39-8.93,29.32-17,44.69-24.26   c10.33-4.87,20.84-9.37,31.56-13.51h0.01c17-6.57,34.47-12.24,52.31-17.03c25.33-6.81,51.42-11.88,78.02-15.31   c11.6-1.51,23.3-2.69,35.08-3.58c229.16-17.23,487.04,79.67,620.13,221.3c26.79,28.52,148.63,176.08,52.59,378.91   c-60.64,128.06,193.4,250.36,283.67,396.6C2801.28,1584.81,2575.35,1836.02,2573.27,1840.87z"
                      />
                      <g opacity="0.8">
                        <path
                          fill="#D7E5FF"
                          d="M2054.76,976.46c-6.12,16.82-13.62,32.76-22.35,47.7c-20.95,35.86-48.89,66.01-81.39,89.32    c-75.51,54.18-175.6,71.37-269.54,37.22c-111.75-40.62-183.53-142.5-191.17-253.95c-1.02-14.69-0.92-29.54,0.37-44.44    c2.12-25.02,7.56-50.18,16.54-74.89c7.27-19.99,16.5-38.71,27.39-56c0.12-0.2,0.25-0.39,0.37-0.59    c30.24-47.76,73.19-84.63,122.59-107.74c14.01-6.56,28.55-12.01,43.47-16.28c7.11-2.04,14.33-3.81,21.59-5.29    c50.97-10.49,105.38-7.44,157.89,11.65c6.96,2.53,13.77,5.3,20.41,8.3c9.38,4.21,18.43,8.88,27.14,13.96    c36.69,21.39,67.39,50.1,90.93,83.52l0.01,0c14.67,20.81,26.54,43.43,35.33,67.27c4.62,12.52,8.39,25.36,11.26,38.45    c1.79,8.15,3.23,16.37,4.32,24.69c2.62,19.97,3.16,40.34,1.52,60.82C2069.39,925.64,2063.92,951.28,2054.76,976.46z"
                        />
                        <g>
                          <path
                            fill="#AEC3FF"
                            d="M1732.53,720.58c-17.21,13.33-12.65,49-42.45,28.9c-29.8-20.11-48.5-31.04-56.94-13.49     c-8.45,17.55,17.96,27.15,11.01,46.27c-6.95,19.12-21.09,21.14-35.99,5.44c-14.92-15.7-15.35-30.81-39.49-30.31     c-24.15,0.5-34.45-9.43-33.27-27.56c0.2-3.19-0.12-5.95-0.79-8.41c0.12-0.2,0.25-0.39,0.37-0.59     c30.24-47.76,73.19-84.63,122.59-107.74c14.01-6.56,28.55-12.01,43.47-16.28c7.11-2.04,14.33-3.81,21.59-5.29     c8.42,3.42,17.45,10.36,22.23,24.28c10.51,30.63,27.56,23.42,13.08,49.08c-14.49,25.65-8.52,31.95,7.28,36.66     c15.81,4.71,34.02,11.33,31.54,23.83C1794.27,737.88,1749.75,707.25,1732.53,720.58z"
                          />
                          <path
                            fill="#AEC3FF"
                            d="M2054.76,976.46c-6.12,16.82-13.62,32.76-22.35,47.7c-20.95,35.86-48.89,66.01-81.39,89.32     c-8.1-0.25-15.47-3-21.68-8.54c-29.18-26.07,9.49-53.75,29.85-67.23c20.36-13.47-17.44-17.94-36.5-44.19     c-19.06-26.25,25.57-61.83-3.11-63.75c-28.68-1.92-63.56-3.78-69.4-34.5c-5.84-30.72,24.33-24.4,19.05-50.28     c-5.28-25.88,19.67-30.73,46.36-23.34c26.69,7.38,29.98-12.29,63.06-3.36c33.08,8.93,17.51,49.65,54.01,59.83     c36.5,10.18,31.92,17.79,31.92,17.79c2.58,0.86,4.87,2.3,6.85,4.25C2069.39,925.64,2063.92,951.28,2054.76,976.46z"
                          />
                          <path
                            fill="#AEC3FF"
                            d="M2069.93,839.35c-34.75,3.63-32.4-39.75-61.9-37.17c-29.75,2.59-17.02-18.56-31.74-40.4     c-14.74-21.84-64.39-10-54.3-32.11c10.09-22.09,37.82-4.81,50.81-20.69c12.99-15.89,40.47-0.75,40.47-0.75     c1.88-0.03,3.8,0.21,5.74,0.72c0,0,0-0.01,0.01,0c14.67,20.81,26.54,43.43,35.33,67.27c4.62,12.52,8.39,25.36,11.26,38.45     C2067.39,822.81,2068.84,831.03,2069.93,839.35z"
                          />
                          <path
                            fill="#AEC3FF"
                            d="M1849.72,670.41c0,0,23.59-16.7,1.41-32.23c-22.19-15.52-24.87-6.73-43.24-15.72     c-18.38-8.99-31.27,3.08-22.8,24.45c8.46,21.37,8.8,56.6,27.78,44.79C1831.85,679.89,1833.89,666.46,1849.72,670.41z"
                          />
                        </g>
                      </g>
                      <path
                        fill="#A8D29F"
                        d="M487.77,1837.14h101.6c5.44-4.62-3.77-15.11-13.39-15.81c-4.18-0.32-9.47,0.56-15.51,1.83   c-1.4-18.57-9.38-102.05-39.47-98.69c-33.83,3.76,0,36.76,9.56,56.3c7.09,14.47,14.56,35.23,17.97,45.03   c-2.75,0.59-5.59,1.18-8.5,1.71c-4.48-13.77-21.17-60.08-42.39-57.38c-24.91,3.16-14.9,21.73,2.76,32.75   c12.95,8.11,28.86,19.63,36.42,25.19c-6.4,1.05-13.09,1.75-19.87,1.59C499.56,1829.25,491.07,1834.38,487.77,1837.14z"
                      />
                      <path
                        fill="#A8D29F"
                        d="M2104.67,1837.14h-94.37c-5.06-4.29,3.5-14.03,12.44-14.69c3.88-0.29,8.79,0.52,14.4,1.7   c1.3-17.25,8.71-94.79,36.66-91.67c31.43,3.49,0,34.15-8.88,52.3c-6.58,13.44-13.52,32.72-16.7,41.82   c2.55,0.55,5.19,1.09,7.89,1.59c4.17-12.79,19.66-55.81,39.37-53.3c23.14,2.94,13.84,20.18-2.56,30.42   c-12.03,7.53-26.81,18.23-33.83,23.4c5.95,0.97,12.16,1.63,18.46,1.48C2093.73,1829.81,2101.62,1834.58,2104.67,1837.14z"
                      />
                      <g>
                        <path
                          fill="#FFB27D"
                          d="M2172.07,1218.56c0,0,2.25-22.34-9.43-21.57c-11.68,0.76-14.78,6.12-13.47,10.42    c1.3,4.29,12.67,18.5,12.67,18.5L2172.07,1218.56z"
                        />
                        <path
                          fill="#739AF0"
                          d="M2354.1,1263.32c0,0-12.93,15.2-40.74,32.73c-2.63,1.67-5.39,3.34-8.29,5.03    c-10.8,6.29-23.47,12.74-38.1,18.75c-1.61,0.66-3.5,1.39-5.63,2.16c-16.67,6.02-47.33,14.55-66.33,15.32    c-1.65,0.07-3.22,0.07-4.68,0.01c-28.92-1.17-32.45-113.42-32.45-113.42s3.22-11.23,18.08-8.4c0,0,22.57,63.4,30.65,68.57    c7.18,4.59,48.47-57.99,84.01-62.37C2314.54,1218.73,2354.1,1263.32,2354.1,1263.32z"
                        />
                        <path
                          fill="#1C3154"
                          d="M2276.39,1810.74c0,0,2.14,24.71-3.09,27.28l-66.85-0.14c0,0,0-7.72,10.74-9.43    c10.74-1.71,29.6-8,32.79-22C2253.18,1792.45,2276.39,1810.74,2276.39,1810.74z"
                        />
                        <path
                          fill="#2A4E96"
                          d="M2227.32,1511.66c0.33,16.16,1.11,32.83,2.63,47.81c7.22,71.08,9.76,156.96,10.8,173.52    c1.8,28.74,7.48,74.66,7.48,74.66s11.26,10.69,33.21,5.59c0,0,18.19-66.13,22.25-135.77c0.06-1.14,0.12-2.29,0.18-3.44    c0.47-9.21,0.96-19.73,1.47-31.08c3.34-76.03,6.98-188.98,6.98-188.98l-83.89-19.7    C2228.42,1434.25,2226.49,1471.38,2227.32,1511.66z"
                        />
                        <path
                          fill="#233862"
                          d="M2227.32,1511.66c21.26,36.68,61.78,104.26,78.02,131.27c3.34-76.03,6.98-188.98,6.98-188.98l-83.89-19.7    C2228.42,1434.25,2226.49,1471.38,2227.32,1511.66z"
                        />
                        <path
                          fill="#6385DB"
                          d="M2261.33,1321.98c-16.67,6.02-47.33,14.55-66.33,15.32c2.91-0.54,24.07-4.74,31.89-14.18    c8.4-10.16,22.55-37.74,33.81-26.48C2266,1301.93,2264.45,1312.4,2261.33,1321.98z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M2402.29,1499.71c0,0,5.21,20.42,4.35,24.96c-0.85,4.54-5.94,19.9-9.11,23.72s-3.3-16.57-5.41-19.78    c-2.11-3.2-0.97,7.61-2.66,8.5s-4.51-8.19-5.76-11.41s4.5-14.42,5.04-22.45S2402.29,1499.71,2402.29,1499.71z"
                        />
                        <path
                          fill="#1C3154"
                          d="M2442.92,1782.66c0,0,11.9,10.86,9.58,18.57c-2.32,7.72-13.06,12.86-14.51,22.29s-7.25,14.57-12.48,14.57    s-24.9,0-24.9,0s-3.85-6.64,5.44-8.64c9.29-2,12.79-13.08,12.21-23.37c-0.58-10.29,0.87-24,0.87-24l19.73-4.57L2442.92,1782.66z"
                        />
                        <path
                          fill="#2A4E96"
                          d="M2241.64,1455.67c0,0,78.53,168.02,120.32,241.74c41.79,73.72,54.85,94.3,54.85,94.3    s23.94-9.43,26.12-15.86c0,0-15.98-142.33-43.97-177.02c-15.81-19.6-34.39-78.44-35.26-99.01c-0.87-20.57-19.22-69.68-19.22-69.68    L2241.64,1455.67z"
                        />
                        <path
                          fill="#739AF0"
                          d="M2227.01,1446.93c0,0,25.57,27.52,69.02,35.83c19.07,3.65,57.78,7.18,67.38-6.4    c2.25-3.19,2.27-7.91,1.77-15.28c-1.29-19.15-6.11-56.18,15.64-130.59c2.03-6.92,3.39-13.56,4.18-19.89    c4.75-38.07,4.23-66.8-28.79-82.15c-49.49-23-69.8-4.08-69.8-4.08s-26.32,12.3-38.35,48.14    C2224.02,1344.21,2227.01,1446.93,2227.01,1446.93z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M2326.5,1222.13c-8.33,5.92-22.99,9.41-36.96,2.42c-1.08-0.54-0.94-7.93-0.67-15.19    c0.27-7.12,0.94-14.12,0.94-14.12l17.61-11.56l16.8-11.02C2322.2,1210.57,2330.26,1219.44,2326.5,1222.13z"
                        />
                        <path
                          fill="#ED985F"
                          d="M2321.26,1188.31c0,0-11.43,17.15-32.39,21.05c0.27-7.12,1.03-14.97,1.03-14.97l17.52-10.7    L2321.26,1188.31z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M2274.03,1190.79c0,0,42.6,15.9,52.08-10.35c9.48-26.25,21.36-41.87-5.44-53.13    c-26.8-11.26-35.73-3.14-41.06,4.85C2274.29,1140.15,2263.2,1185.98,2274.03,1190.79z"
                        />
                        <path
                          fill="#2C3A64"
                          d="M2352.65,1130.26c0.49-0.38,1.48-0.57,3.56-0.27c0,0-1.44-1.35-3.62-1.84c0.13-2.94-0.39-8.29-5.05-10.48    c0,0,2.36,1.93,2.11,5.03c-4.53-6.96-15.88-13.62-50.14-17.62c-45.16-5.27-36.13,26.09-31.04,30.62    c5.09,4.53,21.41-1.91,31.97,1.06c10.56,2.97,9.54,18.87,12.75,21.71c3.22,2.85,2.53-1.2,7.89-2.45s4.22,7,1.75,12.02    c-2.47,5.02,2.91,13.36,2.91,13.36s0,0,8.29-6.01c8.29-6.01,20.89-35.92,19.1-43.18    C2352.97,1131.56,2352.81,1130.91,2352.65,1130.26z"
                        />
                        <path
                          fill="#6385DB"
                          d="M2365.18,1461.08c-1.29-19.15-6.11-56.18,15.64-130.59c2.03-6.92,3.39-13.56,4.18-19.89l0,0    c-16.16-5.33-45.6,6.52-45.6,6.52S2332.47,1415.49,2365.18,1461.08z"
                        />
                        <path
                          fill="#739AF0"
                          d="M2365.12,1233.43c0,0,19.88,7.23,32.57,59.88c8.38,34.78,23.81,85.91,25.55,101.98    c1.74,16.07-17.64,110.13-17.64,110.13s-2.73,1.99-9.43,0.65c-6.41-1.28-9.39-5.62-9.39-5.62s-4.63-62.69-3.44-92.29    c0.5-12.3-56.93-90.07-87.93-100.09C2283.24,1304.13,2365.12,1233.43,2365.12,1233.43z"
                        />
                        <path
                          fill="#AEC3FF"
                          d="M2086.99,1165.28c0,0,57.47-1.63,123.79,10.58l6.93-17.46l7.6-19.13c0,0-66.58-28.66-106.6-68.58    L2086.99,1165.28z"
                        />
                        <path
                          fill="#F99746"
                          d="M2119.62,1069.75l-33,97.4l0,0c-4.83-1.43-7.46-6.61-5.78-11.35l29.04-81.84    C2111.29,1070,2115.77,1068.06,2119.62,1069.75L2119.62,1069.75z"
                        />
                        <path
                          fill="#F99746"
                          d="M2151.12,1237.77L2151.12,1237.77c6.42,1.28,12.71-2.92,14-9.33l11.36-56.85c1.28-6.42-2.92-12.71-9.33-14    l0,0c-6.42-1.28-12.71,2.92-14,9.33l-11.36,56.85C2140.5,1230.19,2144.7,1236.49,2151.12,1237.77z"
                        />
                        <path
                          fill="#739AF0"
                          d="M2086.99,1165.28c0,0,57.47-1.63,123.79,10.58l6.93-17.46C2155.4,1145.67,2086.99,1165.28,2086.99,1165.28    z"
                        />
                        <path
                          fill="#F99746"
                          d="M2192.45,1172.34l17.96,3.16c-0.27,0.01,16.68,3.3,22.24-11.73c0.51-1.03,0.84-2.04,1.07-3.24    c4.32-18.73-5.96-22.18-5.96-22.18l-23.11-9.01l-7.34,25.99c0,0,0,0,0,0.09L2192.45,1172.34z"
                        />
                        <path
                          fill="#ED7D2B"
                          d="M2192.45,1172.34l17.96,3.16c-0.27,0.01,16.68,3.3,22.24-11.73c-10.16-7.39-35.35-8.45-35.35-8.45    s0,0,0,0.09L2192.45,1172.34z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M2152.65,1182.73c0,0,8.79,3.5,7.17,7.37c-1.61,3.87-12.78,9.19-14.54,6.14    C2143.52,1193.18,2147.12,1179.46,2152.65,1182.73z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M2172.24,1215.04c0,0,1.66-8.99,1.76-13.12c0.1-4.13,0.59-7.5-0.38-7.62c-0.97-0.12-1.68,0.64-2.07,1.97    c-0.4,1.33-1.92,9.56-1.92,9.56l0.77,7.95L2172.24,1215.04z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#7599EA"
                          d="M1303.31,1209.17l-49.77,591.8c0,0-5.21,35.87,21.47,35.94c43.87,0.1,218.38,0,218.38,0    s-33.02-7.47-32.62-24.27c0.47-19.8,58.17-609.07,58.17-609.07L1303.31,1209.17z"
                        />
                        <path
                          fill="#90AFF4"
                          d="M1509.81,1826.41c-8.63,0.03-177.57,0.31-220.3-0.24c-18.62-0.23-21.89-16.56-22.04-27.12    c-0.08-5.06,0.57-8.81,0.57-8.81l1.29-15.38l3.3-39.2l2.91-34.64l3.75-44.51l3.15-37.57l35.37-420.52l215.63-5.6    c0,0-24.3,246.61-41.49,426.11c-1.92,20-3.74,39.18-5.43,57.08c-0.55,5.72-1.07,11.3-1.59,16.74    c-1.59,16.98-3.05,32.58-4.32,46.35c-0.67,7.26-1.29,14-1.84,20.18c-2.19,24.21-3.48,39.67-3.5,42.61    c-0.01,1.71,0.14,3.31,0.42,4.79C1479.23,1825.56,1506.57,1826.41,1509.81,1826.41z"
                        />
                        <polygon
                          fill="#AEC3FF"
                          points="779.65,887.13 900.89,1374.12 917.49,1440.81 973.95,1667.63 2023,1667.63 1814.88,887.13   "
                        />
                        <polygon
                          fill="#90AFF4"
                          points="779.65,887.13 762.85,892.73 951.62,1684.57 973.96,1667.63   "
                        />
                        <polygon
                          fill="#7599EA"
                          points="951.62,1684.57 1994.21,1684.57 2023,1667.63 973.96,1667.63   "
                        />

                        <rect
                          x="1317.74"
                          y="1826.41"
                          transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 2861.2231 3663.5508)"
                          fill="#AEC3FF"
                          width="225.74"
                          height="10.73"
                        />
                        <polygon
                          fill="#5888DD"
                          points="1486.1,1684.57 1484.93,1692.77 1272.64,1735.66 1275.55,1701.02 1276.94,1684.57   "
                        />
                        <path
                          opacity="0.4"
                          fill="#5888DD"
                          d="M1509.81,1826.41c-8.63,0.03-177.57,0.31-220.3-0.24c-18.62-0.23-21.89-16.56-22.04-27.12    l211.29-39.75c-2.19,24.21-3.48,39.67-3.5,42.61c-0.01,1.71,0.14,3.31,0.42,4.79C1479.23,1825.56,1506.57,1826.41,1509.81,1826.41    z"
                        />
                        <polygon
                          fill="#AEC3FF"
                          points="779.65,887.13 1814.88,887.13 1982.84,1514.41 935.81,1514.41   "
                        />
                        <polygon
                          fill="#739AF0"
                          points="1934.02,1487.22 964.73,1487.22 930.09,1349.24 916.69,1295.85 911.23,1274.08 875.63,1132.28     873.78,1124.9 854.97,1049.94 846.55,1016.41 839.37,987.8 822.53,920.74 1777.84,920.74 1796.33,987.8 1804.22,1016.41     1813.46,1049.94 1874.37,1270.89 1874.37,1270.9 1879.53,1289.62 1881.93,1298.31 1923.99,1450.84   "
                        />
                        <polygon
                          fill="#607CF4"
                          points="1796.33,987.8 839.37,987.8 822.53,920.73 1777.85,920.73   "
                        />
                        <g>
                          <path
                            fill="#E9F3FE"
                            d="M862.05,951.04c-1.15-4.63,1.66-8.38,6.29-8.38c4.63,0,9.32,3.75,10.47,8.38     c1.15,4.63-1.66,8.38-6.29,8.38C867.9,959.43,863.21,955.67,862.05,951.04z"
                          />
                          <path
                            fill="#E9F3FE"
                            d="M889.91,951.04c-1.15-4.63,1.66-8.38,6.29-8.38c4.63,0,9.32,3.75,10.47,8.38     c1.15,4.63-1.66,8.38-6.29,8.38C895.76,959.43,891.07,955.67,889.91,951.04z"
                          />
                          <path
                            fill="#E9F3FE"
                            d="M919.91,951.04c-1.15-4.63,1.66-8.38,6.29-8.38c4.63,0,9.32,3.75,10.47,8.38     c1.15,4.63-1.66,8.38-6.29,8.38C925.75,959.43,921.06,955.67,919.91,951.04z"
                          />
                        </g>
                      </g>
                      <path
                        fill="#F77D48"
                        d="M1587.81,530.83c39.18-46,99.59-138.4,140.26-185.71c8.37-9.74,1.41-24.77-11.43-24.77h-74.01l69.81-157.86   c2.98-6.74-6.2-12.11-10.62-6.21l-168.8,225.92c-4.92,6.59,0.46,15.87,8.63,14.86l83.79-10.32l-49.85,136.94   C1572.85,531.22,1582.61,536.93,1587.81,530.83z"
                      />
                      <path
                        fill="#AEC3FF"
                        d="M1239.36,810.99c8.81-45.21,14.38-129.17,23.69-175.81c1.92-9.6-8.33-16.98-16.86-12.18l-49.16,27.68   l-12.68-130.96c-0.54-5.59-8.65-5.72-9.37-0.15l-27.61,213.19c-0.8,6.22,6.24,10.37,11.29,6.64l51.79-38.19l18.11,109.6   C1229.57,816.85,1238.19,816.99,1239.36,810.99z"
                      />
                      <g>
                        <path
                          fill="#E6EFFF"
                          d="M1976.36,1500.58l-842.23,70.96c-63.95,5.39-110.23-59.81-84.05-118.4l356.82-798.57    c27.07-60.58,110.28-67.59,147.1-12.39l485.41,727.61C2075.03,1423.17,2040.31,1495.19,1976.36,1500.58z"
                        />
                        <g>
                          <path
                            fill="#F77D48"
                            d="M1979.04,1532.43l-842.23,70.96c-41.42,3.49-80.52-14.66-104.58-48.56     c-24.06-33.9-28.3-76.79-11.34-114.74l356.82-798.57c17.53-39.24,53.57-64.82,96.4-68.43c42.83-3.61,82.64,15.58,106.49,51.34     l485.41,727.61c23.07,34.58,26.07,77.58,8.02,115.02C2055.97,1504.5,2020.46,1528.94,1979.04,1532.43z M1479.48,636.8     c-19.28,1.62-35.5,13.14-43.39,30.8l-356.82,798.57c-7.63,17.08-5.72,36.39,5.11,51.64c10.83,15.26,28.43,23.43,47.07,21.86     l842.23-70.96c18.64-1.57,34.63-12.57,42.75-29.42c8.12-16.85,6.78-36.21-3.61-51.77l-485.41-727.61     C1516.67,643.82,1498.75,635.18,1479.48,636.8z"
                          />
                        </g>
                        <g>
                          <g>
                            <path
                              fill="#F77D48"
                              d="M1459.74,1378.4c-0.91-10.85,0.17-21.05,3.28-30.59c3.1-9.53,7.9-17.97,14.41-25.31      c6.51-7.33,14.57-13.32,24.2-17.94c9.62-4.61,20.67-7.46,33.14-8.51c12.46-1.05,23.92-0.1,34.34,2.82      c10.43,2.93,19.47,7.48,27.11,13.62c7.65,6.15,13.79,13.67,18.44,22.54c4.65,8.89,7.43,18.75,8.35,29.61      c0.91,10.85-0.19,20.97-3.3,30.34c-3.11,9.38-7.92,17.73-14.43,25.07c-6.51,7.34-14.66,13.33-24.45,17.96      c-9.79,4.63-20.92,7.48-33.39,8.53c-12.47,1.05-23.84,0.1-34.09-2.84c-10.26-2.94-19.21-7.49-26.86-13.64      c-7.64-6.14-13.79-13.57-18.42-22.3C1463.43,1399.04,1460.66,1389.25,1459.74,1378.4z M1589.96,1226.03l-121.32,10.22      l-52.11-413.07l155.69-13.12L1589.96,1226.03z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <path
                          fill="#FFB27D"
                          d="M936.75,1251.36c0,0,2.64-5.94,4.35-8.75c1.71-2.81,9.02-11.05,10.26-9.19    c1.24,1.87,6.22,14.42,3.58,17.94c-2.64,3.52,0.17,9.63-12.97,13.34L936.75,1251.36z"
                        />
                        <path
                          fill="#ED7D2B"
                          d="M790.68,1213.86c0,0,67.29,56.17,93.52,50.97c20.52-4.07,52.95-14.35,52.95-14.35s8.56,8.14,6.14,17.9    c0,0-41.32,38.88-70.76,39.13c-46.02,0.39-67.72-22.8-67.72-22.8L790.68,1213.86z"
                        />
                        <path
                          fill="#113C68"
                          d="M672.67,1806.31c0,0,1.95,31.51,8.05,31.96c0.81,0.06,30.92,0.06,30.92,0.06l38.44-0.22    c0,0-0.25-9.26-11.49-11.22c-11.23-1.96-30.04-9.85-36.85-24.7C694.58,1786.56,672.67,1806.31,672.67,1806.31z"
                        />
                        <path
                          fill="#2A4E96"
                          d="M670.45,1808.49c0,0,11.68,3.91,20.81,3.91c10.18,0,19.42-2.77,19.42-2.77s2.2-21.27,14.3-68.14    c6.35-24.57,15.41-56.16,28.3-95.41c35.65-108.48,46.49-171.94,47.48-177.95c0.05-0.32,0.07-0.47,0.07-0.47l-0.12-0.05    l-109.61-48.12c0,0,13.55,149.51-3.74,206.9c-2.42,8.02-4.49,17.37-6.27,27.49C670.2,1716.2,670.45,1808.49,670.45,1808.49z"
                        />
                        <path
                          fill="#233862"
                          d="M724.99,1741.49c6.35-24.57,15.41-56.16,28.3-95.41c35.65-108.48,46.49-171.94,47.48-177.95    c-0.01-0.18-0.02-0.35-0.04-0.53l-109.61-48.12c0,0,13.55,149.51-3.74,206.9C684.97,1634.4,724.99,1741.49,724.99,1741.49z"
                        />
                        <path
                          fill="#15447C"
                          d="M758.28,1806.31c0,0,1.95,31.51,8.05,31.96c0.81,0.06,30.92,0.06,30.92,0.06l38.44-0.22    c0,0-0.25-9.26-11.49-11.22c-11.23-1.96-30.04-9.85-36.85-24.7C780.19,1786.56,758.28,1806.31,758.28,1806.31z"
                        />
                        <path
                          fill="#2A4E96"
                          d="M820.42,1423c0,0,1.42,96.88-15.34,215.84S794,1808.29,794,1808.29s-11.52,3.73-16.16,4.11    c-11.84,0.96-23.98-2.78-23.98-2.78s-23.99-119-17.11-178.54s-32.85-204.31-32.85-204.31L820.42,1423z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M742.7,1196.29l21.4,14.03l19.49-5.51c3.56-5.08,4.51-14.88,4.56-23.36c0.05-8.42-0.77-15.56-0.77-15.56    l-28.87-12.74l-10.77-4.75c0.03,0.17,0.84,8.84,0.95,10.11C750.7,1181,742.7,1196.29,742.7,1196.29z"
                        />
                        <path
                          fill="#F99746"
                          d="M820.91,1449.94c-26.63,25.71-127.41,21.39-133.62-6.94c-6.2-28.33,6.77-241.15,52.26-254.52    c9.44-2.77,59.91,13.54,59.91,13.54s7.92,20.72,13.11,47.09c1.01,5.13,3.95,59.52,6.15,110.4c1.03,23.8,1.9,46.83,2.34,63.7    C821.46,1439,821.48,1449.38,820.91,1449.94z"
                        />
                        <path
                          fill="#FCA56B"
                          d="M750.85,1158.9c5.86,9.9,26.4,18.57,37.29,22.54c0.05-8.42-0.77-15.56-0.77-15.56l-28.87-12.74    C752.13,1153.05,748.24,1154.48,750.85,1158.9z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M798.26,1164.12c0,0-46.51,14.51-55.1-14.59c-8.59-29.1-20.38-46.83,8.96-57.32    c29.33-10.49,38.41-1.1,43.64,7.92C800.98,1109.14,810.14,1159.6,798.26,1164.12z"
                        />
                        <path
                          fill="#163560"
                          d="M793.93,1097.32c0,0-2.97,33.87-9.97,32.4c-7-1.47-14.71-4.62-13.69,5.59    c1.03,10.21-6.81,27.71-16.91,27.67c-21.66-0.09-45.19-55.67-39.26-66.21C731.21,1066.36,818.66,1060.22,793.93,1097.32z"
                        />
                        <path
                          fill="#ED7D2B"
                          d="M787.09,1189.21c-0.6-0.25-34.62-15.51-38.6-14.2c-3.98,1.31-8.94,13.47-8.94,13.47l59.91,13.54    C799.45,1202.02,794.74,1192.43,787.09,1189.21z"
                        />
                        <g>
                          <path
                            fill="#1F4D84"
                            d="M998.9,1354.03L808.18,1533.6c-3.74,3.52-8.45,4.64-10.48,2.49l-6.44-6.84     c-2.02-2.15-0.62-6.79,3.12-10.31l190.72-179.57c3.74-3.52,8.45-4.64,10.48-2.49l6.44,6.84     C1004.04,1345.87,1002.64,1350.51,998.9,1354.03z"
                          />
                          <path
                            fill="#739AF0"
                            d="M891.87,1454.8l-83.69,78.8c-3.74,3.52-8.45,4.64-10.48,2.49l-6.44-6.84c-2.02-2.15-0.62-6.79,3.12-10.31     l83.69-78.8L891.87,1454.8z"
                          />
                          <path
                            fill="#1F4D84"
                            d="M955.07,1286.23c7.32,24.13,20.88,46.73,40.57,65.25c60.13,56.57,154.74,53.68,211.31-6.44     c55.82-59.34,53.75-152.24-4.11-209.04c-0.77-0.76-1.55-1.52-2.35-2.26c-60.12-56.57-154.72-53.7-211.29,6.43     C951.15,1180.62,939.99,1236.67,955.07,1286.23z M997.79,1148.27c32.92-34.99,79.83-48.94,123.88-41.31     c25.67,4.44,50.35,16.2,70.74,35.38c55.38,52.1,58.03,139.23,5.93,194.62c-19.18,20.39-43.1,33.62-68.44,39.63     c-43.51,10.3-91.19-0.75-126.18-33.68C948.34,1290.79,945.68,1203.66,997.79,1148.27z"
                          />
                          <g opacity="0.5">
                            <path
                              fill="#B3CFF9"
                              d="M1198.35,1336.96c-19.18,20.39-43.11,33.62-68.44,39.63c-43.51,10.29-91.18-0.76-126.18-33.69      c-55.38-52.1-58.04-139.24-5.93-194.63c32.92-34.99,79.83-48.94,123.88-41.31c25.67,4.44,50.36,16.2,70.74,35.38      C1247.79,1194.44,1250.45,1281.57,1198.35,1336.96z"
                            />
                          </g>
                        </g>
                        <path
                          fill="#ED7D2B"
                          d="M821.06,1423.2c-105.09-74.88-96.35-174.81-96.35-174.81c38.41,21.66,76.52,69.56,94.01,111.11    C819.74,1383.3,820.61,1406.33,821.06,1423.2z"
                        />
                        <g>
                          <path
                            fill="#FFB27D"
                            d="M924.78,1389.1c7.41-1.71,12.39-7.31,13.42-8.29c0,0,3.16,0.22-0.52,6.96c-0.31,0.56-0.99,2.01-1.51,3.04     c-0.21,0.42,0.08,0.91,0.55,0.94c4.28,0.33,8.58-1.37,16.81,0.35c1.14,0.24,1.47,2.96,1.42,4.12c-0.07,1.96,0.07,4.17-2.21,7.18     c-1.33,1.75-5.03,3.63-13.28,6.95c-3.14,1.26-12.13,0.31-20.55-2.58l-6.73-3.12l2.09-12.5l1.69,0.35     C918.65,1393.06,921.98,1389.78,924.78,1389.1z"
                          />
                          <path
                            fill="#F99746"
                            d="M757.32,1202.02c0,0-94.42,17.98,27.78,154.72c19.54,21.87,125.14,50.34,125.14,50.34     s10.71-9.11,5.31-16.49c0,0-72.71-52.8-88.7-61.19c-7.2-3.78-27.93-84.27-39.66-103.65     C775.46,1206.36,757.32,1202.02,757.32,1202.02z"
                          />
                        </g>
                        <path
                          fill="#FFB27D"
                          d="M939.31,1253.02c0,0,3.09-5.9,3.69-9.02c0.6-3.11,4.49-12.19,6.09-13.79c0.64-0.64,2.76,0.19,3.01,2.94    c0.11,1.2-0.48,3.38-1.52,5.97c-1.69,4.23-1.41,9.96-2,11.87C947.12,1255.75,939.31,1253.02,939.31,1253.02z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            fill="#FFB27D"
                            d="M976.89,1018.74c-1.61,1.84-1.81,3.89-1.02,6.09c0,0.01,0.01,0.02,0.02,0.03     c1.47,4.06,6.32,8.6,11.96,13.13c0.72,0.58,1.36,1.11,1.92,1.6c0.15,0.13,0.29,0.26,0.43,0.39c0.11,0.1,0.22,0.2,0.33,0.3     c5.26,4.85,3.28,5.96,12.72,15.8c10.77,11.21,18.18,11.42,20.29,10.39c1.79-0.87-1.84-6.81-5.86-13.5     c-0.19-0.32-0.39-0.65-0.58-0.97c-0.54-0.89-1.07-1.79-1.6-2.68c-4.71-8.04-3.5-11.81-2.38-23.78     c0.91-9.72,15.15-54.32,15.15-54.32l-20.64-3.56c0,0-6.23,35.51-13.08,40.98c-3.66,2.91-7.76,2.95-11.28,5.11     c-0.25,0.15-0.49,0.3-0.73,0.46C980.54,1015.53,978.5,1016.9,976.89,1018.74z"
                          />
                          <path
                            fill="#233862"
                            d="M976.89,1018.74c-1.61,1.84-1.81,3.89-1.02,6.09c0,0.01,0.01,0.02,0.02,0.03     c1.8,4.06,6.53,8.57,11.98,13.09c0.71,0.59,1.34,1.13,1.9,1.64c0.15,0.13,0.29,0.26,0.43,0.39c0.11,0.1,0.22,0.2,0.33,0.3     c5.26,4.85,3.28,5.96,12.72,15.8c10.77,11.21,18.18,11.42,20.29,10.39c1.79-0.87-1.84-6.81-5.86-13.5     c-2.1-0.77-11.84-4.73-19.2-14.16c-6.25-7.98-10.49-18.14-14.74-24.39c-0.47-0.09-0.75-0.14-1.21-0.23     C980.54,1015.53,978.5,1016.9,976.89,1018.74z"
                          />
                          <path
                            fill="#FFB27D"
                            d="M1143.34,1005.91c1.27,6.37,10.36,5.31,20.5,2.84c10.15-2.48,7.74,0.47,22.18-1.12     c14.44-1.59,18.82-6.98,19.26-9.12c0.4-1.91-6.74-2.58-14.39-3.46c-0.96-0.11-1.93-0.23-2.9-0.35     c-8.64-1.09-14.97-6.67-22.82-11.25c-7.43-4.34-18.52-22.02-18.52-22.02l-19.81,1.56c0,0,10.39,15.79,14.41,22.65     c2.36,4.04,2.24,9.38,1.94,13.5C1143.04,1001.37,1142.89,1003.66,1143.34,1005.91z"
                          />
                          <path
                            fill="#233862"
                            d="M1163.84,1008.75c10.15-2.48,7.74,0.47,22.18-1.12c14.44-1.59,18.82-6.98,19.26-9.12     c0.4-1.91-6.74-2.58-14.39-3.46l-0.07,0.15c0,0-8.66,6.14-20.68,6.42c-9.8,0.24-20.17-2.58-26.94-2.47     c-0.15,2.24-0.3,4.53,0.15,6.78C1144.61,1012.28,1153.7,1011.22,1163.84,1008.75z"
                          />
                          <path
                            fill="#233862"
                            d="M1067.48,879.24l-18.53,49.81l-24.19,65.04c0,0-6.56,0.63-12.84-1.18c-5.78-1.67-12.42-6.05-12.42-6.05     s6.74-69.85,19.27-109.94c3.21-10.29,6.81-18.61,10.78-23.3C1048.97,830.7,1067.48,879.24,1067.48,879.24z"
                          />
                          <path
                            fill="#2A4E96"
                            d="M1048.69,800.11c-10.54-3.4-38.04-0.07-67.87,5.32c-8.24,1.49-16.55-2.28-20.89-9.44l0,0l-75.06-5.89     c0,0-24.79,54.92-11.24,89.93c1.99,5.14,6.36,8.36,13.12,8.47c0,0,0,0,0,0c0,0,45.36,0.08,59.87,0.08     c37.33,0.01,79.01-25.63,84.4-26.71c8.82-1.76,12.35,15.89,21.17,34.42c8.82,18.53,82.92,82.09,82.92,82.09s11.39,0.12,16.1-1.65     c5.06-1.9,6.91-4.36,6.91-4.36S1076.04,808.94,1048.69,800.11z"
                          />
                          <path
                            fill="#233862"
                            d="M952.03,575.56c-5.57-3.32-11.01-3.83-14.34-3.74c-2.32,0.07-4.63-0.09-6.92-0.42     c-28.65-4.21-45.91,37.4-46.93,49.97c-1.86,23.08-14.08,26.6-31.27,41.05c-34.48,28.98-24.38,62.66-5.54,75.19     c11.31,7.51,31.24,0.01,31.24,0.01c0.28,0.2,54.52-30.3,81.25-45C999.6,686.94,968.13,585.15,952.03,575.56z"
                          />
                          <path
                            fill="#FFB27D"
                            d="M937.13,680.08c0.12,0.08-13.15,4.6-22.02,0.67c-8.35-3.69-14.66-17.43-14.54-17.43     c3.8-0.17,7.97-3.49,11.18-25.27l1.62,0.58l27.18,9.79c0,0-1.97,8.72-3.25,17.03C936.22,672.42,935.64,679.1,937.13,680.08z"
                          />
                          <path
                            fill="#ED975D"
                            d="M940.55,648.42c0,0-1.09,4.71-2.67,13.5c-15.61-2.82-22.31-14.85-24.51-23.29L940.55,648.42z"
                          />
                          <path
                            fill="#FFB27D"
                            d="M944.59,657.08c0,0-37.19-5.01-33.72-28.12c3.47-23.11,1.01-39.33,24.91-37.11     c23.89,2.22,27.17,11.73,27.89,19.73C964.39,619.57,954.36,657.78,944.59,657.08z"
                          />
                          <path
                            fill="#233862"
                            d="M962.32,598.9c0,0-23.82,20.51-40.01,22.16c-16.2,1.65-22.33-3.55-22.33-3.55s9.55-7.92,15.3-20.71     c1.69-3.77,5.27-6.36,9.39-6.71C936.23,589.1,958.15,588.65,962.32,598.9z"
                          />
                          <path
                            fill="#233862"
                            d="M947.43,597.06c0,0,7.96,7.7,10.47,13.42c2.42,5.51,3.14,18.7,1.21,23.93c0,0,12.42-24.13,5.4-35.85     C957.06,586.12,947.43,597.06,947.43,597.06z"
                          />
                          <path
                            fill="#F99746"
                            d="M968.43,734.89c-1.15,75.29-0.33,59.56-8.57,66.7c-1.99,1.72-7.56,2.74-14.91,3.23     c-23.09,1.52-63.67-2.29-65.21-6.56c-5.32-14.77-1.56-19.35-5.99-41.95c-0.46-2.4-1.03-5.01-1.71-7.84     c-3.7-15.52-7.3-20.81,5.85-45.74c11.93-22.59,24.31-40.92,25.43-40.26c31.44,18.68,33.98,2.99,33.98,2.99     S968.99,698.16,968.43,734.89z"
                          />
                          <path
                            fill="#2A4E96"
                            d="M1048.95,929.05l-24.19,65.04c0,0-6.56,0.63-12.84-1.18c-5.78-1.67-12.42-6.05-12.42-6.05     s6.74-69.85,19.27-109.94L1048.95,929.05z"
                          />
                          <path
                            fill="#F99746"
                            d="M937.3,665.44c0,0,32.35,5.5,36.15,19.43c3.79,13.93,16.27,98.49,16.27,98.49s-9.13,8.91-16.72,1.31     c-7.6-7.6-23.89-79.12-23.89-79.12L937.3,665.44z"
                          />
                          <path
                            fill="#ED7D2B"
                            d="M944.96,804.81c-23.09,1.52-63.67-2.29-65.21-6.56c-5.32-14.77-1.56-19.35-5.99-41.95l11.15-31.35     c0,0-1.85,34.92,8.44,44.1c10.28,9.19,42.15,17.74,47.16,25.42C942.91,798.15,944.49,801.85,944.96,804.81z"
                          />
                          <path
                            fill="#FFB27D"
                            d="M918.51,790.12c0,0,22.78-5.06,26.45-3.04c3.67,2.02,12.65,9.39,13.78,11.95     c1.12,2.56-8.18-1.95-11.21-2.76c-1.96-0.53-7.12,1.26-9.68,2.12c-10.75,3.58-17.34,1.73-17.34,1.73L918.51,790.12z"
                          />
                          <path
                            fill="#F99746"
                            d="M903.3,662.44c0,0-30.58,1.87-41.18,16.64c-14.91,20.78-44.51,109.36-27.57,121.18     c19.08,13.31,86.84,3.51,86.84,3.51s2.26-10.54-0.88-15.64c-3.14-5.1-54.12-13.35-56.47-15.71     c-2.35-2.35,29.43-45.88,29.43-52.94C893.49,712.43,903.3,662.44,903.3,662.44z"
                          />
                          <path
                            fill="#FFB27D"
                            d="M946.06,793.42c0,0,4.77,6.16,6.33,6.89c1.56,0.74,2.58,0.55,2.58,0.55l-3.21-5.79L946.06,793.42z"
                          />
                          <path
                            fill="#FFB27D"
                            d="M944.96,787.08l13.87,6.74c0,0-0.59,0.97-2.3,0.89c-1.71-0.08-6.02-1.66-6.02-1.66L944.96,787.08z"
                          />
                        </g>
                        <polygon
                          fill="#233862"
                          points="1063.44,802.96 918.5,802.91 918.5,806.11 1062.15,806.16   "
                        />
                        <polygon
                          fill="#233862"
                          points="952.39,806.12 1062.15,806.16 1095.2,724.06 985.61,724.02   "
                        />
                        <path
                          fill="#FFFFFF"
                          d="M1034.46,765.09c0.83-2.89-0.84-5.23-3.73-5.23c-2.89,0-5.9,2.34-6.73,5.22    c-0.83,2.89,0.84,5.23,3.73,5.23C1030.62,770.32,1033.63,767.98,1034.46,765.09z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#163560"
                          d="M1777.27,1115.74c-14.79-4.91-25.02,1.29-25.02,1.29c-42.57,1.75-44.15,45.39-39.16,61.63    c7.02,22.81-17.82,40.79,9.73,50.02c31.96,10.71,58.78-2,56.95,3.52C1880.84,1240.16,1810.9,1126.9,1777.27,1115.74z"
                        />
                        <path
                          fill="#F99746"
                          d="M1733.12,1327.98c0,0-4.04,8.01-11.38,17.21c-8.84,11.08-55.26-15.21-51.26-17.21    c4-2,27.32-69.2,32.88-77.64c5.56-8.44,24.48-12.83,24.48-12.83L1733.12,1327.98z"
                        />
                        <path
                          fill="#1F447F"
                          d="M1889.76,1781.18c0,0,12.81,11.69,10.31,20c-2.5,8.31-14.06,13.85-15.62,24    c-1.56,10.15-7.81,15.69-13.44,15.69c-5.62,0-26.82,0-26.82,0s-4.14-7.15,5.86-9.3c10-2.15,13.77-14.08,13.14-25.16    s0.94-25.84,0.94-25.84l21.25-4.92L1889.76,1781.18z"
                        />
                        <path
                          fill="#1C3154"
                          d="M1781.32,1786.15c0,0,4.61,49.53-1.01,52.3l-71.98-0.15c0,0,0-8.31,11.56-10.15    c11.56-1.85,31.87-8.62,35.31-23.69S1781.32,1786.15,1781.32,1786.15z"
                        />
                        <path
                          fill="#F99746"
                          d="M1836.69,1294.57c0,0,1.07,15.12,0,33.27c-0.71,12.03-12.88,28.14-14.61,32.13    c-4.33,10-9.22,11.62-9.22,11.62l-37.79,9.26l-13.29,3.25l-41.91-12.52c0,0-19.09-53.27-11.42-83.26    c7.66-29.99,19.37-50.82,19.37-50.82s28.48-6.4,78.72-2.84L1836.69,1294.57z"
                        />
                        <path
                          fill="#ED7D2B"
                          d="M1840.29,1328.26c-0.71,12.03-16.48,27.72-18.21,31.71c-4.33,10-9.22,11.62-9.22,11.62l-37.79,9.26    l-6.82-19.56c0,0,55.22-14.51,46.34-65.62L1840.29,1328.26z"
                        />
                        <path
                          fill="#233862"
                          d="M1722.55,1379.67c0,0-20.75,64.59-22.09,86.59c-1.33,21.99,39.67,312.42,41.32,322.55    c1.55,9.55,13.42,15.64,13.42,15.64s5.46,3.22,13.24,1.02c10.8-3.05,14.22-6.7,14.22-6.7s16.15-207.13,6.16-281.12    c-10-73.99-10.11-137.98-10.11-137.98H1722.55z"
                        />
                        <path
                          fill="#2A4E96"
                          d="M1810.47,1377.75c0,0,31.66,42.47,34.61,71.19c2.95,28.71,4.63,129.56,10.92,157.55    s29.39,81.86,29.39,169.16c0,0-2.4,5-9.81,7.67c-2.37,0.85-5.55,1.68-8.63,2.39c-6.11,1.4-12.39-1.59-15.18-7.2    c-15.62-31.44-65.36-132.86-67.92-153.91c-1.8-14.9-17.51-113.5-51.78-171.35c-8.23-13.89,5.05-69.13,5.05-69.13L1810.47,1377.75z    "
                        />
                        <path
                          fill="#233862"
                          d="M1719.23,1369.7c0,0,8.33,6.59,24.88,6.92c19.77,0.4,68.75-5.04,68.75-5.04s2.18,6.08,2.09,12.5    c0,0-59.83,15.09-94.49,2.2C1720.46,1386.29,1715.99,1377.88,1719.23,1369.7z"
                        />
                        <g>
                          <path
                            fill="#FFFFFF"
                            d="M1739.37,1393.66c-4.97,0-9.02-4.58-9.02-10.21s4.05-10.21,9.02-10.21s9.02,4.58,9.02,10.21     S1744.35,1393.66,1739.37,1393.66z M1739.37,1376.23c-3.33,0-6.03,3.24-6.03,7.22s2.71,7.22,6.03,7.22s6.03-3.24,6.03-7.22     S1742.7,1376.23,1739.37,1376.23z"
                          />
                        </g>
                        <polygon
                          fill="#B5453C"
                          points="1782.59,1412.23 1692.67,1413.07 1648.43,1309.09 1738.35,1308.25   "
                        />
                        <path
                          fill="#FFB27D"
                          d="M1770.83,1365.24l-6.59-8.1c0,0-4.46-3.98-7.7-4.65c-3.24-0.68-10.07-4.62-10.73-3.51    c-0.66,1.11,5.25,9.03,5.25,9.03l0.3,0.04c-6.79,5.11-23.93,0.6-26.04,2.68c-2.56,2.52,3.53,7.98,6.8,11.05s12.62,8.17,19.91,5.91    c5.33-1.66,13.71-3.35,13.71-3.35L1770.83,1365.24z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M1655.19,1327.38c0,0,5.65-6.42,9.25-3.34c3.6,3.08-0.94,18.24-4.37,16.7S1653.22,1329.26,1655.19,1327.38    z"
                        />
                        <path
                          fill="#F99746"
                          d="M1806.55,1234.67c0,0,25.74,2.84,44.21,36.4c14.57,26.47,39.47,75.26,30.07,89.04    c-4.12,6.05-43.67,27.58-119.94,15.28c0,0,1.2-9.63,7.35-14.1c0,0,64.19-20.61,64.08-24.71c-0.21-7.75-8.67-14.46-44.53-50.32    C1778.05,1276.51,1806.55,1234.67,1806.55,1234.67z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M1786.74,1236.03c0.88-1.19-0.25-4.97-1.67-12.01c-1.28-6.38-3.45-16.25-3.41-27.35l-1.9,0.32l-31.9,5.37    c0,0,0.73,8.53,0.75,16.57c0,0.07,0,0.13,0,0.2c0.04,6.98-1.88,17.53,0.47,18.9C1761.17,1245.11,1783.34,1240.6,1786.74,1236.03z"
                        />
                        <path
                          fill="#E8945B"
                          d="M1747.87,1202.36c0,0,0.63,12.03,0.66,20.06c17.85-0.43,27.38-17.76,31.25-25.43L1747.87,1202.36z"
                        />
                        <path
                          fill="#FFB27D"
                          d="M1745.94,1216.7c0,0,41.82-5.96,37.71-31.95s-1.48-44.26-28.36-41.55c-26.88,2.7-30.48,13.44-31.22,22.45    C1723.34,1174.66,1734.96,1217.58,1745.94,1216.7z"
                        />
                        <path
                          fill="#163560"
                          d="M1736.2,1145.59c0,0,16.28,28.66,34.52,30.38c18.25,1.71,25.1-4.19,25.1-4.19s-15.15-8.54-20.91-26.74    C1774.91,1145.04,1742.5,1129.71,1736.2,1145.59z"
                        />
                        <path
                          fill="#163560"
                          d="M1739.42,1147.62c0,0-7.96,4.52-10.47,11.25c-2.45,6.58-4.08,15.05-1.84,24.75c0,0-11.91-19.35-4.1-32.61    C1731.29,1136.94,1739.42,1147.62,1739.42,1147.62z"
                        />
                      </g>
                      <path
                        fill="#233862"
                        d="M2660.86,1844.61H342.52c-1.59,0-2.9-1.3-2.9-2.9v-1.67c0-1.59,1.3-2.9,2.9-2.9h2318.35   c1.59,0,2.9,1.3,2.9,2.9v1.67C2663.76,1843.3,2662.46,1844.61,2660.86,1844.61z"
                      />
                    </g>
                  </svg>
                )}
                <h4 className="fs-4 fw-medium text-black mb-3">{title}</h4>
                {message && (
                  <h5 className="fs-5 fw-medium text-dark mb-3">
                    {message} ,Or{" "}
                    <a
                      href="javascript:void(0)"
                      className="text-primary text-capitalize"
                      title="Back to home"
                      onClick={fallbackClick}
                    >
                      {buttonText}
                    </a>
                  </h5>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
