export const MY_ACTIONS_LIST_POST = "/action/my_action_list";
export const ALL_ACTIONS_LIST_POST = "/action/list";
export const ACTION_DETAILS_GET = "/action/detail/";
export const DELETE_ACTION_POST = "/action/delete/";
export const EDIT_ACTION_GET = "/action/edit/";
export const UPDATE_ACTION_POST = "/action/update/";
export const UPLOAD_FILES_POST = "/action/upload_reference_files";
export const MY_ACTIONS_POST = "/action/my_action_list";
export const FILE_TYPE_SUBMISSION = "action/filetype_submission";
export const ACTION_MASTER_FILTER = "action/master_filter";
