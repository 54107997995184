import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useEngagementMeterData } from "../../../../../queries/query-hooks/hcp-profile-hook";
// import { useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import HcpMeterCardSkeleton from "../../../../../skeleton/hcp/HcpProfileDetails/HcpMeterCardSkeleton";

const EngagementMeter = ({ hcpId, colors}) => {
  // const { id: hcpId } = useParams();

  const { data: engagementMeterData, isLoading: isEngagementMeterDataLoading } =
    useEngagementMeterData(hcpId, !!hcpId);

  // const getVariableColor = () => {
  //   let prefix =
  //     getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
  //   if (prefix) {
  //     prefix = prefix.trim();
  //   }
  //   const color1 = getComputedStyle(document.body).getPropertyValue(
  //     `--${prefix}primary`
  //   );
  //   const color2 = getComputedStyle(document.body).getPropertyValue(
  //     `--${prefix}info`
  //   );
  //   const color3 = getComputedStyle(document.body).getPropertyValue(
  //     `--${prefix}primary-tint-20`
  //   );
  //   const color4 = getComputedStyle(document.body).getPropertyValue(
  //     `--${prefix}warning`
  //   );
  //   return {
  //     primary: color1.trim(),
  //     info: color2.trim(),
  //     warning: color4.trim(),
  //     primary_light: color3.trim(),
  //   };
  // };

  // const variableColors = getVariableColor();

  // const colors = [
  //   variableColors.primary,
  //   variableColors.info,
  //   variableColors.warning,
  // ];

  const engagementMeterChart = {
    options: {
      colors: colors,
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: "50%",
          },
          track: {
            margin: 10,
            strokeWidth: "80%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      labels:
        !isEngagementMeterDataLoading && engagementMeterData?.labels?.length > 0
          ? engagementMeterData?.labels
          : ["Voice Call Default", "Comments def", "Engagement"],
    },
    series:
      !isEngagementMeterDataLoading && engagementMeterData?.series?.length > 0
        ? engagementMeterData?.series
        : [76, 55, 100],
  };

  // skeleton
  if (isEngagementMeterDataLoading) {
    return (
      <HcpMeterCardSkeleton labels={engagementMeterChart?.options?.labels} />
    );
  }

  return (
    <Card data-aos="fade-up" data-aos-delay="900" className="h-100">
      <Card.Header className="flex-wrap d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Engagement Meter</h4>
        </div>
        {/* <Dropdown>
        <Dropdown.Toggle
          as={CustomToggle}
          href="#"
          variant="text-secondary dropdown-toggle"
          size="sm"
          id="dropdownMenuButton1"
        >
          This Week
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#">This Week</Dropdown.Item>
          <Dropdown.Item href="#">This Month</Dropdown.Item>
          <Dropdown.Item href="#">This Year</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
      </Card.Header>
      <Card.Body>
        {!isEngagementMeterDataLoading && (
          <div className="flex-wrap d-flex align-items-center justify-content-between">
            <Chart
              className="col-md-8 col-lg-8"
              options={engagementMeterChart.options}
              series={engagementMeterChart.series}
              type="radialBar"
              height="300"
            />
            <Col md={4} lg={4} className="d-grid gap">
              {!isEngagementMeterDataLoading &&
                engagementMeterChart?.options?.labels?.length > 0 &&
                engagementMeterChart?.series?.length > 0 &&
                engagementMeterChart?.options?.labels.map((key, ind) => (
                  <div className="d-flex align-items-start">
                    <svg
                      className="mt-0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      viewBox="0 0 24 24"
                      fill="#1a5aa7"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill={colors[ind]}
                        ></circle>
                      </g>
                    </svg>
                    <div className="ms-3">
                      <h4 className="m-0">
                        {engagementMeterChart?.series[ind]} %
                      </h4>
                      <span className="text-secondary">{key}</span>
                    </div>
                  </div>
                ))}
            </Col>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default EngagementMeter;
