import Default from "../../../../layouts/dashboard/default";
import EmbeddedSmsCreate from "../pages/embaded-sms-gengerator";
import EmbeddedWhatsappCreate from "../pages/embaded-whatsapp-generator";
import EmbeddedEmailCreate from "../pages/embedded-email-generator";

export const EmbeddedToolsRouter = [
  {
    path: "embedded-tools",
    element: <Default />,
    children: [
      {
        path: "create-email",
        element: <EmbeddedEmailCreate />,
      },
      {
        path: "create-sms",
        element: <EmbeddedSmsCreate />,
      },
      {
        path: "create-whatsapp",
        element: <EmbeddedWhatsappCreate />,
      },
    ],
  },
];
