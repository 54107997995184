import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Plotly from "plotly.js-dist";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../../components/dropdowns";
import { useReOnboardingByRegistration } from "../../../../../queries/query-hooks/hcp-profile-hook";
import Skeleton from "../../../../../components/skeleton";
import DashboardDropdown from "../../../../../components/DashboardDropdown";
import { dateFilterOptions } from "../../../../../components/DropdownConstant";
import HcpReonboardingSkeleton from "../../../../../skeleton/hcp/hcp-re-onboarding-skeleton";

const plotlyConfig = {
  displayModeBar: false,
};

var GRAPH_LAYOUT = {
  displayModeBar: false,
};

export default function ReOnboardingByRegistrationGraph() {
  const [payload, setPayload] = useState({
    type: dateFilterOptions?.initial,
    year: "",
    month: "03",
    isRefresh: "",
  });

  console.log("component",payload);

  /**
   * Re-Onboarding By Registration query
   */
  const {
    data: reOnboardingByRegistration,
    isLoading: isReOnboardingGraphLoading,
  } = useReOnboardingByRegistration(payload);

  //   handle dropdown change
  const handleDropdownChange = (type) => {
    setPayload({
      ...payload,
      type: type,
    });
  };

  useEffect(() => {
    console.log("ReOnboarding by registration", reOnboardingByRegistration);
    if (reOnboardingByRegistration && reOnboardingByRegistration?.data) {
      Plotly.newPlot(
        "re-onbording-by-registration-graph",
        [reOnboardingByRegistration?.data],
        GRAPH_LAYOUT,
        plotlyConfig
      );
    }
  }, [reOnboardingByRegistration]);

  // if loading return skeleton
  if (isReOnboardingGraphLoading) {
    return <HcpReonboardingSkeleton />;
  }

  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
        <Card.Title>
          <h4 className="mb-0"> Re-Onboarding By Registration</h4>
          <p className="mb-0 fs-7 text-secondary mt-2">{payload?.type}</p>
        </Card.Title>

        <DashboardDropdown
          handleDropdownChange={handleDropdownChange}
          type={payload?.type}
        />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <div id="re-onbording-by-registration-graph"></div>
      </Card.Body>
    </Card>
  );
}
