import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import article_1 from "../../../../../assets/modules/file-manager/images/article_1.jpg";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import {
  ADD_FILE_TYPE,
  ALL_FILE_TYPES,
  DELETE_FILE_TYPE,
  UPDATE_FILE_TYPE,
  FILE_TYPE_BY_ID,
  UPLOAD_FILE_TYPE,
} from "../../../../../api/endpoints/file-type-endpoints";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import toast from "react-hot-toast";
import uploadService from "../../../../../api/services/upload-service";
import app_config from "../../../../../common/data/app_config";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllFileTypes = () => {
  const fileTypedrpdwns = [
    { id: 1, value: "Audio/Video", label: "Audio/Video" },
    { id: 2, value: "File", label: "File" },
    { id: 3, value: "Text", label: "Text" },
  ];
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [fileTypeList, setFileTypeList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewFileType, setViewFileType] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [filetype, setFileType] = useState(fileTypedrpdwns);
  const [fileTypeIcon, setFileTypeIcon] = useState(null);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      file_type_id: "desc",
    },
  });
  //yup and formik for validation of form fields of file_type_name

  const validationSchema = yup.object().shape({
    file_type_name: yup.string().required("FileType Name is required"),
    file_content_type: yup.string().required("File Content Type is required"),
    file_type_icon: yup.string().required("File Icon is required"),
    // file_type_code: yup.string().required("File Type Code is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      file_type_name: "",
      file_content_type: "",
      file_type_icon: "",
      // file_type_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const postValues = {
        ...values,
        file_type_name: values.file_type_name.trim(),
        // file_content_type: values.file_content_type.trim(),
        // file_type_code: values.file_type_code.trim(),
      };

      console.log("POST_VALUES", postValues);

      try {
        // setLoading(true);
        const response = await postService(
          isEdit ? UPDATE_FILE_TYPE + "/" + selectedFileType : ADD_FILE_TYPE,
          {
            ...postValues,
            status: 1,
            // file_type_icon: "icons/file_type/551198966.png",
            // file_type_code:"551198966"
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchFileTypes(post_json.current);
          isEdit && setIsEdit(false);
          setFileTypeIcon(null);
          toast.success(
            isEdit
              ? "File-type Updated Successfully"
              : "New File-type Added Successfully"
          );
        }
      } catch (error) {
        setLoading(false);
        console.log("FILE_TYPE_SERVICE_ERROR", error);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;

  //fetch file_types
  const fetchFileTypes = async (json) => {
    try {
      // setLoading(true);
      const response = await postService(ALL_FILE_TYPES, json);
      console.log("FILE_TYPE_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setFileTypeList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("FILE_TYPE_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (file_type) => {
    const { file_type_id, file_type_name, file_content_type, file_type_icon } =
      file_type;
    let dataJson = {
      "File Type ID": file_type_id,
      "File Type Name": file_type_name,
      "File Content Type": file_content_type,
      "File Type Icon": file_type_icon ? (
        <div className="w-25 h-25 overflow-hidden">
          <img
            className="w-100 h-100 object-fit-cover"
            src={file_type_icon}
            alt=""
          />
        </div>
      ) : (
        <></>
      ),
    };
    setViewFileType(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteFileType = async () => {
    try {
      // setLoading(true);
      const response = await getService(
        `${DELETE_FILE_TYPE}/${selectedFileType}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchFileTypes(post_json.current);
        toast.success("File-type Deleted Successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("FILE_TYPE_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchFileTypes(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchFileTypes(payload);
    },
    [fileTypeList, loading, post_json.current]
  );
  const onDeleteFileType = (file_type) => {
    // alert("deleting" + file_type.file_type_id);
    setSelectedFileType(file_type.file_type_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "File Type ID",
      accessor: "file_type_id",
    },
    {
      Header: "File Type Name",
      accessor: "file_type_name",
    },
    {
      Header: "File Content Type",
      accessor: "file_content_type",
    },
    {
      Header: "File Type Code",
      accessor: "file_type_code",
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={(data) => viewModal(data)}
          onEdit={(data) => onEditFileTypeClick(data)}
          onDelete={(data) => onDeleteFileType(data)}
        />
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditFileTypeClick = async (file_type) => {
    console.log("file_type===>", file_type);
    setIsEdit(true);
    setSelectedFileType(file_type.file_type_id);

    const editValues = {
      file_type_name: file_type?.file_type_name,
      file_content_type: file_type?.file_content_type,
      file_type_icon: file_type?.file_type_icon,
      // file_type_code: file_type.file_type_code,
    };

    console.log("editValues", editValues);
    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  const onCrossImageClick = () => {
    setFileTypeIcon(null);
    setValues({
      ...values,
      file_type_icon: "",
    });
    // console.log("values", values);
  };

  const uploadFiles = (files) => {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("Did not select file to upload.");
        return;
      }

      // console.log(files[0].size);

      //will change the file size limit later
      if (files[0].size > 100000) {
        reject("File size should be less than 2MB");
      } else {
        let formdata = new FormData();

        files.forEach((file) => {
          formdata.append("files[]", file);
        });

        //To see the value of form data
        for (let value of formdata.values()) {
          console.log(value);
        }

        // 1. Upload files to server
        let response = await uploadService(UPLOAD_FILE_TYPE, formdata);
        // console.log(response);

        if (response.data?.success) {
          // const urls = response.data.data.map((file) => file.name);
          const file = response.data?.data[0];
          resolve(file);
        } else {
          reject("Something went wrong");
        }
      }
    });
  };

  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    // console.log(files, [...files]);

    // console.log(files);

    const fileType = await uploadFiles([...files]);
    console.log("fileType", fileType);

    if (fileType) {
      setFileTypeIcon(fileType?.url);
      setValues({
        ...values,
        file_type_icon: fileType?.name,
      });
    }
  };

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All FileTypes</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add FileType
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                fileTypeList.length > 0 ? (
                  <ReactTable
                    data={fileTypeList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteFileType}
      />
      <ViewModal
        title={"View FileType Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewFileType}
      />
      {/* create a add FileType modal with FileType name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
          setFileTypeIcon(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} FileType</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                File Type Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter FileType Name"
                name="file_type_name"
                onChange={handleChange}
                value={values.file_type_name}
                onBlur={handleBlur}
                isInvalid={touched.file_type_name && errors.file_type_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.file_type_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                File Content Type <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter City Country Id"
                name="file_content_type"
                onChange={handleChange}
                value={values.file_content_type}
                onBlur={handleBlur}
                isInvalid={
                  touched.file_content_type && errors.file_content_type
                }
              >
                <option value="">Select File Type</option>
                {filetype.map((_ft) => (
                  <option value={_ft?.value}>{_ft?.label}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.file_content_type}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-12 form-group">
              <Form.Label>
                File Type Icon <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="file"
                id="customFile"
                name="file_type_icon"
                isInvalid={touched.file_type_icon && errors.file_type_icon}
                onChange={handleChangeReferenceFiles}
              />
              <Form.Control.Feedback type="invalid">
                {errors.file_type_icon}
              </Form.Control.Feedback>
            </Form.Group>
            {(fileTypeIcon || isEdit) && (
              <div className="d-flex flex-wrap flex-row gap-3 mt-3">
                {/* {values.requisition_files?.map((file, index) => ( */}
                <div className="position-relative">
                  {console.log(fileTypeIcon, values.file_type_icon)}
                  <img
                    className="rounded img-fluid avatar-120"
                    src={isEdit ? values.file_type_icon : fileTypeIcon}
                    alt="file_type_icon"
                    loading="lazy"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = article_1;
                    }}
                  />
                  <span
                    className="position-absolute top-0 end-0"
                    onClick={onCrossImageClick}
                  >
                    {findIcon("Square X", "solid", 20)}
                  </span>
                </div>
                {/* ))} */}
              </div>
            )}

            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>File Content Type <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter File Content Type"
                name="file_content_type"
                onChange={handleChange}
                value={values.file_content_type}
                onBlur={handleBlur}
                isInvalid={
                  touched.file_content_type && errors.file_content_type
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.file_content_type}
              </Form.Control.Feedback>
            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>File Type Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter File Type Code"
                name="file_type_code"
                onChange={handleChange}
                value={values.file_type_code}
                onBlur={handleBlur}
                isInvalid={touched.file_type_code && errors.file_type_code}
              />
              <Form.Control.Feedback type="invalid">
                {errors.file_type_code}
              </Form.Control.Feedback>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
              setFileTypeIcon(null);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllFileTypes;
