import * as dayjs from "dayjs";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import {
  ALL_ACTIONS_POST,
  MY_ACTIONS_LIST_POST,
} from "../../../../api/endpoints/action-endpoints";
import postService from "../../../../api/services/post-service";
import {
  changeTableStateToPayload,
  getFormatedDate,
  statusToBg,
} from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import ReactTable from "../../../../lib/table/react-table";
import {
  STATUS_CONSTANTS,
  actionStatus,
  getStatusId,
} from "../constants/action-constants";
import useRedirectToAction from "../hooks/use-redirect-to-action";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import toast from "react-hot-toast";
import PageNotFound from "../../../PageNotFound";
import withAuthorization from "../../../../components/hoc/with-authorization";
import FilterBar from "../../../../components/filter-bar";
import { useGetActionFilterData, useGetActionListData } from "../../../../queries/query-hooks/action-hook";


const priorityOptions = [
  {
    label: "High",
    value: 1,
  },
  {
    label: "Medium",
    value: 2,
  },
  {
    label: "Low",
    value: 2,
  },
];

const statusOptions = [
  {
    label: "Draft",
    value: 1,
  },
  {
    label: "Pending",
    value: 2,
  },
  {
    label: "Deffered",
    value: 3,
  },
  {
    label: "In Progress",
    value: 4,
  },
  {
    label: "Completed",
    value: 5,
  },
  {
    label: "Reject",
    value: 6,
  },
  {
    label: "Cancel",
    value: 7,
  },
];

const details = (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-22"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <path
      d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
      fill="currentColor"
    ></path>
  </svg>
);

const MyActionList = () => {
  const { pathname } = useLocation();
  const { type = "" } = useParams();
  const redirectToAction = useRedirectToAction();
  const statusName = pathname.split("/").length > 2 ? pathname.split("/")[2] : "";
  const statusId = type || "";

  const [pageTitle, setPageTitle] = React.useState("");
  const { data: actionFilterData, isLoading } = useGetActionFilterData();
  const { action_assign_to, action_assign_by, action_priority_id } = actionFilterData || { action_assign_to: [], action_assign_by: [], action_priority_id: [] }


  const getInitialFilter = () =>
    statusId
      ? statusId == 0
        ? statusName == "overdue"
          ? {
            status: { type: "eq", value: STATUS_CONSTANTS.PENDING },
            action_due_date: {
              type: "gt",
              value: dayjs().format("YYYY-MM-DD 00-00-00"),
            },
          }
          : {}
        : { status: { type: "eq", value: statusId } }
      : {};
  const post_json = useRef({
    default: "all",
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: {
      action_id: "desc",
    },
    filter: {},//getInitialFilter(),
  });

  const [filterPayload, setPayloadFilter] = useState(post_json.current);

  const { data: actionListData, isLoading: loading, refetch: fetchActionListData } = useGetActionListData(filterPayload);
  const { data: { totalCount, rows: actions } } = actionListData || { data: { totalCount: 0, rows: [] } };


  const transformToFilter = (input, idKey, nameKey) => {
    return input.map(item => {
      return {
        value: item[idKey],
        label: item[nameKey]
      };
    });
  };

  const userAssignToList = transformToFilter(action_assign_to, "id", "name")
  const userAssignByList = transformToFilter(action_assign_by, "id", "name")
  const userAssignByPriority = transformToFilter(action_priority_id, "priority_id", "priority_name")

  const inputFilters = [
    {
      name: "action_assign_to",
      type: "select",
      placeholder: "Select Assign To",
      label: "Assign To",
      operator: "eq",
      options: userAssignToList
    },
    {
      name: "action_assign_by",
      type: "select",
      placeholder: "Select Assign By",
      label: "Assign By",
      operator: "eq",
      options: userAssignByList
    },
    {
      name: "action_priority_id",
      type: "select",
      placeholder: "Select Priority",
      label: "Priority",
      operator: "eq",
      options: userAssignByPriority
    },
    {
      name: "updated_at",
      type: "dateRange",
      placeholder: "Complete Date",
    },

  ];
  function DropDownFilter({ column, options = [] }) {
    const { filterValue, preFilteredRows, setFilter } = column;
    return (
      <Form.Select onChange={(e) => setFilter(e.target.value || undefined)}>
        {options?.length > 0 &&
          options.map((item) => {
            const { label, value } = item;
            return <option value={value}>{label}</option>;
          })}
      </Form.Select>
    );
  }
  const COLUMNS = [
    {
      Header: "ID",
      accessor: "action_id",
    },
    {
      Header: "Action Name",
      accessor: "action_name",
    },
    {
      Header: "Hcp Id",
      accessor: "origin",
      Cell: ({ value }) => {
        return value?.hcp_id ?? "";
      },
    },
    {
      Header: " Priority",
      accessor: "action_priority_id",
      Filter: ({ column }) => (
        <DropDownFilter column={column} options={priorityOptions} />
      ),
      Cell: ({ value }) => (
        <>
          <div className="d-flex align-items-center justify-content-center">
            <Badge bg={statusToBg(value?.priority_id)} className="me-2 ">
              <span className="btn-inner text-capitalize">
                {value?.priority_name}
              </span>
            </Badge>
          </div>
        </>
      ),
    },
    {
      Header: " Assign By",
      accessor: "action_assign_by",
      disableFilters: true,

      Cell: ({ value }) => {
        return value?.name ?? "";
      },
    },
    {
      Header: " Assign To",
      accessor: "action_assign_to",
      disableFilters: true,

      Cell: ({ value }) => {
        return value?.name ?? "";
      },
    },
    {
      Header: "Due Date ",
      accessor: "action_due_date",
      disableFilters: true,

      Cell: ({ value }) => {
        return value ?? "";
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }) => {
        return getFormatedDate(value);
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: ({ column }) => (
        <DropDownFilter column={column} options={statusOptions} />
      ),
      Cell: ({ value }) => {

        return value?.value ?? "..";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              role="button"
              onClick={() => {
                const { action_type_id, action_id, origin_id, status } =
                  row.original;
                if (status.id !== STATUS_CONSTANTS.PENDING) {
                  toast.error("You can't access this action");
                  return false;
                }
                redirectToAction(action_type_id?.action_type_code, action_id, {
                  origin_id,
                });
              }}
            >
              <span className="btn-inner">{details}</span>
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    let innitialFilter = getInitialFilter();
    setPayloadFilter((prev) => ({
      ...prev, filter: { ...prev.filter, ...innitialFilter }
    }))
    setPageTitle(actionStatus[statusId]);
  }, [pathname]);

  const onTableStateChange = useCallback(
    (state) => {
      let payload = changeTableStateToPayload(state);
      setPayloadFilter(payload)
      fetchActionListData();
    },
    [actions, loading]
  );

  const onFilterApply = (data) => {
    setPayloadFilter((prev) => ({
      ...prev, filter: { ...prev.filter, ...data }
    }))
    fetchActionListData();
  }

  const onResetClick = () => {
    setPayloadFilter(post_json.current);
    fetchActionListData();
  }
  return (
    <>
      <Fragment>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <div className="d-flex flex-column">
            <h3 className="m-0">
              {" "}
              {pageTitle || "All Actions"} ({totalCount})
            </h3>
          </div>
          <div className="d-flex justify-content-between align-items-center rounded flex-column flex-md-row flex-wrap gap-3">

            {
              !loading && <FilterBar inputs={inputFilters} onFilterApply={onFilterApply} onResetClick={onResetClick} />
            }

          </div>
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <div className="fancy-table table-responsive rounded">
                  {/* implement react table hh */}
                  {!loading && actions?.length > 0 ? (
                    <ReactTable
                      columns={COLUMNS}
                      data={actions}
                      onTableStateChange={onTableStateChange}
                      recordsTotal={totalCount}
                      recordsPerPage={10}
                      isRowSelectionAvailable={false}
                      initialState={filterPayload}
                    />
                  ) : // <CommonTableLoader />
                    loading ? (
                      <CommonTableLoader />
                    ) : (
                      <PageNotFound />
                    )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </>
  );
};

export default withAuthorization(memo(MyActionList));
