import React, { memo } from "react";
import { NextIcon, PrevIcon } from "./icons";

const PaginationBar = ({
  onPreviousPage,
  onNextPage,
  gotoPage,
  totalPage,
  pageIndex,
}) => {
  if (totalPage < 2) return null;

  const PaginationCell = ({ index }) => {
    return (
      <li
        key={`page-${index}`}
        className={`page-item ${index === pageIndex ? "active" : ""}`}
      >
        <a
          className="page-link"
          href="javascript:void(0)"
          onClick={() => {
            gotoPage(index);
          }}
        >
          {index + 1}
        </a>
      </li>
    );
  };
  //if total page < 6 then show all the pages else if pageindex < 3 then show 1,2,3...last else if pageindex > totalpage - 3 then show first...last-2,last-1,last else show first...pageindex-1,pageindex,pageindex+1...last
  const PaginationComponent = () => {
    if (totalPage < 6) {
      return (
        <>
          {Array.from({ length: totalPage }, (_, i) => (
            <PaginationCell index={i} />
          ))}
        </>
      );
    } else {
      if (pageIndex < 3) {
        return (
          <>
            {Array.from({ length: 3 }, (_, i) => (
              <PaginationCell index={i} />
            ))}
            <li class="page-item disabled">
              <a class="page-link" href="javascript:void(0)">
                ...
              </a>
            </li>
            <PaginationCell index={totalPage - 1} />
          </>
        );
      } else if (pageIndex > totalPage - 3) {
        return (
          <>
            <PaginationCell index={0} />
            <li class="page-item disabled">
              <a class="page-link" href="javascript:void(0)">
                ...
              </a>
            </li>
            {Array.from({ length: 3 }, (_, i) => (
              <PaginationCell index={totalPage - 3 + i} />
            ))}
          </>
        );
      } else {
        return (
          <>
            <PaginationCell index={0} />
            <li class="page-item disabled">
              <a class="page-link" href="javascript:void(0)">
                ...
              </a>
            </li>
            <PaginationCell index={pageIndex - 1} />
            <PaginationCell index={pageIndex} />
            <PaginationCell index={pageIndex + 1} />
            <li class="page-item disabled">
              <a class="page-link" href="javascript:void(0)">
                ...
              </a>
            </li>
            <PaginationCell index={totalPage - 1} />
          </>
        );
      }
    }
  };
  return (
    <div className="d-flex justify-content-between align-items-start align-items-md-center border-top border-1 pt-3 flex-column flex-md-row">
      <div className="d-flex align-items-center">
        Showing{" "}
        <input
          type="text"
          style={{ width: "3rem" }}
          className="text-dark form-control mx-2 px-2 py-1 text-center"
          value={pageIndex + 1}
          onChange={(event) => {
            const page = event.target.value
              ? Number(event.target.value) - 1
              : 0;
            gotoPage(page);
          }}
        />{" "}
        of <span className="text-dark ms-2"> {totalPage}</span>
      </div>
      <nav aria-label="Page navigation example" className="mt-3 mt-md-0 pe-3">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a
              class="page-link"
              href="javascript:void(0)"
              onClick={onPreviousPage}
            >
              <PrevIcon />
            </a>
          </li>

          <PaginationComponent />

          <li class="page-item ">
            <a class="page-link" href="javascript:void(0)" onClick={onNextPage}>
              <NextIcon />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default memo(PaginationBar);
