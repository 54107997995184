export const folder = [
  {
    id: 0,
    name: "",
    children: [
      1, 11, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
    ],
    parent: null,
  },
  {
    id: 1,
    name: "CLIRNET Services",
    children: [2, 5, 6, 7, 8, 9, 10],
    parent: 0,
  },
  {
    id: 2,
    name: "phase three",
    children: [3, 4],
    parent: 1,
  },
  {
    id: 3,
    name: "child",
    children: [],
    parent: 2,
  },
  {
    id: 4,
    name: "chold",
    children: [],
    parent: 2,
  },
  {
    id: 5,
    name: "phase three",
    children: [],
    parent: 1,
  },
  {
    id: 6,
    name: "phase three",
    children: [],
    parent: 1,
  },
  {
    id: 7,
    name: "phase three",
    children: [],
    parent: 1,
  },
  {
    id: 8,
    name: "phase three",
    children: [],
    parent: 1,
  },
  {
    id: 9,
    name: "phase three",
    children: [],
    parent: 1,
  },
  {
    id: 10,
    name: "DocTube",
    children: [],
    parent: 1,
  },
  {
    id: 11,
    name: "CLIRNET",
    children: [12, 13, 18],
    parent: 0,
  },
  {
    id: 12,
    name: "child principle",
    children: [],
    parent: 11,
  },
  {
    id: 13,
    name: "MedWiki",
    children: [14, 17],
    parent: 11,
  },
  {
    id: 14,
    name: "Sub Division 1",
    children: [15, 16],
    parent: 13,
  },
  {
    id: 15,
    name: "MedWiki",
    children: [],
    parent: 14,
  },
  {
    id: 16,
    name: "MasterCast",
    children: [],
    parent: 14,
  },
  {
    id: 17,
    name: "Sub Division 2",
    children: [],
    parent: 13,
  },
  {
    id: 18,
    name: "Clinical Video",
    children: [],
    parent: 11,
  },
  {
    id: 19,
    name: "Internal",
    children: [],
    parent: 0,
  },
  {
    id: 20,
    name: "MyMD",
    children: [21],
    parent: 0,
  },
  {
    id: 21,
    name: "rin_bn",
    children: [],
    parent: 20,
  },
  {
    id: 22,
    name: "MasterCast",
    children: [],
    parent: 0,
  },
  {
    id: 23,
    name: "DocTube Shorts",
    children: [],
    parent: 0,
  },
  {
    id: 24,
    name: "IT",
    children: [],
    parent: 0,
  },
  {
    id: 25,
    name: "test678",
    children: [],
    parent: 0,
  },
  {
    id: 26,
    name: "test678",
    children: [],
    parent: 0,
  },
  {
    id: 27,
    name: "adawdwad",
    children: [],
    parent: 0,
  },
  {
    id: 28,
    name: "adawdwad 12",
    children: [],
    parent: 0,
  },
  {
    id: 29,
    name: "sample 12",
    children: [],
    parent: 0,
  },
  {
    id: 30,
    name: "sample 12",
    children: [],
    parent: 0,
  },
  {
    id: 31,
    name: "sample 12",
    children: [],
    parent: 0,
  },
  {
    id: 32,
    name: "dasdsd",
    children: [],
    parent: 0,
  },
  {
    id: 33,
    name: "AB",
    children: [],
    parent: 0,
  },
  {
    id: 34,
    name: "AB",
    children: [],
    parent: 0,
  },
];
export const principal_entity_data = [
  {
    principal_entity_id: 6,
    principal_entity_name: "Sub Division 1",
    principal_entity_description: "Principal Entity 2",
    principal_entity_parent_id: 4,
  },
  {
    principal_entity_id: 7,
    principal_entity_name: "Sub Division 2",
    principal_entity_description: "Principal Entity 1",
    principal_entity_parent_id: 4,
  },
  {
    principal_entity_id: 11,
    principal_entity_name: "phase three",
    principal_entity_description: "ooo",
    principal_entity_parent_id: 1,
  },
  {
    principal_entity_id: 12,
    principal_entity_name: "phase three",
    principal_entity_description: "ooo",
    principal_entity_parent_id: 1,
  },
  {
    principal_entity_id: 14,
    principal_entity_name: "child",
    principal_entity_description: "descr",
    principal_entity_parent_id: 11,
  },
  {
    principal_entity_id: 15,
    principal_entity_name: "chold",
    principal_entity_description: "desd",
    principal_entity_parent_id: 11,
  },
  {
    principal_entity_id: 16,
    principal_entity_name: "phase three",
    principal_entity_description: "ooo",
    principal_entity_parent_id: 1,
  },
  {
    principal_entity_id: 18,
    principal_entity_name: "phase three",
    principal_entity_description: "ooo",
    principal_entity_parent_id: 1,
  },
  {
    principal_entity_id: 20,
    principal_entity_name: "child principle",
    principal_entity_description: "child description",
    principal_entity_parent_id: 2,
  },
  {
    principal_entity_id: 21,
    principal_entity_name: "phase three",
    principal_entity_description: "ooo",
    principal_entity_parent_id: 1,
  },
  {
    principal_entity_id: 10,
    principal_entity_name: "phase three",
    principal_entity_description: "ooo",
    principal_entity_parent_id: 1,
  },
  {
    principal_entity_id: 23,
    principal_entity_name: "rin_bn",
    principal_entity_description: "tt",
    principal_entity_parent_id: 22,
  },
  {
    principal_entity_id: 1,
    principal_entity_name: "CLIRNET Services",
    principal_entity_description: "Principal Entity 1",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 2,
    principal_entity_name: "CLIRNET",
    principal_entity_description: "Principal Entity 2",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 3,
    principal_entity_name: "DocTube",
    principal_entity_description: "Principal Entity 2",
    principal_entity_parent_id: 1,
  },
  {
    principal_entity_id: 4,
    principal_entity_name: "MedWiki",
    principal_entity_description: "Principal Entity 2",
    principal_entity_parent_id: 2,
  },
  {
    principal_entity_id: 5,
    principal_entity_name: "Clinical Video",
    principal_entity_description: "Principal Entity 1",
    principal_entity_parent_id: 2,
  },
  {
    principal_entity_id: 13,
    principal_entity_name: "Internal",
    principal_entity_description: "desc",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 22,
    principal_entity_name: "MyMD",
    principal_entity_description: "sss",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 24,
    principal_entity_name: "MasterCast",
    principal_entity_description: "testhghg",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 19,
    principal_entity_name: "DocTube Shorts",
    principal_entity_description: "descr",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 8,
    principal_entity_name: "MedWiki",
    principal_entity_description: "Principal Entity 2",
    principal_entity_parent_id: 6,
  },
  {
    principal_entity_id: 9,
    principal_entity_name: "MasterCast",
    principal_entity_description: "Principal Entity 1",
    principal_entity_parent_id: 6,
  },
  {
    principal_entity_id: 29,
    principal_entity_name: "IT",
    principal_entity_description: "sss",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 30,
    principal_entity_name: "test678",
    principal_entity_description: "hjhj",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 31,
    principal_entity_name: "test678",
    principal_entity_description: "hjhj",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 33,
    principal_entity_name: "adawdwad",
    principal_entity_description: "dadwdawd",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 34,
    principal_entity_name: "adawdwad 12",
    principal_entity_description: "dadwdawd",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 35,
    principal_entity_name: "sample 12",
    principal_entity_description: "sample",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 37,
    principal_entity_name: "sample 12",
    principal_entity_description: "sample 12",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 38,
    principal_entity_name: "sample 12",
    principal_entity_description: "sample 12sd",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 40,
    principal_entity_name: "dasdsd",
    principal_entity_description: "sadsd",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 52,
    principal_entity_name: "AB",
    principal_entity_description: "sss",
    principal_entity_parent_id: 0,
  },
  {
    principal_entity_id: 54,
    principal_entity_name: "AB",
    principal_entity_description: "sss tt",
    principal_entity_parent_id: 0,
  },
];

export const file_type_data = [
  {
    file_type_id: 4,
    file_type_name: "Zip",
    file_type_code: "zip",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/zip.svg                                                                                                                                                                                                                                        ",
  },
  {
    file_type_id: 5,
    file_type_name: "Image",
    file_type_code: "image",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/image.svg                                                                                                                                                                                                                                      ",
  },
  {
    file_type_id: 9,
    file_type_name: "Video",
    file_type_code: "video",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/https://storage.googleapis.com/cipla-cms-dev/icons/file_type/video.svg                                                                                                                                                                                         ",
  },
  {
    file_type_id: 6,
    file_type_name: "PDF",
    file_type_code: "pdf",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg                                                                                                                                                                                                                                        ",
  },
  {
    file_type_id: 8,
    file_type_name: "Email",
    file_type_code: "email",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/email.svg                                                                                                                                                                                                                                      ",
  },
  {
    file_type_id: 1,
    file_type_name: "Audio",
    file_type_code: "audio",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/audio.svg                                                                                                                                                                                                                                      ",
  },
  {
    file_type_id: 2,
    file_type_name: "Article",
    file_type_code: "article",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg                                                                                                                                                                                                                                        ",
  },
  {
    file_type_id: 3,
    file_type_name: "PPT",
    file_type_code: "ppt",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/ppt.svg                                                                                                                                                                                                                                        ",
  },
  {
    file_type_id: 11,
    file_type_name: "Excel",
    file_type_code: "excel",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/word.svg                                                                                                                                                                                                                                       ",
  },
  {
    file_type_id: 56,
    file_type_name: "HTML",
    file_type_code: "html",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/temp/file_type/1687519701_320.png                                                                                                                                                                                                                              ",
  },
  {
    file_type_id: 66,
    file_type_name: "DOCX",
    file_type_code: "docx",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/temp/file_type/1687530852_376.jpg                                                                                                                                                                                                                              ",
  },
  {
    file_type_id: 7,
    file_type_name: "HTML",
    file_type_code: "html",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/html.svg                                                                                                                                                                                                                                       ",
  },
];
