import React from "react";
import Default from "../../../../layouts/dashboard/default";
import ContentStudio from "../pages/content-studio";
import ContentStudioListing from "../pages/content-studio-listing";
import ContentStudioJourney from "../pages/content-studio-journey";
import ContentStudioDetails from "../pages/content-studio-details";
import CreateContentStudio from "../pages/create-content-studio-new";

export const ContentStudioRouter = [
  {
    path: "/content-studio",
    element: <Default />,
    children: [
      {
        path: "/content-studio/dashboard",
        element: <ContentStudio />,
      },
      {
        path: "/content-studio/listing",
        element: <ContentStudioListing />,
      },
      {
        path: "/content-studio/journey",
        element: <ContentStudioJourney />,
      },
      {
        path: "/content-studio/:details/:id",
        element: <ContentStudioDetails />,
      },
      {
        path: "/content-studio/create",
        element: <CreateContentStudio key="create" />,
      },
      {
        path: "/content-studio/create/:id",
        element: <CreateContentStudio key="create-edit" />,
      },
      {
        path: "/content-studio/edit/:id",
        element: <CreateContentStudio key="create" />,
      },
    ],
  },
];
