import { useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import {
  CONTENT_STUDIO_DETAILS,
  CONTENT_STUDIO_FILE_DETAILS,
} from "../../api/endpoints/content-studio-endpoints";
import {
  GET_CONTENT_STUIO_DETAILS,
  GET_CONTENT_STUIO_FILE_DETAILS,
} from "../query-constant/content-studio-hook";

const staleTime = 300000;
const cacheTime = 600000;
const fetchContentStudioDetails = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(`${CONTENT_STUDIO_DETAILS}${id}`);
};

export const useContentStudioDetails = (id, enabled = true, onSuccess) => {
  const queryClient = useQueryClient();
  return useQuery([GET_CONTENT_STUIO_DETAILS, id], fetchContentStudioDetails, {
    enabled: !!enabled,
    onSuccess,
    cacheTime,
    staleTime,
    refetchOnWindowFocus: false,
    select: (res) => res.data.data,
    // initialData: () => { },
  });
};

const fetchFileDetails = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(`${CONTENT_STUDIO_FILE_DETAILS}${id}`);
};

export const useFileDetails = (id, enabled, onSuccess) => {
  const queryClient = useQueryClient();
  return useQuery([GET_CONTENT_STUIO_FILE_DETAILS, id], fetchFileDetails, {
    enabled: !!enabled,
    onSuccess,
    cacheTime,
    staleTime,
    refetchOnWindowFocus: false,
    select: (res) => res.data.data,
    // initialData: () => { },
  });
};
