import { useState, memo, Fragment, useEffect, forwardRef, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Card,
  Badge,
  Dropdown,
  Image,
  Nav,
  Table,
} from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import "swiper/css";
import avatars11 from "../../../../assets/images/avatars/01.png";

//Masonry
import Masonry from "react-masonry-css";
// import LeafletMap from "../components/leafletMap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import {
  useEngagementMeterData,
  useHcpActivityList,
  useHcpDetails,
} from "../../../../queries/query-hooks/hcp-profile-hook";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import networkgraph from "highcharts/modules/networkgraph";

HighchartsExporting(Highcharts);
networkgraph(Highcharts);
const HcpMetaProfile = () => {
  const [request_data_change_modal, set_request_data_change_modal] =
    useState(false);

  const [
    change_recommendation_modal_status,
    set_change_recommendation_modal_status,
  ] = useState(false);
  const { redirectTo } = useRedirect();

  const { id: hcpId } = useParams();

  const onSuccess = (response) => {
    //response
    console.log("reponse", response);
  };

  const onActivitySuccess = (response) => {
    console.log("onActivitySuccess", response);
    console.log("hcpData", generateActivityChartData(response));
  };
  const { data: hcpDetail, isLoading } = useHcpDetails(
    hcpId,
    !!hcpId,
    onSuccess
  );

  const { data: activityList, isLoading: isActivityLoading } =
    useHcpActivityList(hcpId, !!hcpId, onActivitySuccess);

  const { data: engagementMeterData, isLoading: isEngagementMeterDataLoading } =
    useEngagementMeterData(hcpId, !!hcpId);

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [
    variableColors.primary,
    variableColors.info,
    variableColors.warning,
  ];

  useEffect(() => {
    return () => colors;
  });

  const engagementMeterChart = {
    options: {
      colors: colors,
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: "50%",
          },
          track: {
            margin: 10,
            strokeWidth: "80%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      labels:
        !isEngagementMeterDataLoading && engagementMeterData?.labels?.length > 0
          ? engagementMeterData?.labels
          : ["Voice Call Default", "Comments def", "Engagement"],
    },
    series:
      !isEngagementMeterDataLoading && engagementMeterData?.series?.length > 0
        ? engagementMeterData?.series
        : [76, 55, 100],
  };

  function generateActivityChartData(input) {
    const output = [];

    const totalValue = Object.values(input).reduce(
      (acc, value) => acc + value,
      0
    );
    let start = 1;

    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const value = input[key];
        const name = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize the name

        const item = {
          hcp_id: input.hcp_id,
          color: variableColors.primary,
          value,
          id: "circle-progress-01",
          svg: <>{value}</>,
          name,
          start,
          end: (start + value / totalValue) * 100,
        };

        output.push(item);
        start = item.end;
      }
    }

    return output;
  }

  const onRequestDataChnageClick = () => {
    set_request_data_change_modal(!request_data_change_modal);
  };

  const onRequestDataChnageSubmit = () => {
    onRequestDataChnageClick();
  };

  const onRecommendationDataChnageClick = () => {
    set_change_recommendation_modal_status(!change_recommendation_modal_status);
  };

  const seriesData = [
    {
      layoutAlgorithm: {
        enableSimulation: true,
        // initialPositions: function () {
        //   const chart = this.series[0].chart,
        //     width = chart.plotWidth,
        //     height = chart.plotHeight;
        //   console.log("Innitial login:", this.nodes?.length);
        //   this.nodes.forEach(function (node) {
        //     // If initial positions were set previously, use that
        //     // positions. Otherwise use random position:
        //     node.plotX =
        //       node.plotX === undefined ? Math.random() * width : node.plotX;
        //     node.plotY =
        //       node.plotY === undefined ? Math.random() * height : node.plotY;
        //   });
        // },
      },

      nodes: [
        {
          id: "Urogenital Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Amino Acids, Peptides, and Proteins",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Anesthesia and Analgesia",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Animal Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Animal Structures",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Bacteria",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Behavior and Behavior Mechanisms",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Mental Disorders",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Biological Factors",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Body Regions",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Carbohydrates",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Cardiovascular Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Cardiovascular System",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Diagnosis",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Digestive System",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Digestive System Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Behavioral Disciplines and Activities",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Pharmaceutical Preparations",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Embryonic Structures",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Endocrine System Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Endocrine System",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Environment and Public Health",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Eye Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Health Occupations",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Health Services Administration",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Hemic and Lymphatic Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Heterocyclic Compounds",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Hormones, Hormone Substitutes, and Hormone Antagonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Immune System Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Infections",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Information Science",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Inorganic Chemicals",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Lipids",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Investigative Techniques",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Musculoskeletal Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Congenital, Hereditary, and Neonatal Diseases and Abnormalities",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Neoplasms",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Nervous System",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Nervous System Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Nucleic Acids, Nucleotides, and Nucleosides",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Nutritional and Metabolic Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Organic Chemicals",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Otorhinolaryngologic Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Natural Science Disciplines",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Physiological Phenomena",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Polycyclic Compounds",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Population Characteristics",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Psychological Phenomena",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Respiratory System",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Respiratory Tract Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Social Sciences",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Surgical Procedures, Operative",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Pathological Conditions, Signs and Symptoms",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Therapeutics",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Urogenital System",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Wounds and Injuries",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Skin and Connective Tissue Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Health Care Quality, Access, and Evaluation",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Food and Beverages",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Chemical Actions and Uses",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Immune System Phenomena",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Musculoskeletal and Neural Physiological Phenomena",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Chemically-Induced Disorders",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 15,
            fillColor: "Blue",
          },
        },
        {
          id: "Sleep Aids, Pharmaceutical",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Trastuzumab",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Capecitabine",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Tinea cruris",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Chemotherapy-Related Cognitive Impairment",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cognitive Restructuring",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adenocarcinoma",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adrenal Cortex Hormones",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adrenergic alpha-Antagonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adrenergic beta-Agonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adrenergic beta-Antagonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Aldosterone",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Alzheimer Disease",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Analgesics",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Angina Pectoris",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Angiotensin-Converting Enzyme Inhibitors",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Antineoplastic Agents",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Anxiety",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Coronary Artery Bypass",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Arrhythmias, Cardiac",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Ascorbic Acid",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Asthenia",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Asthma",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Atrial Fibrillation",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Autoimmune Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Autonomic Nerve Block",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Avitaminosis",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Blood Transfusion",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Brain",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Brain Concussion",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cardiomyopathy, Dilated",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cardiovascular Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Catecholamines",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Choroid Hemorrhage",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cisplatin",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cognition Disorders",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Communicable Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Confusion",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Coronary Disease",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Neuromuscular Nondepolarizing Agents",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Desensitization, Immunologic",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Diabetes Mellitus",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Diarrhea",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Disease",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Disseminated Intravascular Coagulation",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Diuretics",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Drug Therapy",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Pharmaceutical Preparations",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Embryonic Structures",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Epistaxis",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Ergocalciferols",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Esophagogastric Junction",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Eye Hemorrhage",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Fallopian Tubes",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Fatigue",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Fetomaternal Transfusion",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Fibromyalgia",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Fluorouracil",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Gonads",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart Aneurysm",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart Arrest",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart Atria",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart Block",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart Diseases",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart Failure",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart Ventricles",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Hemorrhage",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Postpartum Hemorrhage",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Histamine H2 Antagonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Hypertension",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Iliac Vein",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Infections",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Kidney",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Liver",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Lung",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Lyme Disease",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Magnesium",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Memory Disorders",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Mercury Poisoning",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Methotrexate",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Metoprolol",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Migraine Disorders",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Mitral Valve Insufficiency",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Morbidity",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Multiple Sclerosis",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cardiomyopathies",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Myocardial Infarction",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Myocarditis",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Neoplasm Metastasis",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Neoplasms",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Neuromuscular Blocking Agents",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Neutropenia",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Nitrates",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Obesity",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Ophthalmia, Sympathetic",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Pain",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Pallor",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Laparoscopy",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Physical Examination",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Platinum",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Pneumonia, Atypical Interstitial, of Cattle",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Pregnancy, Ectopic",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Radiography",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Radiography, Abdominal",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Recurrence",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Rubidium",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Signs and Symptoms, Respiratory",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Sleep Apnea Syndromes",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Stomach",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Stomach Neoplasms",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Substance Withdrawal Syndrome",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Substantia Nigra",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Surgical Procedures, Operative",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Sympathectomy",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Sympathetic Nervous System",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Tachycardia, Supraventricular",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Taste Disorders",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Therapeutics",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Thorax",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Thyroid Crisis",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Vasodilator Agents",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Vitamin D",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Lightning Injuries",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Mycobacterium avium Complex",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Fatty Acids, Omega-3",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Fatigue Syndrome, Chronic",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Comorbidity",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cognitive Behavioral Therapy",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Heart Transplantation",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Critical Illness",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cardiopulmonary Resuscitation",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Paclitaxel",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Ventricular Dysfunction, Left",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Hydroxymethylglutaryl-CoA Reductase Inhibitors",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "alpha-Methyltyrosine",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Mood Disorders",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Myoclonic Epilepsies, Progressive",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Mercury Poisoning, Nervous System",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Pulmonary Disease, Chronic Obstructive",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Renal Insufficiency, Chronic",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Lethargy",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Postural Orthostatic Tachycardia Syndrome",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Vasa Previa",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Hyalinosis, Systemic",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adrenergic beta-1 Receptor Agonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adrenergic beta-2 Receptor Agonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adrenergic beta-1 Receptor Antagonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Adrenergic beta-2 Receptor Antagonists",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Cognitive Dysfunction",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Mortality, Premature",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Teratogenesis",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
        {
          id: "Perinatal Death",
          dataLabels: {
            enabled: true,
          },
          marker: {
            radius: 10,
            fillColor: "Green",
          },
        },
      ],
      data: [
        ["Anions", "Ions"],
        ["Kidney", "Urinary Tract"],
        ["11-Hydroxycorticosteroids", "Hydroxycorticosteroids"],
        ["Oviducts", "Animal Structures"],
        ["Chronic Disease", "Disease Attributes"],
        ["Cardiomyopathy, Dilated", "Laminopathies"],
        ["Statistics as Topic", "Health Care Evaluation Mechanisms"],
        ["Neoplasms, Glandular and Epithelial", "Neoplasms by Histologic Type"],
        [
          "Nervous System Physiological Phenomena",
          "Musculoskeletal and Neural Physiological Phenomena",
        ],
        ["Magnesium", "Metals, Alkaline Earth"],
        ["Rheumatic Diseases", "Connective Tissue Diseases"],
        ["alpha-Methyltyrosine", "Methyltyrosines"],
        [
          "Postural Orthostatic Tachycardia Syndrome",
          "Orthostatic Intolerance",
        ],
        ["Cardiomegaly", "Heart Diseases"],
        ["Atrial Fibrillation", "Arrhythmias, Cardiac"],
        ["Psychophysiology", "Behavioral Sciences"],
        [
          "Female Urogenital Diseases",
          "Female Urogenital Diseases and Pregnancy Complications",
        ],
        ["Coronary Artery Bypass", "Vascular Grafting"],
        ["Intertrigo", "Skin Diseases, Eczematous"],
        ["Lung Diseases", "Respiratory Tract Diseases"],
        ["Hematologic Diseases", "Hemic and Lymphatic Diseases"],
        ["Postpartum Hemorrhage", "Obstetric Labor Complications"],
        ["Liver", "Digestive System"],
        ["Head Injuries, Closed", "Craniocerebral Trauma"],
        ["Growth", "Growth and Development"],
        ["Nucleosides", "Glycosides"],
        ["Asthma", "Lung Diseases, Obstructive"],
        ["Pyrimidine Nucleosides", "Pyrimidines"],
        ["Hydrocarbons, Aromatic", "Hydrocarbons, Cyclic"],
        [
          "Molecular Mechanisms of Pharmacological Action",
          "Pharmacologic Actions",
        ],
        ["Natriuretic Agents", "Cardiovascular Agents"],
        ["Fallopian Tubes", "Oviducts"],
        ["Mercury Poisoning, Nervous System", "Mercury Poisoning"],
        ["Neurologic Manifestations", "Signs and Symptoms"],
        ["Noxae", "Toxic Actions"],
        ["Pregnanes", "Steroids"],
        ["Natriuretic Agents", "Physiological Effects of Drugs"],
        ["Body Constitution", "Physical Examination"],
        ["Cardiac Surgical Procedures", "Cardiovascular Surgical Procedures"],
        ["Desensitization, Immunologic", "Immunosuppression Therapy"],
        ["Pain", "Sensation"],
        ["Ergocalciferols", "Vitamin D"],
        ["Overweight", "Body Weight"],
        ["Neurotoxicity Syndromes", "Nervous System Diseases"],
        ["Therapeutic Uses", "Pharmacologic Actions"],
        ["Disseminated Intravascular Coagulation", "Hemorrhagic Disorders"],
        ["Catecholamines", "Catechols"],
        ["Tauopathies", "Neurodegenerative Diseases"],
        ["Sensory System Agents", "Peripheral Nervous System Agents"],
        ["Neurotoxicity Syndromes", "Poisoning"],
        ["Dermatitis", "Skin Diseases"],
        ["Fallopian Tubes", "Adnexa Uteri"],
        ["Deoxyribonucleosides", "Nucleosides"],
        ["Neuroinflammatory Diseases", "Inflammation"],
        ["Postpartum Hemorrhage", "Uterine Hemorrhage"],
        ["Sleep Apnea Syndromes", "Sleep Disorders, Intrinsic"],
        ["Propanolamines", "Amino Alcohols"],
        ["Metals, Heavy", "Metals"],
        ["Leukoencephalopathies", "Brain Diseases"],
        ["Anesthesia, Conduction", "Anesthesia"],
        ["Eye Hemorrhage", "Hemorrhage"],
        ["Sleep Wake Disorders", "Nervous System Diseases"],
        ["Thyroid Diseases", "Endocrine System Diseases"],
        ["Histamine H2 Antagonists", "Histamine Antagonists"],
        ["Peripheral Nervous System Agents", "Physiological Effects of Drugs"],
        ["Puerperal Disorders", "Pregnancy Complications"],
        ["Stomach Diseases", "Gastrointestinal Diseases"],
        ["Epilepsy, Generalized", "Epilepsy"],
        ["Cytidine", "Ribonucleosides"],
        [
          "Mercury Poisoning, Nervous System",
          "Heavy Metal Poisoning, Nervous System",
        ],
        ["Amino Alcohols", "Alcohols"],
        ["Epilepsies, Myoclonic", "Epilepsy, Generalized"],
        ["Epilepsies, Myoclonic", "Epileptic Syndromes"],
        ["Uterine Hemorrhage", "Uterine Diseases"],
        ["Gonads", "Endocrine Glands"],
        ["Pain", "Neurologic Manifestations"],
        ["Cholestenes", "Cholestanes"],
        ["Radiography, Abdominal", "Radiography"],
        ["Dietary Fats, Unsaturated", "Dietary Fats"],
        ["Sleep Aids, Pharmaceutical", "Hypnotics and Sedatives"],
        ["Autonomic Denervation", "Denervation"],
        ["Myocarditis", "Cardiomyopathies"],
        ["Inflammation", "Pathologic Processes"],
        ["Choroid Hemorrhage", "Eye Hemorrhage"],
        ["Histamine Antagonists", "Histamine Agents"],
        ["Teratogenesis", "Pathologic Processes"],
        ["Skin Diseases, Infectious", "Skin Diseases"],
        ["Cisplatin", "Nitrogen Compounds"],
        ["Mortality, Premature", "Mortality"],
        ["Lightning Injuries", "Electric Injuries"],
        ["Terpenes", "Hydrocarbons"],
        ["Central Nervous System Diseases", "Nervous System Diseases"],
        ["Metals, Light", "Elements"],
        ["Nucleosides", "Nucleic Acids, Nucleotides, and Nucleosides"],
        ["Psychotherapy", "Behavioral Disciplines and Activities"],
        ["Metals, Alkali", "Elements"],
        ["Trastuzumab", "Antibodies, Monoclonal, Humanized"],
        ["Protease Inhibitors", "Enzyme Inhibitors"],
        ["Psychophysiology", "Psychological Phenomena"],
        ["Carboxylic Acids", "Organic Chemicals"],
        ["Lung", "Respiratory System"],
        ["Cardiopulmonary Resuscitation", "Resuscitation"],
        ["Autonomic Nervous System Diseases", "Nervous System Diseases"],
        ["Vector Borne Diseases", "Infections"],
        ["Central Nervous System Agents", "Therapeutic Uses"],
        ["Diarrhea", "Signs and Symptoms, Digestive"],
        ["Alzheimer Disease", "Dementia"],
        ["Neurotransmitter Agents", "Physiological Effects of Drugs"],
        ["Disease", "Pathologic Processes"],
        ["Neuromuscular Agents", "Peripheral Nervous System Agents"],
        ["Fats", "Lipids"],
        ["Hydrocarbons, Alicyclic", "Hydrocarbons, Cyclic"],
        ["Uterine Diseases", "Genital Diseases, Female"],
        ["Demyelinating Autoimmune Diseases, CNS", "Demyelinating Diseases"],
        ["Ventricular Dysfunction", "Heart Diseases"],
        ["Cognitive Dysfunction", "Cognition Disorders"],
        ["Pulmonary Disease, Chronic Obstructive", "Chronic Disease"],
        ["Myoclonic Epilepsies, Progressive", "Epilepsies, Myoclonic"],
        ["Gram-Positive Rods", "Gram-Positive Bacteria"],
        ["Thoracic Surgical Procedures", "Surgical Procedures, Operative"],
        ["Laminopathies", "Genetic Diseases, Inborn"],
        ["Immunoglobulins", "Immunoproteins"],
        ["Uveal Diseases", "Eye Diseases"],
        ["Neuroinflammatory Diseases", "Nervous System Diseases"],
        ["Anemia, Neonatal", "Anemia"],
        ["Tinea cruris", "Intertrigo"],
        ["Psychophysiology", "Physiology"],
        ["Adrenergic beta-Agonists", "Adrenergic Agonists"],
        ["Radiography", "Diagnostic Imaging"],
        ["Acids, Noncarboxylic", "Acids"],
        ["Hemorrhage", "Pathologic Processes"],
        ["Migraine Disorders", "Headache Disorders, Primary"],
        ["Diagnostic Techniques and Procedures", "Diagnosis"],
        ["Neoplasms by Histologic Type", "Neoplasms"],
        ["Thrombophilia", "Hematologic Diseases"],
        [
          "Pregnancy Complications",
          "Female Urogenital Diseases and Pregnancy Complications",
        ],
        ["Glucose Metabolism Disorders", "Metabolic Diseases"],
        ["Deoxycytidine", "Cytidine"],
        ["Aldosterone", "11-Hydroxycorticosteroids"],
        ["Leukocyte Disorders", "Hematologic Diseases"],
        ["Lyme Disease", "Gram-Negative Bacterial Infections"],
        ["Dementia", "Brain Diseases"],
        ["Histamine Agents", "Neurotransmitter Agents"],
        ["Fluorouracil", "Uracil"],
        ["Central Nervous System Infections", "Infections"],
        ["Gram-Negative Bacterial Infections", "Bacterial Infections"],
        ["Anesthesia", "Anesthesia and Analgesia"],
        ["Myocardial Ischemia", "Vascular Diseases"],
        [
          "Hydroxymethylglutaryl-CoA Reductase Inhibitors",
          "Anticholesteremic Agents",
        ],
        ["Sympathetic Nervous System", "Autonomic Nervous System"],
        ["Globulins", "Proteins"],
        ["Serum Globulins", "Blood Proteins"],
        ["Epidemiologic Measurements", "Public Health"],
        ["Statistics as Topic", "Epidemiologic Methods"],
        ["Comorbidity", "Epidemiologic Factors"],
        ["Pterins", "Pteridines"],
        ["Spirochaetales Infections", "Gram-Negative Bacterial Infections"],
        [
          "Drug-Related Side Effects and Adverse Reactions",
          "Chemically-Induced Disorders",
        ],
        ["Data Collection", "Information Management"],
        ["Vital Statistics", "Data Collection"],
        ["Tachycardia", "Arrhythmias, Cardiac"],
        ["Neurodegenerative Diseases", "Nervous System Diseases"],
        ["Endoscopy", "Minimally Invasive Surgical Procedures"],
        ["Cardiac Surgical Procedures", "Thoracic Surgical Procedures"],
        ["Brain Concussion", "Brain Injuries, Traumatic"],
        ["Heart Ventricles", "Heart"],
        ["Cardiomyopathies", "Heart Diseases"],
        ["Heart Transplantation", "Organ Transplantation"],
        ["Public Health", "Medicine"],
        ["Health", "Population Characteristics"],
        ["Confusion", "Neurobehavioral Manifestations"],
        ["Asthma", "Bronchial Diseases"],
        [
          "Cardiovascular Surgical Procedures",
          "Surgical Procedures, Operative",
        ],
        ["Fatigue", "Signs and Symptoms"],
        ["Body Constitution", "Physiological Phenomena"],
        ["Acids, Acyclic", "Carboxylic Acids"],
        ["Neurosurgical Procedures", "Surgical Procedures, Operative"],
        ["Emotions", "Behavior and Behavior Mechanisms"],
        ["Public Health", "Health"],
        ["Genital Diseases, Female", "Female Urogenital Diseases"],
        [
          "Female Urogenital Diseases and Pregnancy Complications",
          "Urogenital Diseases",
        ],
        ["Biometry", "Statistics as Topic"],
        ["Brain Stem", "Brain"],
        ["Coronary Artery Bypass", "Myocardial Revascularization"],
        ["Transplantation", "Surgical Procedures, Operative"],
        ["Hypersensitivity, Immediate", "Hypersensitivity"],
        ["Asthma", "Respiratory Hypersensitivity"],
        ["Fatty Acids", "Lipids"],
        ["Skin Diseases, Genetic", "Genetic Diseases, Inborn"],
        ["Ergocalciferols", "Cholestenes"],
        ["Apnea", "Respiration Disorders"],
        ["Bacterial Infections", "Bacterial Infections and Mycoses"],
        ["Mycobacteriaceae", "Gram-Positive Asporogenous Rods, Regular"],
        ["Platinum", "Transition Elements"],
        ["Epidemiologic Factors", "Public Health"],
        ["Angiotensin-Converting Enzyme Inhibitors", "Protease Inhibitors"],
        ["Substance-Related Disorders", "Mental Disorders"],
        ["Heterocyclic Compounds, 1-Ring", "Heterocyclic Compounds"],
        ["Food", "Food and Beverages"],
        ["Brain Injuries", "Craniocerebral Trauma"],
        ["Upper Gastrointestinal Tract", "Gastrointestinal Tract"],
        ["Elements", "Inorganic Chemicals"],
        ["Cycloparaffins", "Macrocyclic Compounds"],
        ["Capecitabine", "Deoxycytidine"],
        ["Immunomodulation", "Immune System Phenomena"],
        ["Cardiomyopathy, Dilated", "Cardiomegaly"],
        ["Neoplasms by Site", "Neoplasms"],
        ["Magnesium", "Metals, Light"],
        ["Epistaxis", "Hemorrhage"],
        ["Biological Science Disciplines", "Natural Science Disciplines"],
        [
          "Adrenergic beta-2 Receptor Antagonists",
          "Adrenergic beta-Antagonists",
        ],
        [
          "Demyelinating Autoimmune Diseases, CNS",
          "Autoimmune Diseases of the Nervous System",
        ],
        ["Epistaxis", "Nose Diseases"],
        ["Peripheral Nervous System", "Nervous System"],
        ["Alcohols", "Organic Chemicals"],
        ["Myocardial Infarction", "Myocardial Ischemia"],
        ["Anthropometry", "Physical Examination"],
        ["Blood Proteins", "Proteins"],
        ["Behavioral Sciences", "Behavioral Disciplines and Activities"],
        ["Demyelinating Diseases", "Nervous System Diseases"],
        ["Methyltyrosines", "Tyrosine"],
        ["Deoxycytidine", "Deoxyribonucleosides"],
        ["Chlorine Compounds", "Inorganic Chemicals"],
        ["Aneurysm", "Vascular Diseases"],
        ["Propanolamines", "Propanols"],
        ["Vasa Previa", "Obstetric Labor Complications"],
        ["Perinatal Death", "Pregnancy Complications"],
        ["Fetomaternal Transfusion", "Anemia, Neonatal"],
        ["Fatty Acids, Omega-3", "Fatty Acids, Unsaturated"],
        ["Pallor", "Skin Manifestations"],
        ["Myocardial Revascularization", "Cardiac Surgical Procedures"],
        ["Orthostatic Intolerance", "Neurologic Manifestations"],
        ["Pathologic Processes", "Pathological Conditions, Signs and Symptoms"],
        ["Drug Therapy", "Therapeutics"],
        ["Resuscitation", "Emergency Treatment"],
        ["Cattle Diseases", "Animal Diseases"],
        ["Malnutrition", "Nutrition Disorders"],
        ["Myocardial Ischemia", "Heart Diseases"],
        ["Pigments, Biological", "Biological Factors"],
        ["Male Urogenital Diseases", "Urogenital Diseases"],
        ["Torso", "Body Regions"],
        ["Stomach", "Upper Gastrointestinal Tract"],
        ["Sensation", "Psychophysiology"],
        ["Taxoids", "Diterpenes"],
        [
          "Neuromuscular Nondepolarizing Agents",
          "Neuromuscular Blocking Agents",
        ],
        ["Headache Disorders", "Brain Diseases"],
        ["Choroid Diseases", "Uveal Diseases"],
        [
          "Genetic Diseases, Inborn",
          "Congenital, Hereditary, and Neonatal Diseases and Abnormalities",
        ],
        ["Anticholesteremic Agents", "Hypolipidemic Agents"],
        ["Multiple Sclerosis", "Demyelinating Autoimmune Diseases, CNS"],
        ["Heart Block", "Cardiac Conduction System Disease"],
        ["Autonomic Nervous System", "Peripheral Nervous System"],
        ["Digestive System Neoplasms", "Digestive System Diseases"],
        ["Renal Insufficiency, Chronic", "Renal Insufficiency"],
        ["Hydrocarbons, Cyclic", "Hydrocarbons"],
        ["Muscular Diseases", "Musculoskeletal Diseases"],
        ["Laparoscopy", "Endoscopy"],
        ["Connective Tissue Diseases", "Skin and Connective Tissue Diseases"],
        ["Cardiovascular Agents", "Therapeutic Uses"],
        ["Phenoxypropanolamines", "Propanolamines"],
        ["Denervation", "Neurosurgical Procedures"],
        ["Epistaxis", "Signs and Symptoms, Respiratory"],
        ["Neuromuscular Diseases", "Nervous System Diseases"],
        ["Stomach Neoplasms", "Stomach Diseases"],
        ["Cycloparaffins", "Hydrocarbons, Alicyclic"],
        ["Analgesics", "Sensory System Agents"],
        ["Tinea", "Dermatomycoses"],
        ["Demography", "Social Sciences"],
        ["Biological Therapy", "Therapeutics"],
        ["Adrenergic Agonists", "Adrenergic Agents"],
        ["Sugar Acids", "Carbohydrates"],
        ["Sterols", "Membrane Lipids"],
        ["Respiratory Hypersensitivity", "Respiratory Tract Diseases"],
        ["Ventricular Dysfunction, Left", "Ventricular Dysfunction"],
        ["Rubidium", "Metals, Heavy"],
        ["Nitrates", "Nitric Acid"],
        ["Metoprolol", "Phenoxypropanolamines"],
        ["Skin Diseases, Infectious", "Infections"],
        ["Demography", "Population Characteristics"],
        ["Brain Concussion", "Head Injuries, Closed"],
        ["Anxiety", "Emotions"],
        ["Mathematics", "Natural Science Disciplines"],
        [
          "Chemotherapy-Related Cognitive Impairment",
          "Drug-Related Side Effects and Adverse Reactions",
        ],
        ["Proteins", "Amino Acids, Peptides, and Proteins"],
        ["Substantia Nigra", "Cerebral Peduncle"],
        ["Pregnancy, Ectopic", "Pregnancy Complications"],
        ["Kidney Diseases", "Urologic Diseases"],
        ["Skin Manifestations", "Signs and Symptoms"],
        ["Hypertension", "Vascular Diseases"],
        ["Statistics as Topic", "Mathematics"],
        ["Membrane Lipids", "Lipids"],
        ["Antimetabolites", "Noxae"],
        ["Cisplatin", "Chlorine Compounds"],
        ["Body Size", "Body Weights and Measures"],
        ["Adrenal Cortex Hormones", "Hormones"],
        ["Anthropometry", "Biometry"],
        ["Respiration Disorders", "Respiratory Tract Diseases"],
        ["Sympathectomy", "Autonomic Denervation"],
        ["Cardiomegaly", "Hypertrophy"],
        ["Health Services Administration", "Health Occupations"],
        ["Aldosterone", "Pregnenediones"],
        ["Eye Hemorrhage", "Eye Diseases"],
        ["Sleep Disorders, Intrinsic", "Dyssomnias"],
        ["Neurocognitive Disorders", "Mental Disorders"],
        ["Pyrimidines", "Heterocyclic Compounds, 1-Ring"],
        ["Immunoproteins", "Blood Proteins"],
        ["Amino Acids", "Amino Acids, Peptides, and Proteins"],
        ["Genital Diseases", "Urogenital Diseases"],
        ["Vascular Surgical Procedures", "Cardiovascular Surgical Procedures"],
        ["Trauma, Nervous System", "Nervous System Diseases"],
        ["Rheumatic Diseases", "Musculoskeletal Diseases"],
        ["Hydroxycorticosteroids", "Adrenal Cortex Hormones"],
        ["Thyrotoxicosis", "Hyperthyroidism"],
        ["Heavy Metal Poisoning", "Poisoning"],
        ["Alzheimer Disease", "Tauopathies"],
        [
          "Gram-Positive Asporogenous Rods, Irregular",
          "Gram-Positive Asporogenous Rods",
        ],
        ["Anemia", "Hematologic Diseases"],
        ["Immunomodulation", "Biological Therapy"],
        ["Sleep Wake Disorders", "Mental Disorders"],
        ["Sugar Acids", "Hydroxy Acids"],
        ["Immunoglobulins", "Serum Globulins"],
        ["Actinobacteria", "Gram-Positive Bacteria"],
        ["Pregnenediones", "Pregnenes"],
        ["Ribonucleosides", "Nucleosides"],
        ["Postpartum Hemorrhage", "Puerperal Disorders"],
        ["Capecitabine", "Fluorouracil"],
        ["Mitral Valve Insufficiency", "Heart Valve Diseases"],
        ["Gastrointestinal Neoplasms", "Gastrointestinal Diseases"],
        ["Neoplasm Metastasis", "Neoplastic Processes"],
        ["Nose Diseases", "Otorhinolaryngologic Diseases"],
        ["Data Collection", "Health Care Evaluation Mechanisms"],
        ["Neurobehavioral Manifestations", "Behavior and Behavior Mechanisms"],
        ["Skin Diseases, Genetic", "Skin Diseases"],
        ["Electric Injuries", "Wounds and Injuries"],
        ["Encephalomyelitis", "Neuroinflammatory Diseases"],
        ["Catechols", "Phenols"],
        ["Angina Pectoris", "Chest Pain"],
        ["Ischemia", "Pathologic Processes"],
        ["Oils", "Lipids"],
        ["Hemorrhagic Disorders", "Hematologic Diseases"],
        ["Brain", "Central Nervous System"],
        ["Stomach Neoplasms", "Gastrointestinal Neoplasms"],
        ["Social Sciences", "Behavioral Sciences"],
        ["Adnexa Uteri", "Genitalia, Female"],
        [
          "Hormones, Hormone Substitutes, and Hormone Antagonists",
          "Physiological Effects of Drugs",
        ],
        ["Metals, Alkaline Earth", "Elements"],
        ["Overnutrition", "Nutrition Disorders"],
        ["Skin Diseases", "Skin and Connective Tissue Diseases"],
        ["Platinum Compounds", "Inorganic Chemicals"],
        ["Acids", "Inorganic Chemicals"],
        ["Poisoning", "Chemically-Induced Disorders"],
        ["Choroid Hemorrhage", "Choroid Diseases"],
        ["Hyperthyroidism", "Thyroid Diseases"],
        ["Gonads", "Genitalia"],
        ["Disease Attributes", "Pathologic Processes"],
        ["Nerve Block", "Denervation"],
        ["Pyrimidinones", "Pyrimidines"],
        ["Uterine Hemorrhage", "Hemorrhage"],
        ["Epidemiologic Methods", "Investigative Techniques"],
        ["Esophagogastric Junction", "Esophagus"],
        ["Aminopterin", "Pterins"],
        ["Neurologic Manifestations", "Nervous System Diseases"],
        ["Urologic Diseases", "Female Urogenital Diseases"],
        ["Fatty Acids, Omega-3", "Dietary Fats, Unsaturated"],
        ["Vital Statistics", "Demography"],
        [
          "Infant, Newborn, Diseases",
          "Congenital, Hereditary, and Neonatal Diseases and Abnormalities",
        ],
        ["Phenols", "Benzene Derivatives"],
        ["Nitric Acid", "Acids, Noncarboxylic"],
        ["Dietary Fats", "Fats"],
        ["Fibromyalgia", "Muscular Diseases"],
        ["Encephalomyelitis", "Central Nervous System Infections"],
        ["Cisplatin", "Platinum Compounds"],
        ["Growth and Development", "Physiological Phenomena"],
        ["Metals, Light", "Metals"],
        ["Fatigue Syndrome, Chronic", "Neuromuscular Diseases"],
        ["Metals, Alkali", "Metals"],
        ["Adrenergic Antagonists", "Adrenergic Agents"],
        ["Heart Arrest", "Heart Diseases"],
        ["Gastrointestinal Diseases", "Digestive System Diseases"],
        ["Tyrosine", "Amino Acids, Aromatic"],
        ["Avitaminosis", "Deficiency Diseases"],
        ["Obesity", "Overweight"],
        [
          "Minimally Invasive Surgical Procedures",
          "Surgical Procedures, Operative",
        ],
        ["Methotrexate", "Aminopterin"],
        ["Sensation Disorders", "Neurologic Manifestations"],
        ["Information Management", "Information Science"],
        ["Bronchial Diseases", "Respiratory Tract Diseases"],
        ["Headache Disorders, Primary", "Headache Disorders"],
        ["Urologic Diseases", "Urogenital Diseases"],
        ["Pterins", "Pigments, Biological"],
        ["Pyrimidine Nucleosides", "Nucleosides"],
        ["Respiratory Hypersensitivity", "Hypersensitivity, Immediate"],
        ["Signs and Symptoms, Digestive", "Signs and Symptoms"],
        ["Adrenergic beta-1 Receptor Agonists", "Adrenergic beta-Agonists"],
        ["Biometry", "Epidemiologic Measurements"],
        ["Eye Manifestations", "Signs and Symptoms"],
        ["Diabetes Mellitus", "Glucose Metabolism Disorders"],
        ["Steroids", "Fused-Ring Compounds"],
        ["Infarction", "Ischemia"],
        ["Nitrogen Compounds", "Inorganic Chemicals"],
        ["Vasodilator Agents", "Cardiovascular Agents"],
        ["Enzyme Inhibitors", "Molecular Mechanisms of Pharmacological Action"],
        ["Lung Diseases, Obstructive", "Lung Diseases"],
        ["Eye Manifestations", "Eye Diseases"],
        ["Body Weight", "Signs and Symptoms"],
        ["Arrhythmias, Cardiac", "Pathologic Processes"],
        ["Obstetric Labor Complications", "Pregnancy Complications"],
        ["Craniocerebral Trauma", "Trauma, Nervous System"],
        ["Genital Diseases, Female", "Genital Diseases"],
        ["Amino Acids, Aromatic", "Amino Acids, Cyclic"],
        ["Lipid Regulating Agents", "Therapeutic Uses"],
        ["Immunosuppression Therapy", "Immunotherapy"],
        ["Autoimmune Diseases", "Immune System Diseases"],
        ["Morbidity", "Vital Statistics"],
        ["Metals, Heavy", "Elements"],
        ["Cognition Disorders", "Neurocognitive Disorders"],
        ["Gram-Positive Asporogenous Rods", "Gram-Positive Rods"],
        ["Propanols", "Alcohols"],
        ["Genitalia", "Urogenital System"],
        ["Epidemiologic Factors", "Quality of Health Care"],
        ["Mortality", "Vital Statistics"],
        ["Arrhythmias, Cardiac", "Heart Diseases"],
        ["Borrelia Infections", "Spirochaetales Infections"],
        ["Diabetes Mellitus", "Endocrine System Diseases"],
        ["Body Weight", "Body Size"],
        ["Taste Disorders", "Sensation Disorders"],
        ["Coronary Disease", "Myocardial Ischemia"],
        ["Mercury Poisoning", "Heavy Metal Poisoning"],
        ["Secosteroids", "Steroids"],
        ["Chemotherapy-Related Cognitive Impairment", "Cognitive Dysfunction"],
        ["Sleep Apnea Syndromes", "Apnea"],
        ["Heart Diseases", "Cardiovascular Diseases"],
        ["Carcinoma", "Neoplasms, Glandular and Epithelial"],
        ["Actinobacteria", "Gram-Positive Asporogenous Rods, Irregular"],
        ["Adenocarcinoma", "Carcinoma"],
        ["Esophagus", "Upper Gastrointestinal Tract"],
        ["Epilepsy", "Brain Diseases"],
        ["Central Nervous System", "Nervous System"],
        ["Primary Dysautonomias", "Autonomic Nervous System Diseases"],
        ["Antibodies", "Immunoglobulins"],
        ["Intertrigo", "Dermatitis"],
        ["Leukopenia", "Leukocyte Disorders"],
        ["Necrosis", "Pathologic Processes"],
        ["Physiological Effects of Drugs", "Pharmacologic Actions"],
        ["Pneumonia, Atypical Interstitial, of Cattle", "Cattle Diseases"],
        ["Adrenergic alpha-Antagonists", "Adrenergic Antagonists"],
        ["Mesencephalon", "Brain Stem"],
        ["Bacterial Infections and Mycoses", "Infections"],
        ["Heavy Metal Poisoning, Nervous System", "Neurotoxicity Syndromes"],
        ["Epidemiologic Methods", "Public Health"],
        ["Gram-Positive Bacteria", "Bacteria"],
        [
          "Adrenergic beta-1 Receptor Antagonists",
          "Adrenergic beta-Antagonists",
        ],
        ["Cytidine", "Pyrimidine Nucleosides"],
        ["Nitrates", "Organic Chemicals"],
        ["Thorax", "Torso"],
        ["Nitric Acid", "Nitrogen Compounds"],
        ["Immunotherapy", "Immunomodulation"],
        ["Nontuberculous Mycobacteria", "Mycobacterium"],
        ["Fatigue Syndrome, Chronic", "Muscular Diseases"],
        ["Heart Valve Diseases", "Heart Diseases"],
        ["Veins", "Blood Vessels"],
        ["Serum Globulins", "Globulins"],
        ["Taxoids", "Cyclodecanes"],
        ["Blood Coagulation Disorders", "Hematologic Diseases"],
        ["Esophagogastric Junction", "Stomach"],
        ["Hyalinosis, Systemic", "Skin Diseases, Genetic"],
        ["Mood Disorders", "Mental Disorders"],
        ["Cardiac Conduction System Disease", "Heart Diseases"],
        ["Paclitaxel", "Taxoids"],
        ["Fatigue Syndrome, Chronic", "Encephalomyelitis"],
        ["Antimetabolites", "Molecular Mechanisms of Pharmacological Action"],
        ["Ascorbic Acid", "Sugar Acids"],
        [
          "Quality of Health Care",
          "Health Care Quality, Access, and Evaluation",
        ],
        ["Hormones", "Hormones, Hormone Substitutes, and Hormone Antagonists"],
        ["Transition Elements", "Elements"],
        ["Heart Transplantation", "Cardiac Surgical Procedures"],
        ["Physical Appearance, Body", "Body Constitution"],
        ["Pharmacologic Actions", "Chemical Actions and Uses"],
        ["Wounds, Nonpenetrating", "Wounds and Injuries"],
        ["Catecholamines", "Amines"],
        ["Tinea cruris", "Tinea"],
        ["Heterocyclic Compounds, Fused-Ring", "Heterocyclic Compounds"],
        ["Substance Withdrawal Syndrome", "Substance-Related Disorders"],
        ["Sugar Acids", "Acids, Acyclic"],
        ["Iliac Vein", "Veins"],
        ["Physiology", "Biological Science Disciplines"],
        ["Thyroid Crisis", "Thyrotoxicosis"],
        ["Ophthalmia, Sympathetic", "Panuveitis"],
        ["Urinary Tract", "Urogenital System"],
        ["Sensation", "Nervous System Physiological Phenomena"],
        ["Angina Pectoris", "Myocardial Ischemia"],
        ["Body Weights and Measures", "Anthropometry"],
        ["Heart Aneurysm", "Aneurysm"],
        ["Hydrocarbons", "Organic Chemicals"],
        ["Anemia, Neonatal", "Infant, Newborn, Diseases"],
        ["Communicable Diseases", "Infections"],
        ["Eye Hemorrhage", "Eye Manifestations"],
        ["Pregnenes", "Pregnanes"],
        ["Gastrointestinal Tract", "Digestive System"],
        ["Head Injuries, Closed", "Wounds, Nonpenetrating"],
        ["Sleep Wake Disorders", "Neurologic Manifestations"],
        ["Panuveitis", "Uveitis"],
        ["Cognitive Behavioral Therapy", "Behavior Therapy"],
        ["Sterols", "Cholestanes"],
        ["Renal Insufficiency", "Kidney Diseases"],
        ["Substance-Related Disorders", "Chemically-Induced Disorders"],
        ["Heart", "Cardiovascular System"],
        ["Vascular Diseases", "Cardiovascular Diseases"],
        ["Cognitive Restructuring", "Cognitive Behavioral Therapy"],
        ["Neuromuscular Blocking Agents", "Neuromuscular Agents"],
        ["Dermatomycoses", "Mycoses"],
        ["Fibromyalgia", "Rheumatic Diseases"],
        ["Neoplastic Processes", "Neoplasms"],
        ["Central Nervous System Depressants", "Central Nervous System Agents"],
        ["Body Weight", "Physical Appearance, Body"],
        ["Autoimmune Diseases of the Nervous System", "Autoimmune Diseases"],
        ["Electrolytes", "Inorganic Chemicals"],
        ["Analgesics", "Central Nervous System Agents"],
        ["Fused-Ring Compounds", "Polycyclic Compounds"],
        ["Cholestanes", "Steroids"],
        ["Glycosides", "Carbohydrates"],
        ["Immunologic Techniques", "Investigative Techniques"],
        ["Diagnostic Imaging", "Diagnostic Techniques and Procedures"],
        ["Benzene Derivatives", "Hydrocarbons, Aromatic"],
        ["Body Size", "Growth"],
        ["Signs and Symptoms", "Pathological Conditions, Signs and Symptoms"],
        ["Diterpenes", "Terpenes"],
        ["Neoplastic Processes", "Pathologic Processes"],
        ["Medicine", "Health Occupations"],
        ["Neurobehavioral Manifestations", "Neurologic Manifestations"],
        ["Dietary Fats", "Food"],
        ["Rubidium", "Metals, Alkali"],
        ["Trauma, Nervous System", "Wounds and Injuries"],
        ["Endoscopy", "Diagnostic Techniques, Surgical"],
        ["Heart Block", "Arrhythmias, Cardiac"],
        [
          "Neurotransmitter Agents",
          "Molecular Mechanisms of Pharmacological Action",
        ],
        ["Physical Examination", "Diagnostic Techniques and Procedures"],
        ["Myocardial Infarction", "Infarction"],
        ["Hypolipidemic Agents", "Lipid Regulating Agents"],
        ["Pteridines", "Heterocyclic Compounds, 2-Ring"],
        ["Death", "Pathologic Processes"],
        ["Lyme Disease", "Borrelia Infections"],
        ["Food", "Diet, Food, and Nutrition"],
        ["Ions", "Electrolytes"],
        ["Disseminated Intravascular Coagulation", "Thrombophilia"],
        ["Lethargy", "Neurobehavioral Manifestations"],
        ["Epileptic Syndromes", "Epilepsy"],
        ["Tick-Borne Diseases", "Vector Borne Diseases"],
        ["Brain Injuries", "Brain Diseases"],
        ["Recurrence", "Disease Attributes"],
        ["Perinatal Death", "Death"],
        ["Hypolipidemic Agents", "Antimetabolites"],
        ["Demyelinating Autoimmune Diseases, CNS", "Leukoencephalopathies"],
        ["Blood Transfusion", "Biological Therapy"],
        ["Mycobacterium", "Mycobacteriaceae"],
        ["Signs and Symptoms, Respiratory", "Signs and Symptoms"],
        ["Skin Diseases, Eczematous", "Skin Diseases"],
        ["Nose Diseases", "Respiratory Tract Diseases"],
        ["Fish Oils", "Oils"],
        ["Asthenia", "Signs and Symptoms"],
        ["Quality of Health Care", "Health Services Administration"],
        ["Endocrine Glands", "Endocrine System"],
        ["Lyme Disease", "Tick-Borne Diseases"],
        ["Body Weights and Measures", "Body Constitution"],
        ["Data Collection", "Epidemiologic Methods"],
        ["Genitalia, Female", "Genitalia"],
        ["Diet, Food, and Nutrition", "Physiological Phenomena"],
        ["Emergency Treatment", "Therapeutics"],
        ["Metabolic Diseases", "Nutritional and Metabolic Diseases"],
        ["Mycobacterium avium Complex", "Nontuberculous Mycobacteria"],
        ["Antineoplastic Agents", "Therapeutic Uses"],
        ["Adrenergic beta-2 Receptor Agonists", "Adrenergic beta-Agonists"],
        ["Dyssomnias", "Sleep Wake Disorders"],
        ["Neutropenia", "Agranulocytosis"],
        ["Diuretics", "Natriuretic Agents"],
        ["Hypertrophy", "Pathological Conditions, Anatomical"],
        ["Vascular Grafting", "Vascular Surgical Procedures"],
        ["Orthostatic Intolerance", "Primary Dysautonomias"],
        ["Urologic Diseases", "Male Urogenital Diseases"],
        ["Amines", "Organic Chemicals"],
        ["Critical Illness", "Disease Attributes"],
        ["Vitamin D", "Secosteroids"],
        ["Autonomic Nerve Block", "Nerve Block"],
        ["Brain Diseases", "Central Nervous System Diseases"],
        ["Heart Failure", "Heart Diseases"],
        ["Hypnotics and Sedatives", "Central Nervous System Depressants"],
        ["Toxic Actions", "Chemical Actions and Uses"],
        [
          "Gram-Positive Asporogenous Rods, Regular",
          "Gram-Positive Asporogenous Rods",
        ],
        ["Brain Injuries, Traumatic", "Brain Injuries"],
        ["Immunosuppression Therapy", "Immunologic Techniques"],
        ["Overweight", "Overnutrition"],
        ["Cerebral Peduncle", "Mesencephalon"],
        [
          "Central Nervous System Infections",
          "Central Nervous System Diseases",
        ],
        [
          "Heterocyclic Compounds, 2-Ring",
          "Heterocyclic Compounds, Fused-Ring",
        ],
        ["Metals", "Inorganic Chemicals"],
        ["Dermatomycoses", "Skin Diseases, Infectious"],
        ["Antibodies, Monoclonal", "Antibodies"],
        [
          "Pulmonary Disease, Chronic Obstructive",
          "Lung Diseases, Obstructive",
        ],
        ["Tachycardia, Supraventricular", "Tachycardia"],
        [
          "Disseminated Intravascular Coagulation",
          "Blood Coagulation Disorders",
        ],
        ["Muscular Diseases", "Neuromuscular Diseases"],
        ["Apnea", "Signs and Symptoms, Respiratory"],
        ["Heart Aneurysm", "Heart Diseases"],
        ["Adrenergic beta-Antagonists", "Adrenergic Antagonists"],
        ["Digestive System Neoplasms", "Neoplasms by Site"],
        ["Health Care Evaluation Mechanisms", "Quality of Health Care"],
        ["Organ Transplantation", "Transplantation"],
        ["Memory Disorders", "Neurobehavioral Manifestations"],
        ["Uracil", "Pyrimidinones"],
        ["Communicable Diseases", "Disease Attributes"],
        ["Ergocalciferols", "Sterols"],
        [
          "Pathological Conditions, Anatomical",
          "Pathological Conditions, Signs and Symptoms",
        ],
        [
          "Diagnostic Techniques, Surgical",
          "Diagnostic Techniques and Procedures",
        ],
        ["Behavior Therapy", "Psychotherapy"],
        ["Mycoses", "Bacterial Infections and Mycoses"],
        ["Tachycardia", "Cardiac Conduction System Disease"],
        ["Nutrition Disorders", "Nutritional and Metabolic Diseases"],
        ["Fatty Acids, Omega-3", "Fish Oils"],
        ["Renal Insufficiency, Chronic", "Chronic Disease"],
        ["Antibodies, Monoclonal, Humanized", "Antibodies, Monoclonal"],
        ["Ophthalmia, Sympathetic", "Autoimmune Diseases"],
        ["Agranulocytosis", "Leukopenia"],
        ["Hydroxymethylglutaryl-CoA Reductase Inhibitors", "Enzyme Inhibitors"],
        ["Platinum", "Metals, Heavy"],
        ["Uveitis", "Uveal Diseases"],
        ["Nitrates", "Anions"],
        ["Cardiomyopathy, Dilated", "Cardiomyopathies"],
        ["Deficiency Diseases", "Malnutrition"],
        ["Blood Vessels", "Cardiovascular System"],
        ["Amino Acids, Cyclic", "Amino Acids"],
        ["Gastrointestinal Neoplasms", "Digestive System Neoplasms"],
        ["Cyclodecanes", "Cycloparaffins"],
        ["Nerve Block", "Anesthesia, Conduction"],
        [
          "Autoimmune Diseases of the Nervous System",
          "Nervous System Diseases",
        ],
        ["Metals, Alkaline Earth", "Metals"],
        ["Fatty Acids, Unsaturated", "Fatty Acids"],
        ["Mycobacteriaceae", "Actinobacteria"],
        ["Infarction", "Necrosis"],
        ["Hydroxy Acids", "Carboxylic Acids"],
        ["Public Health", "Environment and Public Health"],
        ["Hypersensitivity", "Immune System Diseases"],
        [
          "Central Nervous System Depressants",
          "Physiological Effects of Drugs",
        ],
        ["Macrocyclic Compounds", "Polycyclic Compounds"],
        ["Chest Pain", "Pain"],
        ["Anthropometry", "Investigative Techniques"],
        ["Heart Atria", "Heart"],
        ["Amino Alcohols", "Amines"],
        ["Demography", "Epidemiologic Measurements"],
        ["Dementia", "Neurocognitive Disorders"],
        ["Adrenergic Agents", "Neurotransmitter Agents"],
      ],
    },
  ];

  return (
    <Fragment>
      <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <Col>
          <Card className="h-100">
            <Row>
              <Col lg="12">
                <Card className="mb-3">
                  <Card.Body className="p-3">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                          <Image
                            className="theme-color-default-img  img-fluid rounded-pill avatar-100"
                            src={avatars11}
                            alt="profile-pic"
                          />
                        </div>
                        {(hcpDetail?.hcp_first_name ||
                          hcpDetail?.hcp_to_primary_speciality) && (
                          <div className="d-flex flex-wrap flex-column align-items-start mb-3 mb-sm-0">
                            {
                              <h4 className="me-2 mb-0 fs-5">
                                {hcpDetail?.hcp_first_name +
                                  " " +
                                  hcpDetail.hcp_middle_name +
                                  " " +
                                  hcpDetail?.hcp_last_name}
                              </h4>
                            }
                            {hcpDetail?.hcp_to_primary_speciality && (
                              <span>
                                {
                                  hcpDetail?.hcp_to_primary_speciality
                                    ?.speciality_name
                                }
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="12">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "networkgraph",
                      plotBorderWidth: 1,
                    },
                    title: {
                      text: "Networkgraph with random initial positions",
                    },
                    plotOptions: {
                      // layoutAlgorithm: {
                      //   enableSimulation: true,
                      //   // integration: integration,
                      //   // linkLength: linkLength,
                      // },
                      networkgraph: {
                        keys: ["from", "to"],
                      },
                      marker: {
                        radius: 5,
                        lineWidth: 1,
                      },
                    },
                    series: seriesData,
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Masonry>
    </Fragment>
  );
};

export default HcpMetaProfile;
