import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import {
  GET_ACTION_DETAILS,
  GET_ACTION_EDIT,
} from "../query-constant/action-constant";
import {
  ACTION_DETAILS_GET,
  EDIT_ACTION_GET,
} from "../../api/endpoints/action-endpoints";
import {
  GET_CREATE_SEGMENT_DATA,
  GET_EDIT_SEGMENT_DATA,
  GET_SEGMENT_DETAILS,
} from "../query-constant/segment-constant";
import {
  GET_CREATE_USER_SEGMENT,
  GET_USER_SEGMENT_DETAILS,
  GET_USER_SEGMENT_EDIT,
} from "../../api/endpoints/segment-endpoints";

const staleTime = 300000;
const cacheTime = 600000;
export const useGetSegmentDetails = (id, onSuccess) => {
  return useQuery(
    [GET_SEGMENT_DETAILS, id],
    () => axiosInstance.get(`${GET_USER_SEGMENT_DETAILS}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      onSuccess,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetSegmentEditData = (id, onSuccess) => {
  return useQuery(
    [GET_EDIT_SEGMENT_DATA, id],
    () => axiosInstance.get(`${GET_USER_SEGMENT_EDIT}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      onSuccess,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetSegmentCreateData = (onSuccess, enabled) => {
  return useQuery(
    [GET_CREATE_SEGMENT_DATA],
    () => axiosInstance.get(GET_CREATE_USER_SEGMENT),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled,
      onSuccess,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
