import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllMasterVendor from "../pages/all-master-vendor";


export const MasterVendorRouter = [
  {
    path: "/master-management/master-vendor",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllMasterVendor />,
      },
    ],
  },
];