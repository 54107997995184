import React, { memo } from "react";
import { Col, Row, Dropdown } from "react-bootstrap";
import CustomToggle from "./dropdowns";
import { dateFilterOptions } from "./DropdownConstant";

function DashboardDropdown({ handleDropdownChange, type }) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        variant="text-secondary dropdown-toggle"
      >
        {type}
      </Dropdown.Toggle>

      <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
        {dateFilterOptions?.options.map((item, index) => (
          <Dropdown.Item
            key={index}
            href="#"
            className="text-capitalize"
            onClick={() => {
              handleDropdownChange(item);
            }}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(DashboardDropdown);
