import React, { Fragment } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Offcanvas,
  Row,
} from "react-bootstrap";
// import "../../../../assets/modules/chat/scss/chat.scss";
import "../../../../assets/modules/chat/scss/chat.scss";
import ReactTable from "../../../../lib/table/react-table";
import TemplateSuggestionCard from "../../template-manager/components/template-suggestion-card";
import Select from "react-select";
import { findIcon } from "../../../dashboard/icons";

const details = (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-22"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <path
      d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
      fill="currentColor"
    ></path>
  </svg>
);
const ReplyTaskDetails = () => {
  const [show, setShow] = React.useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const ChatMode = [
    { value: "poke", label: "Poke" },
    { value: "poke 2", label: "poke 2" },
  ];
  const userData = [
    {
      slno: "1",
      pageid: "128",
      services: "Training",
      channel: "WhatsApp",
      template: "training_course_graphic",
      shortlink: "https://uat.clrn.in/RlzMb?tk=wNOgq9	",
      sendingtime: "2023-06-09 16:03:38",
      viewtime: "-",
      status: "In Progress",
    },
    {
      slno: "2",
      pageid: "8",
      services: "Digital CME",
      channel: "WhatsApp",
      template: "cme_image1	",
      shortlink: "https://uat.clrn.in/RlzMb?tk=wNOgq9	",
      sendingtime: "2023-06-09 16:03:38",
      viewtime: "-",
      status: "In Progress",
    },
    {
      slno: "3",
      pageid: "128",
      services: "Training",
      channel: "WhatsApp",
      template: "training_course_graphic",
      shortlink: "https://uat.clrn.in/RlzMb?tk=wNOgq9	",
      sendingtime: "2023-06-09 16:03:38",
      viewtime: "-",
      status: "In Progress",
    },
    {
      slno: "4",
      pageid: "8",
      services: "Digital CME",
      channel: "WhatsApp",
      template: "cme_image1	",
      shortlink: "https://uat.clrn.in/RlzMb?tk=wNOgq9	",
      sendingtime: "2023-06-09 16:03:38",
      viewtime: "-",
      status: "In Progress",
    },
    {
      slno: "5",
      pageid: "128",
      services: "Training",
      channel: "WhatsApp",
      template: "training_course_graphic",
      shortlink: "https://uat.clrn.in/RlzMb?tk=wNOgq9	",
      sendingtime: "2023-06-09 16:03:38",
      viewtime: "-",
      status: "In Progress",
    },
  ];
  const COLUMNS = [
    {
      Header: "Sl No",
      accessor: "slno",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Page Id",
      accessor: "pageid",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Services",
      accessor: "services",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Channel",
      accessor: "channel",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Template",
      accessor: "template",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Short LInk",
      accessor: "shortlink",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Sending Time",
      accessor: "sendingtime",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "View Time",
      accessor: "viewtime",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Status",
      accessor: "status",
      // disableFilters: true,
      // Filter: DefaultColumnFilter,
    },
  ];
  return (
    <Fragment>
      <Row className="gap-4">
        <Col sm={12}>
          <Card className={`mb-0`}>
            <Card.Header className="border-bottom d-flex justify-content-between align-items-center rounded-0 p-3">
              <div className="d-flex align-items-center gap-3">
                <div className="position-relative">
                  <Image
                    src="https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=1024x1024&w=is&k=20&c=5q1NiElGDfT8IxbxhEWKTSufxyn3oFVHNnPNPyDe6as="
                    alt="users"
                    className="img-fluid avatar-50 rounded-pill object-fit-cover"
                    loading="lazy"
                  />
                </div>
                <div className="d-flex flex-column">
                  <h5 className="mb-0">User Nameaaaa</h5>
                  <small className="text-capitalize">Online</small>
                </div>
              </div>
              <div
                href="#all-engagement"
                className="ms-auto me-3 border borer-dashed text-info border-info rounded-2 px-3 py-2 cursor-pointer"
              >
                View All Engagement
              </div>
              <Dropdown className=" d-inline-flex">
                <Dropdown.Toggle as="span" bsPrefix="text-body d-inline-flex">
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 20.6788C10 21.9595 11.0378 23 12.3113 23C13.5868 23 14.6265 21.9595 14.6265 20.6788C14.6265 19.3981 13.5868 18.3576 12.3113 18.3576C11.0378 18.3576 10 19.3981 10 20.6788ZM10 12.0005C10 13.2812 11.0378 14.3217 12.3113 14.3217C13.5868 14.3217 14.6265 13.2812 14.6265 12.0005C14.6265 10.7198 13.5868 9.67929 12.3113 9.67929C11.0378 9.67929 10 10.7198 10 12.0005ZM12.3113 5.64239C11.0378 5.64239 10 4.60192 10 3.3212C10 2.04047 11.0378 1 12.3113 1C13.5868 1 14.6265 2.04047 14.6265 3.3212C14.6265 4.60192 13.5868 5.64239 12.3113 5.64239Z"
                      fill="#8A92A6"
                    ></path>
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu as="ul" align="end">
                  <li>
                    <Dropdown.Item className="d-flex gap-2">
                      <span className="d-flex">
                        <svg
                          width="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M21.3309 7.44251C20.9119 7.17855 20.3969 7.1552 19.9579 7.37855L18.4759 8.12677C17.9279 8.40291 17.5879 8.96129 17.5879 9.58261V15.4161C17.5879 16.0374 17.9279 16.5948 18.4759 16.873L19.9569 17.6202C20.1579 17.7237 20.3729 17.7735 20.5879 17.7735C20.8459 17.7735 21.1019 17.7004 21.3309 17.5572C21.7499 17.2943 21.9999 16.8384 21.9999 16.339V8.66179C21.9999 8.1623 21.7499 7.70646 21.3309 7.44251Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M11.9051 20H6.11304C3.69102 20 2 18.3299 2 15.9391V9.06091C2 6.66904 3.69102 5 6.11304 5H11.9051C14.3271 5 16.0181 6.66904 16.0181 9.06091V15.9391C16.0181 18.3299 14.3271 20 11.9051 20Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      Video Calling
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item className="d-flex gap-2">
                      <span className="d-flex">
                        <svg
                          width="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M14.4183 5.49C13.9422 5.40206 13.505 5.70586 13.4144 6.17054C13.3238 6.63522 13.6285 7.08891 14.0916 7.17984C15.4859 7.45166 16.5624 8.53092 16.8353 9.92995V9.93095C16.913 10.3337 17.2675 10.6265 17.6759 10.6265C17.7306 10.6265 17.7854 10.6215 17.8412 10.6115C18.3043 10.5186 18.609 10.0659 18.5184 9.60018C18.1111 7.51062 16.5027 5.89672 14.4183 5.49Z"
                            fill="currentColor"
                          ></path>
                          <path
                            opacity="0.4"
                            d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4078 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6698 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793Z"
                            fill="currentColor"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.0317 12.9724C15.0208 16.9604 15.9258 12.3467 18.4656 14.8848C20.9143 17.3328 22.3216 17.8232 19.2192 20.9247C18.8306 21.237 16.3616 24.9943 7.6846 16.3197C-0.993478 7.644 2.76158 5.17244 3.07397 4.78395C6.18387 1.67385 6.66586 3.08938 9.11449 5.53733C11.6544 8.0765 7.04266 8.98441 11.0317 12.9724Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      Voice Calling
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item className="d-flex gap-2">
                      <span className="d-flex">
                        <svg
                          width="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z"
                            fill="currentColor"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.9807 13.2901C11.2707 13.2801 10.7007 12.7101 10.7007 12.0001C10.7007 11.3001 11.2807 10.7201 11.9807 10.7301C12.6907 10.7301 13.2607 11.3001 13.2607 12.0101C13.2607 12.7101 12.6907 13.2901 11.9807 13.2901ZM7.37033 13.2901C6.67033 13.2901 6.09033 12.7101 6.09033 12.0101C6.09033 11.3001 6.66033 10.7301 7.37033 10.7301C8.08033 10.7301 8.65033 11.3001 8.65033 12.0101C8.65033 12.7101 8.08033 13.2801 7.37033 13.2901ZM15.3105 12.0101C15.3105 12.7101 15.8805 13.2901 16.5905 13.2901C17.3005 13.2901 17.8705 12.7101 17.8705 12.0101C17.8705 11.3001 17.3005 10.7301 16.5905 10.7301C15.8805 10.7301 15.3105 11.3001 15.3105 12.0101Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      Add to Group
                    </Dropdown.Item>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="chat-body chat-body--reply-task-details bg-body">
              <div className="chat-day-title">
                <span className="main-title">Feb 1,2021</span>
              </div>
              <div className="iq-message-body iq-other-user">
                <div className="chat-profile">
                  <Image
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1180&q=80"
                    alt="chat-user"
                    className="avatar-40 rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ross Taylor, 13:10</small>
                  <div className="d-flex align-items-center justify-content-start">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        I want some teachnic for fast code can you help?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-current-user">
                <div className="chat-profile">
                  <Image
                    src="https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=1024x1024&w=is&k=20&c=5q1NiElGDfT8IxbxhEWKTSufxyn3oFVHNnPNPyDe6as="
                    alt="chat-user"
                    className="avatar-40 object-fit-cover rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ellyse Perry, 16:40</small>
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        Hey, I am looking for the best dashboard template. Could
                        you please help me to find it out?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-other-user">
                <div className="chat-profile">
                  <Image
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1180&q=80"
                    alt="chat-user"
                    className="avatar-40 rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ross Taylor, 13:10</small>
                  <div className="d-flex align-items-center justify-content-start">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        I want some teachnic for fast code can you help?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-current-user">
                <div className="chat-profile">
                  <Image
                    src="https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=1024x1024&w=is&k=20&c=5q1NiElGDfT8IxbxhEWKTSufxyn3oFVHNnPNPyDe6as="
                    alt="chat-user"
                    className="avatar-40 object-fit-cover rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ellyse Perry, 16:40</small>
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        Hey, I am looking for the best dashboard template. Could
                        you please help me to find it out?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-other-user">
                <div className="chat-profile">
                  <Image
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1180&q=80"
                    alt="chat-user"
                    className="avatar-40 rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ross Taylor, 13:10</small>
                  <div className="d-flex align-items-center justify-content-start">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        I want some teachnic for fast code can you help?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iq-message-body iq-current-user">
                <div className="chat-profile">
                  <Image
                    src="https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=1024x1024&w=is&k=20&c=5q1NiElGDfT8IxbxhEWKTSufxyn3oFVHNnPNPyDe6as="
                    alt="chat-user"
                    className="avatar-40 object-fit-cover rounded-pill"
                    loading="lazy"
                  />
                </div>
                <div className="iq-chat-text">
                  <small className="iq-chating p-0">Ellyse Perry, 16:40</small>
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      className={`iq-chating-content d-flex align-items-center`}
                    >
                      <p className="mr-2 mb-0">
                        Hey, I am looking for the best dashboard template. Could
                        you please help me to find it out?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
            <Card.Body>
              <Row>
                <Col xs={12} className="mb-2">
                  {" "}
                  <label className="d-inline-block">Chat Mode</label>
                </Col>
                <Col sm={6}>
                  <Select
                    className="react-select-customize"
                    options={ChatMode}
                    placeholder="Poke"
                  />
                </Col>
                <Col sm={6} className="d-flex">
                  <Button
                    variant="outline-primary"
                    className="border-dashed gap-2 d-flex align-items-center me-auto flex-basis-1"
                    onClick={handleShow}
                  >
                    {findIcon("Plus", "solid", "18")}
                    Add Template
                  </Button>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="bg-body px-3 py-3 rounded-0">
              <form>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control chat-input"
                    placeholder="Write a message"
                    aria-label="Recipient's username"
                  />

                  <span className="input-group-text mgs-icon send-icon">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8325 6.67463L8.10904 12.4592L1.59944 8.38767C0.66675 7.80414 0.860765 6.38744 1.91572 6.07893L17.3712 1.55277C18.3373 1.26963 19.2326 2.17283 18.9456 3.142L14.3731 18.5868C14.0598 19.6432 12.6512 19.832 12.0732 18.8953L8.10601 12.4602"
                        stroke="currentcolor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </form>
            </Card.Footer>
          </Card>
        </Col>

        <div
          id="all-engagement"
          className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3"
        >
          <div className="d-flex flex-column">
            <h4 className="m-0">All Engagement Information:</h4>
          </div>
        </div>

        <Col sm={12}>
          <Card>
            <Card.Body>
              <div className="fancy-table table-responsive rounded">
                <ReactTable data={userData} columns={COLUMNS} />
                {/* <CommonTableLoader /> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Selet Template</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* //iterate with a false array */}
          {Array.from({ length: 10 }, (_, index) => (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between"
            >
              <TemplateSuggestionCard
                data={
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s lorem ipsum dolor sit amet."
                }
                click={() => {}}
                selectedSms={""}
              />
            </div>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default ReplyTaskDetails;
