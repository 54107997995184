import { memo, Fragment } from "react";

// React-bootstrap
import { Row, Col, Form, Button, Container, Image } from "react-bootstrap";

//Components
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.svg";

const Twofactor = memo(() => {
  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Container>
          <Row className="d-flex align-items-center iq-auth-container justify-content-center">
            <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
              <Image
                src={ciplaUnityLogo}
                alt="CLIRNET Unity"
                className="ciplaLogo mb-2"
              />
              <h5 className="h5 lh-base">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </h5>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Body>
                  <div className="mb-2">
                    <h4 className="pb-2">Two Factor -Verification</h4>
                    <p>
                      Enter your email address and we’ll send you an email with
                      instructions to reset your password
                    </p>
                  </div>
                  <Form>
                    <Row>
                      <Col lg="12" className="mb-3">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="text"
                            className=""
                            id="floatingInput1"
                            autoComplete="Enter OTP"
                            placeholder="Enter OTP"
                          />
                          <Form.Label htmlFor="fname">Enter OTP</Form.Label>
                        </Form.Floating>
                      </Col>
                      <Col lg="12" className="mb-3">
                        <Button className="btn btn-primary w-100">
                          Verify
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

Twofactor.displayName = "Twofactor";
export default Twofactor;
