import { memo, Fragment } from "react";

//react-bootstrap
import { Row, Col, Image } from "react-bootstrap";

//router
import { Link } from "react-router-dom";

//component
import Card from "../../../components/bootstrap/card";

//img
import mail from "../../../assets/images/auth/03.png";

// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";
import app_config from "../../../common/data/app_config";

const ConfirmMail = memo(() => {
  const appName = useSelector(SettingSelector.app_name);
  return (
    <Fragment>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6" className="p-0">
            <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-3">
              <Card.Body>
                <Link
                  to="/dashboard"
                  className="navbar-brand d-flex align-items-center mb-3"
                >
                  <svg
                    width="30"
                    className="text-primary"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="-0.757324"
                      y="19.2427"
                      width="28"
                      height="4"
                      rx="2"
                      transform="rotate(-45 -0.757324 19.2427)"
                      fill="currentColor"
                    />
                    <rect
                      x="7.72803"
                      y="27.728"
                      width="28"
                      height="4"
                      rx="2"
                      transform="rotate(-45 7.72803 27.728)"
                      fill="currentColor"
                    />
                    <rect
                      x="10.5366"
                      y="16.3945"
                      width="16"
                      height="4"
                      rx="2"
                      transform="rotate(45 10.5366 16.3945)"
                      fill="currentColor"
                    />
                    <rect
                      x="10.5562"
                      y="-0.556152"
                      width="28"
                      height="4"
                      rx="2"
                      transform="rotate(45 10.5562 -0.556152)"
                      fill="currentColor"
                    />
                  </svg>
                  {/* <h4 className="logo-title ms-3">{app_config.appName}</h4> */}
                  <h4 className="logo-title">
                    <svg viewBox="0 0 306.46 55">
                      <path
                        fill="#1b437a"
                        stroke="0"
                        d="m33.08,4.41V.61H0v54h33.08v-4.66H5.62V4.41h27.46ZM13.16,44.08v-11.85h17.61v-4.33H7.06v20.63h26.02v-4.44H13.16ZM33.08,11.15v-5.3H7.06v20.62h23.71v-4.39H13.16v-10.92h19.92Z"
                      />
                      <path
                        fill="#1b437a"
                        stroke="0"
                        d="m52.13,7.72h-3.78v46.9h6.42V21.3l22,33.31h4.89L52.13,7.72ZM85.08.61v54h4.85V.61h-4.85Zm-8.31,0v33.47L54.77.61h-13.16v54h5.3V6.29h6l30.43,48.32h.3V.61h-6.88Z"
                      />
                      <path
                        fill="#1b437a"
                        stroke="0"
                        d="m122.16,29.72v4.12h16.69c-1.03,3.08-2.66,5.44-4.89,7.08-2.23,1.64-4.96,2.46-8.19,2.46-4.72,0-8.42-1.4-11.12-4.2-2.69-2.79-4.04-6.68-4.04-11.65,0-4.67,1.28-8.4,3.85-11.19,2.56-2.79,5.97-4.2,10.23-4.2,2.46,0,4.6.47,6.42,1.43,1.82.95,3.21,2.32,4.2,4.11h6.74c-.25-.35-.5-.69-.75-1.03-5.48-7.16-13.77-10.63-21.12-8.84-1.1.26-2.21.65-3.29,1.13-.65.3-1.29.62-1.89.96-7.16,4.1-10.91,12.41-9.56,21.16,1.46,9.44,9.22,18.59,19.7,17.57,6.4-.62,14.78-3.21,18.53-11.99v-6.94h-21.51Zm0-5.57v4.13h22.94v8.66l-.05.14c-3.16,7.55-9.99,12.04-19.76,12.99-.59.06-1.17.08-1.74.09-10.43,0-18.03-9.3-19.51-18.87-1.44-9.34,2.59-18.22,10.25-22.62.64-.36,1.32-.71,2.01-1.02,1.17-.52,2.36-.94,3.54-1.23,7.89-1.92,16.76,1.76,22.59,9.37.4.52.79,1.05,1.16,1.58l.29.32h5.96c-1.44-5.54-4.37-9.87-8.81-13-4.43-3.12-9.88-4.69-16.35-4.69-5.34,0-10.08,1.17-14.23,3.49-4.15,2.34-7.4,5.59-9.73,9.77-2.34,4.18-3.5,8.93-3.5,14.27s1.17,10.08,3.5,14.23c2.33,4.15,5.58,7.39,9.76,9.73,4.18,2.33,8.93,3.5,14.27,3.5,4.51,0,8.56-.87,12.16-2.61,3.59-1.74,6.55-4.03,8.88-6.85,2.33-2.82,3.98-5.87,4.96-9.15v-12.23h-28.61Z"
                      />
                      <path
                        fill="#1b437a"
                        stroke="0"
                        d="m183.73,6.68h-4.41l-11.3,31.73h27.11l-11.4-31.73Zm-2.12,8.48l6.69,19.77h-13.31l6.62-19.77Zm14.03,24.68h-28.13l-5.27,14.78h6.07l3.23-9.53h20.15l3.23,9.53h6.03l-5.31-14.78ZM189.31.61h-15.23l-19.53,54h6.18l17.6-49.37h6.42l17.74,49.37h6.37L189.31.61Z"
                      />
                      <path
                        fill="#1b437a"
                        stroke="0"
                        d="m237.46,29.72v4.12h16.69c-1.02,3.08-2.66,5.44-4.88,7.08-2.23,1.64-4.97,2.46-8.2,2.46-4.71,0-8.42-1.4-11.11-4.2-2.7-2.79-4.04-6.68-4.04-11.65,0-4.67,1.29-8.4,3.85-11.19,2.56-2.79,5.97-4.2,10.23-4.2,2.46,0,4.6.47,6.42,1.43,1.83.95,3.22,2.32,4.2,4.11h6.63c-.25-.35-.5-.69-.76-1.03-5.48-7.16-13.77-10.63-21.12-8.84-1.1.26-2.21.65-3.29,1.13-.65.3-1.29.62-1.89.96-7.16,4.1-10.91,12.41-9.55,21.16,1.46,9.44,9.2,18.6,19.69,17.57,6.4-.62,14.78-3.21,18.53-11.99v-6.94h-21.4Zm0-5.57v4.13h22.83v8.66l-.05.14c-3.16,7.55-9.99,12.04-19.76,12.99-.59.06-1.17.08-1.73.09-10.44,0-18.04-9.3-19.52-18.87-1.44-9.34,2.59-18.22,10.25-22.62.64-.36,1.32-.71,2.01-1.02,1.17-.52,2.36-.94,3.54-1.23,7.89-1.92,16.76,1.76,22.6,9.37.4.52.78,1.05,1.15,1.58l.29.32h6.08c-1.43-5.54-4.37-9.87-8.81-13-4.43-3.12-9.88-4.69-16.35-4.69-5.33,0-10.08,1.17-14.23,3.49-4.15,2.34-7.39,5.59-9.73,9.77s-3.5,8.93-3.5,14.27,1.17,10.08,3.5,14.23c2.34,4.15,5.59,7.39,9.77,9.73,4.18,2.33,8.93,3.5,14.26,3.5,4.52,0,8.56-.87,12.16-2.61,3.59-1.74,6.55-4.03,8.88-6.85,2.34-2.82,3.98-5.87,4.96-9.15v-12.23h-28.61Z"
                      />
                      <path
                        fill="#1b437a"
                        stroke="0"
                        d="m273.38.61v54h33.08v-4.66h-26.82V4.41h26.82V.61h-33.08Zm13.16,31.62h17.61v-4.33h-23.08v20.63h25.39v-4.44h-19.92v-11.85Zm-5.47-5.76h23.08v-4.39h-17.61v-10.92h19.92v-5.3h-25.39v20.62Z"
                      />
                    </svg>
                  </h4>
                </Link>
                <h2 className="mt-3 mb-0">Success !</h2>
                <p className="cnf-mail mb-1">
                  A email has been send to youremail@domain.com. Please check
                  for an email from company and click on the included link to
                  reset your password.
                </p>
                <div className="d-inline-block w-100">
                  <Link to="/dashboard" className="btn btn-primary mt-3">
                    Back to Home
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={mail}
              className="img-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
        </Row>
      </section>
    </Fragment>
  );
});

ConfirmMail.displayName = "ConfirmMail";
export default ConfirmMail;
