import { configureStore } from "@reduxjs/toolkit";
import settingReducer from "./setting/reducers";
import segmentReducer from "./segment/segment.reducer";
import reactTableReducer from "./react-table/react-table.reducer";
import authReducer from "./auth/reducers";
import contentStudioReducer from "./content-studio/content-studio.reducer";
import commonReducer from "./common/reducers";

export const store = configureStore({
  reducer: {
    reactTable: reactTableReducer,
    segment: segmentReducer,
    auth: authReducer,
    setting: settingReducer,
    contentStudio: contentStudioReducer,
    common: commonReducer,
  },
});
